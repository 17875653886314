import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../components/ManagePlates/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCustomers from "../components/AddUsers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
// import KanbanBoard from "../components/AllGroups/index";

//Individual screens
// import LovedOnes from "../pages/IndividualScreens/LovedOnes";
// import Individual_Dashboard from "../pages/IndividualScreens/Individual_Dashboard";
// import MerchantDashboard from "../pages/IndividualScreens/MerchantDashboard";
// import RealLovedOnes from "../pages/IndividualScreens/RealLovedOnes";
// import SendCharms from "../pages/IndividualScreens/SendCharms";

// We are Community Screen
// import Samaritan from "../pages/IndividualScreens/Community/Samaritan";
// import Champion from "../pages/IndividualScreens/Community/Champion";
// import Chancellor from "../pages/IndividualScreens/Community/Chancellor";
// import Prophet from "../pages/IndividualScreens/Community/Prophet";
// import CreatePoll from "../pages/IndividualScreens/CreatePoll";
// import ManagedLove from "../pages/IndividualScreens/ManagedLovedOnes/index";
import LoginOTP from "../pages/Authentication/LoginOTP";
import ReportedPlates from "../components/ManagePlates/ReportedPlates";
// import Profile from "../components/VerticalLayout/AllUsers/Profile";
import ManageParkingFeature from "../components/AboutParkings/ManageParkingFeature/ManageParkingFeature";
import MangeVehicleCatagories from "../components/AboutParkings/MangeVehicleCatagories/MangeVehicleCatagories";
import MangeVehicleSpots from "../components/AboutParkings/MangeVehicleSpots/MangeVehicleSpots";
import ParkingReports from "../components/AboutParkings/ParkingReports/ParkingReports";
import ManageParking from "../components/AboutParkings/ManageParking/ManageParking";

import ManageParkingProfileData from "../components/AboutParkings/ManageParkingProfile/ManageParkingProfileData";
import AllUsers from "../components/ManageUsers/AllUsers";
import ReportedUser from "../components/ManageUsers/ReportedUser";
import Reasons from "../components/Settings/Reasons";
import VehicleColors from "../components/Settings/VehicleColors";
import VehicleModel from "../components/Settings/VehicleModel";
import Vehicles from "../components/Settings/Vehicles";
import QuickNotes from "../components/Settings/QuickNotes";
import ManagePayment from "../components/Settings/ManagePayment/ManagePayment";
import SocialGroups from "../components/SocialGroups/SocialGroups";
import invoices from "../components/Invoices/Invoices";
import BillingDeatail from "../components/BillingDetails/BillingDeatail";
import PackageManagement from "../components/ManagePackages/PackageManagement";
import Chat from "../components/MessageCenter/Chat";
import KanbanBoard from "../components/AllGroups/index";
import AdminProfile from "../components/AdminProfile/AdminProfile";
import PaymentDeatail from "../components/PaymentDeatail/PaymentDeatail";
import ManageCouponCode from "../components/Settings/ManageCouponCode";
import ManageFeedback from "../components/AboutParkings/Feedback/ManageFeedback";
const authProtectedRoutes = [
   //Individual screens
   // { path: "/my-loved-ones", component: LovedOnes },
   // { path: "/individual-dashboard", component: Individual_Dashboard },
   // { path: "/merchant-dashboard", component: MerchantDashboard },
   // { path: "/manage-my-loved-ones", component: RealLovedOnes },
   // { path: "/send-charms", component: SendCharms },
   // { path: "/create-poll", component: CreatePoll },
   // { path: "/managed-loved", component: ManagedLove },

   //Community screens

   // { path: "/samaritan", component: Samaritan },
   // { path: "/champion", component: Champion },
   // { path: "/chancellor", component: Chancellor },
   // { path: "/prophet", component: Prophet },

   //Kanban Board
   { path: "/deals", component: KanbanBoard },
   { path: "/social-groups", component: SocialGroups },
   { path: "/invoices", component: invoices },
   { path: "/payment-details", component: PaymentDeatail },
   { path: "/billing-details", component: BillingDeatail },
   { path: "/package-management", component: PackageManagement },
   { path: "/quick-notes", component: QuickNotes },
   { path: "/manage-payment-methods", component: ManagePayment },
   { path: "/manage-coupon-code", component: ManageCouponCode },
   ////Parking
   { path: "/parking-features", component: ManageParkingFeature },
   { path: "/vehicle-types", component: MangeVehicleCatagories },
   { path: "/vehicle-spots", component: MangeVehicleSpots },
   { path: "/parking-reports", component: ParkingReports },
   { path: "/parkings", component: ManageParking },
    
   { path: "/payments-feedbacks", component: ManageFeedback },
   { path: "/parking/:id", component: ManageParkingProfileData },

   // Tables
   { path: "/basic-tables", component: BasicTables },
   { path: "/datatable-table", component: DatatableTables },
   { path: "/responsive-table", component: ResponsiveTables },
   { path: "/editable-table", component: EditableTables },

   // Ui
   { path: "/ui-alerts", component: UiAlert },
   { path: "/ui-buttons", component: UiButtons },
   { path: "/ui-cards", component: UiCards },
   { path: "/ui-carousel", component: UiCarousel },
   { path: "/ui-dropdowns", component: UiDropdown },
   { path: "/ui-general", component: UiGeneral },
   { path: "/ui-grid", component: UiGrid },
   { path: "/ui-images", component: UiImages },
   { path: "/ui-lightbox", component: UiLightbox },
   { path: "/ui-modals", component: UiModal },
   { path: "/ui-progressbars", component: UiProgressbar },
   { path: "/ui-sweet-alert", component: UiSweetAlert },
   { path: "/ui-tabs-accordions", component: UiTabsAccordions },
   { path: "/ui-typography", component: UiTypography },
   { path: "/ui-video", component: UiVideo },
   { path: "/ui-session-timeout", component: UiSessionTimeout },
   { path: "/ui-rating", component: UiRating },
   { path: "/ui-rangeslider", component: UiRangeSlider },
   { path: "/ui-notifications", component: UiNotifications },
   { path: "/ui-roundslider", component: UIRoundSlider },

   // Forms
   { path: "/form-elements", component: FormElements },
   { path: "/form-advanced", component: FormAdvanced },
   { path: "/form-editors", component: FormEditors },
   { path: "/form-mask", component: FormMask },
   { path: "/form-file-upload", component: FormUpload },
   { path: "/form-wizard", component: FormWizard },
   { path: "/form-validation", component: FormValidations },
   { path: "/form-xeditable", component: FormXeditable },

   //Utility
   { path: "/starter", component: StarterPage },
   { path: "/timeline", component: Timeline },
   { path: "/faqs", component: FAQs },
   { path: "/pricing", component: Pricing },

   //Icons
   { path: "/icons-remix", component: RemixIcons },
   { path: "/material-design", component: MaterialDesign },
   { path: "/dripicons", component: DripiIcons },
   { path: "/font-awesome-5", component: FontAwesome },

   // Maps
   { path: "/google-maps", component: MapsGoogle },
   { path: "/vector-maps", component: MapsVector },

   //Charts
   { path: "/apex-charts", component: ChartApex },
   { path: "/chartjs", component: ChartjsChart },
   { path: "/charts-sparkline", component: SparklineChart },
   { path: "/charts-knob", component: ChartsKnob },

   //Email
   { path: "/email-inbox", component: EmailInbox },
   { path: "/email-read", component: EmailRead },

   //Ecommerce

   { path: "/ecommerce-products", component: EcommerceProducts },
   { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
   // { path: "/user-list", component: EcommerceCustomers },
   { path: "/ecommerce-cart", component: EcommerceCart },
   { path: "/ecommerce-checkout", component: EcommerceCheckout },
   { path: "/ecommerce-shops", component: EcommerceShops },
   { path: "/ecommerce-add-product", component: EcommerceAddProduct },

   //Newly Created
   { path: "/reported-users", component: ReportedUser },
   { path: "/vehicle-colors", component: VehicleColors },
   { path: "/vehicle-models", component: VehicleModel },
   { path: "/vehicles", component: Vehicles },
   { path: "/plates-list", component: EcommerceOrders },
   { path: "/reported-plates", component: ReportedPlates },
   { path: "/plates-list", component: EcommerceOrders },
   // { path: "/reported-plates", component: ReportedPlates },
   { path: "/users-list", component: AllUsers },
   { path: "/reasons", component: Reasons },
   { path: "/adminProfile", component: AdminProfile },
   // { path: "/profile", component: Profile },
   //chat
   { path: "/chat", component: Chat },

   //calendar
   { path: "/calendar", component: Calendar },

   { path: "/dashboard", component: Dashboard },

   // this route should be at the end of all other routes
   { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
   { path: "/logout", component: Logout },
   { path: "/login", component: Login },
   { path: "/forgot-password", component: ForgetPwd },
   { path: "/register", component: Register },
   { path: "/lock-screen", component: AuthLockScreen },
   { path: "/login-otp", component: LoginOTP },

   // Authentication Inner
   { path: "/auth-login", component: Login1 },
   { path: "/auth-register", component: Register1 },
   { path: "/auth-recoverpw", component: ForgetPwd1 },

   { path: "/maintenance", component: Maintenance },
   { path: "/comingsoon", component: CommingSoon },
   { path: "/404", component: Error404 },
   { path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
