import React, { Component } from "react";
import {
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
} from "reactstrap";
import adminIcon from "../../../assets/icons/adminIcon.svg";
//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import { Link } from "react-router-dom";

class ProfileMenu extends Component {
   constructor(props) {
      super(props);
      this.state = {
         menu: false,
      };
      this.toggle = this.toggle.bind(this);
   }

   toggle() {
      this.setState((prevState) => ({
         menu: !prevState.menu,
      }));
   }

   render() {
      let username = "Admin";
      if (localStorage.getItem("authUser")) {
         const obj = JSON.parse(localStorage.getItem("authUser"));
         const uNm = obj.email.split("@")[0];
         username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
      }

      return (
         <React.Fragment>
            <Dropdown
               isOpen={this.state.menu}
               toggle={this.toggle}
               className="d-inline-block user-dropdown"
            >
               <DropdownToggle
                  tag="button"
                  className="btn header-item waves-effect font-weight-bold"
                  id="page-header-user-dropdown"
               >
                  <img src={adminIcon} width={20} className="mb-1" />
                  <span
                     className="d-none d-xl-inline-block ms-1 "
                     style={{ fontWeight: "bolder", fontSize: "15px" }}
                  >
                     {username}
                  </span>
                  <i
                     className="mdi mdi-chevron-down d-none md-36 ms-1 d-xl-inline-block pt-3"
                     style={{ fontWeight: "bolder" }}
                  ></i>
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem>
                     <Link to="adminProfile">
                        <span
                           className="ri-user-line align-middle"
                           style={{ marginRight: 5 }}
                        />

                        {this.props.t("Profile")}
                     </Link>
                  </DropdownItem>
                  {/* <DropdownItem href="#">
              <i className="ri-wallet-2-line align-middle me-1"></i> {this.props.t("My Wallet")}
            </DropdownItem>
            <DropdownItem className="d-block" href="#">
              <span className="badge badge-success float-end mt-1">11</span>
              <i className="ri-settings-2-line align-middle me-1"></i> {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem href="#">
              <i className="ri-lock-unlock-line align-middle me-1"></i> {this.props.t("Lock screen")}
            </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/logout">
                     <Link
                        to="/logout"
                        onClick={() => localStorage.clear()}
                        className="text-danger"
                     >
                        <span
                           className="ri-shut-down-line align-middle me-1 text-danger"
                           style={{ marginRight: 8 }}
                        />
                        {this.props.t("Logout")}
                     </Link>
                  </DropdownItem>
               </DropdownMenu>
            </Dropdown>
         </React.Fragment>
      );
   }
}

export default withNamespaces()(ProfileMenu);
