import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { getVehicleCategories, AddModel, } from "../Services/UserServices/Index";
import {
   getVehicleCategories,
   deleteVehicleCatagories,
   AddCatagories,
   EditCatagories,
   setActiveCatagories,
   setDeActiveCatagories,
} from "../../Services/VehicleServicesCatagories/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import Swal from "sweetalert2";
import moment from "moment";
import Select from "react-select";
import dummyCar from "../../../assets/vehicleIcon/dummyCar.jpg";
// import image_userA from "../VerticalLayout/AllUsers/VehicleModelA.svg";
// import image_userD from "../VerticalLayout/AllUsers/VehicleModelDA.svg";
import pencil from "../../../assets/icons/Pencil-01.svg";
import catagories_active from "../../../assets/PakingImges/catagariesA.svg";
import catagories_deactive from "../../../assets/PakingImges/catagariesDA.svg";
import Pagination from "../../VerticalLayout/pagination/Pagination";
class MangeVehicleCatagories extends Component {
   constructor(props) {
      super(props);
      this.getAllVehicleCategories = this.getAllVehicleCategories.bind(this);
      this.handleSelectedIcon = this.handleSelectedIcon.bind(this);
      this.getUrlExtension = this.getUrlExtension.bind(this);
      this.addVehicleCatagories = this.addVehicleCatagories.bind(this);
      this.DeleteCatagoriesID = this.DeleteCatagoriesID.bind(this);
      this.updateCatagoriesFunction = this.updateCatagoriesFunction.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.state = {
         pageIDs: [],
         allVehicleCatagories: [],
         loading: false,
         imagesIcons: [
            {
               birthday: require("../../../assets/icons/all-icons/2doorminiTruck.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/4doorminitruck.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/CompactSedan.svg"),
            // },
            {
               birthday: require("../../../assets/icons/all-icons/Coupe.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/crossover.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/FullsizeSedan.svg"),
            // },
            {
               birthday: require("../../../assets/icons/all-icons/Hatchback.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/LargeSUV.svg"),
            // },
            {
               birthday: require("../../../assets/icons/all-icons/MidsizeSedan.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/MiniVan.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/SUV.svg"),
            },
            //////////////
            {
               birthday: require("../../../assets/icons/all-icons/band-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/beach-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/bicycle.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/huk-crain.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/motor-bike.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/racer-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/salahSingleDoor.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/trulah.svg"),
            },
         ],
         name: "",
         id: "",
         addProfile: "",
         editCatagoriesID: "",
         catagoriesName: "",
         getCatagories: [],
         vehicleCatagoriesName: "",
         vehicleCatagoriesNameVal: false,
         currentPage: "",
         lastPage: "",
         showEditProfileImage: "",
         editProfileImage: "",
         showProfileImage: "",

         status: "",
         loadingModel: true,
      };
   }

   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllVehicleCategories();
   }

   async getAllVehicleCategories(page) {
      let catagoriesArray = [];
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const getCatagories = await getVehicleCategories(accessToken, page);

         if (getCatagories.status === true) {
            this.setState({ loading: true });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: getCatagories.vehicle_categories.current_page,
               });
            this.setState({
               lastPage: getCatagories.vehicle_categories.last_page,
            });
            this.setState({
               allVehicleCatagories: getCatagories.vehicle_categories.data,
            });

            for (
               let i = getCatagories.vehicle_categories.from;
               i <= getCatagories.vehicle_categories.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.state.allVehicleCatagories.map((mod, index) => {
               catagoriesArray.push({
                  id: mod.id,
                  name: mod.name,

                  status: mod.status,
               });
               this.setState({ getCatagories: catagoriesArray });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async addVehicleCatagories() {
      // this.setState({ loadingModel: false });

      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (
         this.state.vehicleCatagoriesName == "" ||
         this.state.vehicleCatagoriesName == undefined
      ) {
         this.setState({ vehicleCatagoriesNameVal: true });
      }
      if (this.state.addProfile == "" || this.state.addProfile == undefined) {
         this.setState({ showProfileImageVal: true });
      }
      if (
         this.state.vehicleCatagoriesName !== "" &&
         this.state.vehicleCatagoriesName !== undefined &&
         this.state.addProfile !== "" &&
         this.state.addProfile !== undefined
      ) {
         try {
            const response = await AddCatagories(
               accessToken,
               this.state.vehicleCatagoriesName,
               this.state.addProfile
            );
            if (response.status == true) {
               this.getAllVehicleCategories(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle type added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleCatagoriesName: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async DeleteCatagoriesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteVehicleCatagories(accessToken, id);
            if (result.status === true) {
               this.getAllVehicleCategories(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle type removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async setInitialValue(id, name, imageBG) {
      if (imageBG == "undefinedundefined") {
         this.setState({
            showEditProfileImage: dummyCar,

            // status: status,
         });
      } else {
         this.setState({
            showEditProfileImage: imageBG,

            // status: status,
         });
      }
      this.setState({
         editCatagoriesID: id,
         vehicleCatagoriesName: name,

         // status: status,
      });
   }
   async updateCatagoriesFunction() {
      if (
         this.state.vehicleCatagoriesName == "" ||
         this.state.vehicleCatagoriesName == undefined
      ) {
         this.setState({ vehicleCatagoriesNameVal: true });
      }

      if (
         this.state.vehicleCatagoriesName != "" &&
         this.state.vehicleCatagoriesName != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditCatagories(
               accessToken,
               this.state.vehicleCatagoriesName,
               this.state.editCatagoriesID,
               this.state.editProfileImage
            );
            if (response.status == true) {
               this.getAllVehicleCategories(this.state.currentPage);
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle type updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  modelName: "",
                  status: "",
                  editmodelID: "",
                  editProfileImage: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async ActiveVehicleCatagoriesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setActiveCatagories(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleCategories(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle type active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleCatagoriesID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActiveCatagories(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleCategories(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicles type Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   AllClear = () => {
      this.setState({
         vehicleCatagoriesName: "",
         vehicleCatagoriesNameVal: false,
         showProfileImage: dummyCar,
         addProfile: "",
         showProfileImageVal: false,
         imageIcon: "",
      });
   };

   vehicleCatagoriesNameFunction = (e) => {
      this.setState({
         vehicleCatagoriesName: e.target.value,
         vehicleCatagoriesNameVal: false,
      });
   };

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      // await this.getAllModels(page);
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   handleChange = (e, inputField) => {
      // Add Logo Error
      if (
         e.target?.files[0]?.type == "image/svg+xml" ||
         e.target?.files[0]?.type == "image/svg"
      ) {
         if (inputField == "logo") {
            console.log("e.target.files.length", e.target.files.length);
            if (e.target.files.length !== 0) {
               this.setState({
                  addProfile: e.target.files[0],
                  showProfileImageVal: false,
               });
               this.setState({
                  showProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
         if (inputField == "Elogo") {
            if (e.target.files.length !== 0) {
               this.setState({ editProfileImage: e.target.files[0] });
               this.setState({
                  showEditProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showEditProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
      } else {
         Swal.fire({
            position: "top",
            icon: "warning",
            title: "Please upload svg file",
            showConfirmButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
         });
      }
   };
   getUrlExtension = (url) => {
      return url.split(/[#?]/)[0].split(".").pop().trim();
   };

   handleSelectedIcon = async (e, img, type) => {
      var imgExt = this.getUrlExtension(img);

      const response = await fetch(img);
      const blob = await response.blob();
      const file = new File([blob], "profileImage." + imgExt, {
         type: blob.type,
      });

      if (type == "add") {
         this.setState({
            addProfile: file,
            showProfileImage: img,
            showProfileImageVal: false,
            // showProfileImageVal: false,
         });
      } else if (type == "edit") {
         this.setState({
            editProfileImage: file,
            showProfileImageVal: false,
         });
         this.setState({
            showEditProfileImage: img,
         });
      }
   };
   render() {
      return (
         <>
            <div className="page-content">
               <Container>
                  {/* ///////////////////////////// */}
                  <h5 className="fs-5 mb-3 fw-bold">Vehicle Types </h5>
                  {this.state.loading ? (
                     <>
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div>
                                 {/* <div className="search-box "> */}
                                 {/* <input
                        type="search"
                        class="form-control rounded-pill"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(e) => this.SearchChangeHandler(e)}
                      /> */}
                                 {/* </div> */}
                              </div>
                              <div>
                                 <Button
                                    type="button"
                                    onClick={() => {
                                       this.AllClear();
                                       this.setState({
                                          showProfileImage: dummyCar,
                                          addProfile: "",
                                       });
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded mb-2 me-2"
                                 >
                                    <span>Add Type</span>
                                 </Button>
                              </div>
                           </div>
                           <div className="overflow-auto">
                              <table class="table bg-white table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Icon</th>
                                       <th scope="col">Name</th>

                                       <th scope="col">Date</th>
                                       <th scope="col">Status</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.allVehicleCatagories?.length >
                                    0 ? (
                                       <>
                                          {" "}
                                          {this.state.allVehicleCatagories.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}
                                                         <td>
                                                            {item
                                                               ?.category_image
                                                               ?.folder_path +
                                                            item?.category_image
                                                               ?.file_name ? (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     maxWidth:
                                                                        "50px",
                                                                     // background:
                                                                     //    "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={
                                                                     item
                                                                        ?.category_image
                                                                        ?.folder_path +
                                                                     item
                                                                        ?.category_image
                                                                        ?.file_name
                                                                  }
                                                                  alt=""
                                                               />
                                                            ) : (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     background:
                                                                        "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={dummyCar}
                                                                  alt=""
                                                                  width={"20px"}
                                                                  height={
                                                                     "20px"
                                                                  }
                                                               />
                                                            )}
                                                         </td>
                                                         <td>
                                                            {item.name ?? "--"}
                                                         </td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>

                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <Link
                                                               to="#"
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="Edit"
                                                                  className="mdi mdi-pencil font-size-18"
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#staticBackdropTwo"
                                                                  onClick={() =>
                                                                     this.setInitialValue(
                                                                        item.id,
                                                                        item.name,
                                                                        `${item?.category_image?.folder_path}${item?.category_image?.file_name}`
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                            >
                                                               <i
                                                                  title="Delete"
                                                                  className="mdi mdi-trash-can font-size-18 me-3"
                                                                  onClick={() =>
                                                                     this.DeleteCatagoriesID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        catagories_deactive
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.DeActiveVehicleCatagoriesID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        catagories_active
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.ActiveVehicleCatagoriesID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* ///////////////////////add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Type
                           </h5>
                           <button
                              type="button"
                              onClick={this.AllClear}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="mt-3 d-flex flex-column justify-content-center row">
                                 <div className="m-auto pe-2 pt-3 col-4">
                                    <div
                                       className=" image-input-outline"
                                       data-kt-image-input="true"
                                       style={{
                                          width: "128px",
                                          height: "125px",
                                          border: "1.5px solid #3e94a6",
                                          // background:
                                          //    "linear-gradient(#3E94A6, #323F4B)",
                                       }}
                                    >
                                       <div
                                          className="image-input-wrapper  d-flex"
                                          style={{
                                             width: "125px",
                                             height: "125px",
                                          }}
                                       >
                                          <img
                                             src={this.state.showProfileImage}
                                             className=" modal_image p-2"
                                             alt=""
                                             style={{
                                                maxWidth: "125px",
                                                maxHeight: "125px",
                                             }}
                                          />
                                       </div>
                                       {this.state.showProfileImageVal ? (
                                          <div className="modelValidation">
                                             Icon is required
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       for="avatar"
                                       style={{
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       data-kt-image-input-action="change"
                                       data-bs-toggle="tooltip"
                                       title="Change avatar"
                                    >
                                       <i
                                          title="Add"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addIconModal"
                                          style={{
                                             position: "absolute",
                                             left: "132px",
                                             top: "-5px",
                                             zIndex: 11,
                                          }}
                                       >
                                          <img
                                             src={pencil}
                                             width={30}
                                             height={25}
                                             data-bs-toggle="modal"
                                             data-bs-target="#addIconModal"
                                             alt=""
                                             className="d-inline cursor-pointer"
                                          />
                                       </i>
                                       {/* <input
                                          type="file"
                                          name="avatar"
                                          accept=".svg"
                                          onChange={(e) =>
                                             this.handleChange(e, "logo")
                                          }
                                          onClick={(e) => {
                                             e.target.value = null;
                                          }}
                                          style={{
                                             opacity: 0,
                                             left: "102px",
                                             top: "-30px",
                                             position: "absolute",
                                             zIndex: 111,
                                             cursor: "pointer",
                                             width: "50px",
                                             height: "50px",
                                          }}
                                       />
                                       <input type="hidden" /> */}
                                    </label>
                                 </div>
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Name
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter name"
                                    value={this.state.vehicleCatagoriesName}
                                    onChange={(e) =>
                                       this.vehicleCatagoriesNameFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />
                                 {this.state.vehicleCatagoriesNameVal ? (
                                    <div className="modelValidation">
                                       Enter name
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.AllClear}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleCatagoriesName != "" &&
                           this.state.vehicleCatagoriesName != undefined &&
                           this.state.addProfile != "" &&
                           this.state.addProfile != undefined ? (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addVehicleCatagories}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Type
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addVehicleCatagories}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Type
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Type
                           </h5>
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="mt-3  row">
                                 <div className="m-auto col-4">
                                    <div
                                       className=" image-input-outline"
                                       data-kt-image-input="true"
                                       style={{
                                          width: "125px",
                                          height: "125px",
                                       }}
                                    >
                                       <div
                                          className="image-input-wrapper d-flex "
                                          style={{
                                             width: "125px",
                                             height: "125px",
                                             border: "1.5px solid #3e94a6",

                                             // background:
                                             //    "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                       >
                                          <img
                                             src={
                                                this.state.showEditProfileImage
                                             }
                                             className="img-fluid modal_image p-2 "
                                             alt=""
                                             style={{
                                                maxWidth: "125px",
                                                maxHeight: "125px",
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <label
                                       for="avatar"
                                       style={{
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       data-kt-image-input-action="change"
                                       data-bs-toggle="tooltip"
                                       title="Edit"
                                    >
                                       <i
                                          title="Edit"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editIconModal"
                                          style={{
                                             position: "absolute",
                                             left: "127px",
                                             top: "-20px",
                                             zIndex: 11,
                                          }}
                                       >
                                          <img
                                             src={pencil}
                                             width={30}
                                             height={25}
                                             data-bs-toggle="modal"
                                             data-bs-target="#editIconModal"
                                             alt=""
                                             className="d-inline cursor-pointer"
                                          />
                                       </i>
                                       {/* <input
                                          type="file"
                                          name="avatar"
                                          accept=".svg"
                                          onChange={(e) =>
                                             this.handleChange(e, "Elogo")
                                          }
                                          style={{
                                             opacity: 0,
                                             left: "122px",
                                             top: "-45px",
                                             position: "absolute",
                                             zIndex: 111,
                                             cursor: "pointer",
                                             width: "40px",
                                             height: "60px",
                                          }}
                                       />
                                       <input type="hidden" /> */}
                                    </label>
                                 </div>
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Name
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter name"
                                    value={this.state.vehicleCatagoriesName}
                                    onChange={(e) =>
                                       this.vehicleCatagoriesNameFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />

                                 {this.state.vehicleCatagoriesNameVal ? (
                                    <div className="modelValidation">
                                       Enter name
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleCatagoriesName != "" &&
                           this.state.vehicleCatagoriesName != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 data-bs-dismiss="modal"
                                 onClick={() => this.updateCatagoriesFunction()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() => this.updateCatagoriesFunction()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* Add icon  */}

            <div
               className="modal fade"
               id="addIconModal"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog modal-lg">
                  <div className="modal-content ">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Select icon
                        </h5>
                        <button
                           onClick={this.AllClear}
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className="row ms-5">
                           {this.state.imagesIcons?.map((img, index) => (
                              <div
                                 className="col-2 m-1"
                                 key={index}
                                 style={{
                                    border: `${
                                       this.state.showProfileImage ===
                                       img.birthday.default
                                          ? "2.5px solid #3e94a6"
                                          : "1px solid #88919a"
                                    }`,
                                 }}
                              >
                                 <img
                                    className=" w-100  m-1 "
                                    src={img.birthday.default}
                                    style={{ height: 100 }}
                                    alt="Selected Image"
                                    // onLoad={true}
                                    onClick={(e) =>
                                       this.handleSelectedIcon(
                                          e,
                                          img.birthday.default,
                                          "add"
                                       )
                                    }
                                 />
                                 <i
                                    className={
                                       this.state.showProfileImage ===
                                       img.birthday.default
                                          ? "mdi mdi-check mdi-24px text-white position-absolute "
                                          : ""
                                    }
                                    style={{
                                       left: 0,
                                       top: 0,
                                       backgroundColor: "#3e94a6",
                                       borderBottomRightRadius: 100,
                                    }}
                                 ></i>
                              </div>
                           ))}
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           onClick={this.AllClear}
                           type="button"
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        <button
                           type="button"
                           style={{
                              background: "linear-gradient(#3E94A6, #323F4B)",
                           }}
                           className="btn btn-primary"
                           data-bs-dismiss="modal"
                        >
                           Save
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            {/* Edit icon  */}
            <div
               className="modal fade"
               id="editIconModal"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog modal-lg">
                  <div className="modal-content ">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Select icon
                        </h5>
                        <button
                           onClick={this.AllClear}
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className="row ms-5">
                           {this.state.imagesIcons?.map((img, index) => (
                              <div
                                 className="col-2 m-1"
                                 key={index}
                                 style={{
                                    border: `${
                                       this.state.showEditProfileImage ===
                                       img.birthday.default
                                          ? "2.5px solid #3e94a6"
                                          : "1px solid #88919a"
                                    }`,
                                 }}
                              >
                                 <img
                                    className=" w-100  m-1 "
                                    src={img.birthday.default}
                                    style={{ height: 100 }}
                                    alt="Selected Image"
                                    // onLoad={true}
                                    onClick={(e) =>
                                       this.handleSelectedIcon(
                                          e,
                                          img.birthday.default,
                                          "edit"
                                       )
                                    }
                                 />
                                 <i
                                    className={
                                       this.state.showEditProfileImage ===
                                       img.birthday.default
                                          ? "mdi mdi-check mdi-24px text-white position-absolute "
                                          : ""
                                    }
                                    style={{
                                       left: 0,
                                       top: 0,
                                       backgroundColor: "#3e94a6",
                                       borderBottomRightRadius: 100,
                                    }}
                                 ></i>
                              </div>
                           ))}
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           onClick={this.AllClear}
                           type="button"
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        <button
                           type="button"
                           style={{
                              background: "linear-gradient(#3E94A6, #323F4B)",
                           }}
                           className="btn btn-primary"
                           data-bs-dismiss="modal"
                        >
                           Save
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default MangeVehicleCatagories;
