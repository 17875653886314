import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, size } from "lodash";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import * as moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import EcommerceCustomerColumns from "./EcommerceCustomerColumns";

import { getCustomers } from "../../../store/e-commerce/actions";
import { AddUser, getCountry, GetUser } from "../../../components/Services/UserServices/ManageUsers/index";
class EcommerceCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      breadcrumbItems: [
        { title: "Ecommerce", link: "#" },
        { title: "Customer", link: "#" },
      ],
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      phone: "",
      status: "",
      countryName: [],
      countryID: "",
    };

    // const {Token} = transformedToken
    // console.log("Token Ahysdgfds", transformedToken)

    // const localStorageToken = localStorage.getItem('Token')
    // const transformedToken = JSON.parse(localStorageToken)
    // const {Token} = transformedToken ?? ''
    // console.log("TokenG000...........ooooo",tokenAccess);
    // console.log("TokenGgg",transformedToken);

    this.handleCustomerClick = this.handleCustomerClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleValidCustomerSubmit = this.handleValidCustomerSubmit.bind(this);
    this.handleCustomerClicks = this.handleCustomerClicks.bind(this);
  }

  async SaveUser(e) {
    let tokenAccess = localStorage.getItem("Token");
    let accessToken = JSON.parse(tokenAccess);
    //  let transformedToken = JSON.parse(tokenAccess | "")
    console.log("tokenNew---tokenAccess ", accessToken);
    try {
      const response = await AddUser(
        accessToken,
        this.state.firstName,
        this.state.lastName,
        this.state.email,
        this.state.phone,
        this.state.gender,
        this.state.status,
        this.state.countryID
      );
      console.log("response ", response);
    } catch (err) {
      console.log(err);
    }
    console.log("token ", tokenAccess);
    console.log("FName ", this.state.firstName);
    console.log("Lname ", this.state.lastName);
    console.log("email ", this.state.email);
    console.log("phone ", this.state.phone);
    console.log("status ", this.state.status);
    console.log("gender ", this.state.gender);
    console.log("id ", this.state.countryID);
  }

  async getCountryAPI() {
    // let result = await getCountry()
    // console.log("Countries ", result)
    // this.setState({countryName:result})
  }

  async componentDidMount() {
    let tokenAccess = localStorage.getItem("Token");
    let accessToken = JSON.parse(tokenAccess);
    const result = await getCountry(accessToken);
    // console.log("Countries ", result)
    this.setState({ countryName: result.countries });
    //  console.log("StateCnoooo ", this.state.countryName)

    const userData = await GetUser(accessToken);
    console.log("Gettting UserData ", userData);

    const { customers, onGetCustomers } = this.props;
    if (customers && !customers.length) {
      onGetCustomers();
    }
    this.setState({ customers });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { customers } = this.props;
    if (!isEmpty(customers) && size(prevProps.customers) !== size(customers)) {
      this.setState({ customers: {}, isEdit: false });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleCustomerClicks = (arg) => {
    this.setState({ selectedCustomer: arg });
    this.toggle();
  };

  /* Insert,Update Delete data */

  handleDeleteCustomer = (customer) => {
    const { onDeleteCustomer } = this.props;
    onDeleteCustomer(customer);
  };

  handleCustomerClick = (arg) => {
    const customer = arg;

    this.setState({
      customers: {
        id: customer.id,
        username: customer.username,
        phone: customer.phone,
        email: customer.email,
        address: customer.address,
        rating: customer.rating,
        walletBalance: customer.walletBalance,
        joiningDate: customer.joiningDate,
      },
      isEdit: true,
    });

    this.toggle();
  };

  /**
   * Handling submit Customer on Customer form
   */
  handleValidCustomerSubmit = (e, values) => {
    const { onAddNewCustomer, onUpdateCustomer } = this.props;
    const { isEdit, customers } = this.state;

    if (isEdit) {
      const updateCustomer = {
        id: customers.id,
        username: values.username,
        phone: values.phone,
        email: values.email,
        address: values.address,
        rating: values.rating,
        walletBalance: values.walletBalance,
        joiningDate: values.joiningDate,
      };

      // update Customer
      onUpdateCustomer(updateCustomer);
    } else {
      const newCustomer = {
        id: Math.floor(Math.random() * (30 - 20)) + 20,
        username: values["username"],
        phone: values["phone"],
        email: values["email"],
        address: values["address"],
        rating: values["rating"],
        walletBalance: values["walletBalance"],
        joiningDate: values["joiningDate"],
      };
      // save new Customer
      onAddNewCustomer(newCustomer);
    }
    this.setState({ selectedCustomer: null });
    this.toggle();
  };

  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  /* Insert,Update Delete data */

  render() {
    const { customers } = this.props;

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: customers.length, // replace later with size(customers),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const { SearchBar } = Search;

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users List" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" data={customers}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField="id" columns={EcommerceCustomerColumns()} data={customers} search>
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-end">
                                    <Button
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdropOne"
                                      type="button"
                                      style={{
                                        background: "linear-gradient(#3E94A6, #323F4B)",
                                      }}
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleCustomerClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add User
                                    </Button>
                                    <div
                                      className="modal fade"
                                      id="staticBackdropOne"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabIndex={-1}
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog">
                                        <div className="modal-content text-start">
                                          <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">
                                              Add User
                                            </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                          </div>
                                          <div className="modal-body">
                                            <div className="d-flex justify-content-around text-dark">
                                              <div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1 " className="fw-bolder required form-label mb-0">
                                                  First Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control form-control-solid "
                                                  placeholder="First Name"
                                                  value={this.state.firstName}
                                                  onChange={(e) => this.setState({ firstName: e.target.value })}
                                                />
                                              </div>
                                              <div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                                  Last Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control form-control-solid "
                                                  placeholder="Last Name"
                                                  value={this.state.lastName}
                                                  onChange={(e) => this.setState({ lastName: e.target.value })}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-around mt-4 text-dark">
                                              <div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                                  Email
                                                </label>
                                                <input
                                                  type="email"
                                                  className="form-control form-control-solid "
                                                  placeholder="Email"
                                                  value={this.state.email}
                                                  onChange={(e) => this.setState({ email: e.target.value })}
                                                />
                                              </div>
                                              <div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                                  Gender
                                                </label>
                                                <select
                                                  class="form-select"
                                                  aria-label="Default select example"
                                                  value={this.state.gender}
                                                  onChange={(e) => this.setState({ gender: e.target.value })}
                                                >
                                                  <option selected className="">
                                                    Choose Gender
                                                  </option>
                                                  <option value="Male">Male</option>
                                                  <option value="Female">Female</option>
                                                  <option value="Other">Other</option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-start mt-4 text-dark text-start">
                                              <div div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                                  Phone
                                                </label>
                                                <input
                                                  type="tel"
                                                  className="form-control form-control-solid "
                                                  placeholder="Phone Number"
                                                  value={this.state.phone}
                                                  onChange={(e) => this.setState({ phone: e.target.value })}
                                                />
                                              </div>
                                              <div className="w-50 mx-2">
                                                <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                                  Status
                                                </label>
                                                <select
                                                  class="form-select"
                                                  aria-label="Default select example"
                                                  value={this.state.status}
                                                  onChange={(e) => this.setState({ status: e.target.value })}
                                                >
                                                  <option selected>Choose Status</option>
                                                  <option value="Active">Active</option>
                                                  <option value="Deleted">Deleted</option>
                                                  <option value="Pending">Pending</option>
                                                  <option value="Approved">Approved</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-50 mx-4">
                                            <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                                              Country
                                            </label>
                                            <select
                                              class="form-select"
                                              aria-label="Default select example"
                                              // value={this.state.countryID}
                                              onChange={(e) => this.setState({ countryID: Math.floor(Math.random() + 1) })}
                                            >
                                              <option>Select Country</option>

                                              {this.state.countryName.map((country, i) => {
                                                {
                                                  /* console.log("Map Countries ", country.id, "index ",i+1) */
                                                }
                                                return <option key={i}>{country.name}</option>;
                                              })}
                                            </select>
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                              Close
                                            </button>
                                            <button
                                              type="button"
                                              style={{
                                                background: "linear-gradient(#3E94A6, #323F4B)",
                                              }}
                                              className="btn btn-primary"
                                              onClick={(e) => this.SaveUser(e)}
                                            >
                                              Add User
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone {...paginationProps} />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
};

const mapStateToProps = ({ Ecommerce }) => ({
  customers: Ecommerce.customers,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomers: () => dispatch(getCustomers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceCustomers);
