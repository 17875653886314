import React, { Component } from "react";
import Board from "@lourenci/react-kanban";
import { Row, Col, Button } from "reactstrap";
import CardBox from "./CardBox";
import CardHeader from "./CardHeader";
import PerfectScrollbar from "react-perfect-scrollbar";
import SalesAnalytics from "../../pages/Dashboard/SalesAnalytics";
import {
   AddGroups,
   getSubmitFirstDeal,
   getSubmitFirstDealDisApproved,
   DeleteGroup,
   EditGroup,
   getDeals,
   getGroups,
   AddTablesData,
} from "../Services/AllGroups/index";
import Swal from "sweetalert2";
import "./KanbanBoard.css";
import AddDivButton from "./AddDivButton";
import { Link } from "react-router-dom";
import { asCleanDays } from "@fullcalendar/core";
import { analytics } from "firebase";
import Pagination from "../VerticalLayout/pagination/Pagination";
import crossIcon from "../../assets/icons/cross-icon.svg";

class KanbanBoard extends Component {
   constructor(props) {
      super(props);
      // this.AddSlotDeal = this.AddSlotDeal.bind(this);

      this.AddSlotDeal = this.AddSlotDeal.bind(this);
      this.AddTableGroup = this.AddTableGroup.bind(this);
      this.submitFunctionFirstDeal = this.submitFunctionFirstDeal.bind(this);
      this.state = {
         slotDescription: "",
         slotName: "",

         loadingModel: true,
         selectedDealPrice: "",
         color: "",
         colorCode: "",
         vehicleName: "",
         vehicleArray: [],
         currentPage: "",
         lastPage: "",
         loading: false,
         groupAdd: false,
         groupsArray: [],
         loading: false,
         editGroupID: "",
         status: false,
         groupInput: [],
         pageIDs: [],

         currentID: "",
         actionValue: "",
         actionDetail: "",
         idFirstDeal: "",
         actionDetailVal: false,
         actionValueVal: false,
         currentState: "",
         add: false,
         titleFirstDeal: "",
         decpritionFirstDeal: "",
         imageBackGround: "",
         groupsArrayLink: "",
         groupPrice: "",
         dealSize: "",
         dealPrice: "",
         dealsLink: "",
         tablePrice: "",
         tableDuration: "",
         tableSlot: "",
         isEditDeal: "",
         dealStatus: "",

         daySwitch: "deactive",
         monthSwitch: "deactive",
         weekSwitch: "deactive",
         yearSwitch: "deactive",

         tablePriceId: "",
         tablePricePriceDay: "",
         tablePricePriceWeek: "",
         tablePricePriceMonth: "",
         tablePricePriceYear: "",

         tablePriceStatusDay: "",
         tablePriceStatusWeek: "",
         tablePriceStatusMonth: "",
         tablePriceStatusYear: "",

         tablePriceDurationDay: "",
         tablePriceDurationWeek: "",
         tablePriceDurationMonth: "",
         tablePriceDurationYear: "",

         tablePriceSlotDay: "",
         tablePriceSlotWeek: "",
         tablePriceSlotMonth: "",
         tablePriceSlotYear: "",

         groupDayPrice: "",
         groupWeekPrice: "",
         groupMonthPrice: "",
         groupYearPrice: "",

         slotNameValues: "",
         slotDescriptionValues: "",

         slotDescriptionVal: false,
         slotNameVal: false,

         // add slotes name
         slotFirstName: "",
         slotFirstDescription: "",
         slotFirstPricePerDay: "",
         slotFirstPricePerWeek: "",
         slotFirstPricePerMonth: "",
         slotFirstPricePerYear: "",

         slotFirstNameVal: false,
         slotFirstDescriptionVal: false,
         slotFirstPricePerDayVal: false,
         slotFirstPricePerWeekVal: false,
         slotFirstPricePerMonthVal: false,
         slotFirstPricePerYearVal: false,

         slotSecondName: "",
         slotSecondDescription: "",
         slotSecondPricePerDay: "",
         slotSecondPricePerWeek: "",
         slotSecondPricePerMonth: "",
         slotSecondPricePerYear: "",

         slotSecondNameVal: false,
         slotSecondDescriptionVal: false,
         slotSecondPricePerDayVal: false,
         slotSecondPricePerWeekVal: false,
         slotSecondPricePerMonthVal: false,
         slotSecondPricePerYearVal: false,

         slotThirdName: "",
         slotThirdDescription: "",
         slotThirdPricePerDay: "",
         slotThirdPricePerWeek: "",
         slotThirdPricePerMonth: "",
         slotThirdPricePerYear: "",

         slotThirdNameVal: false,
         slotThirdDescriptionVal: false,
         slotThirdPricePerDayVal: false,
         slotThirdPricePerWeekVal: false,
         slotThirdPricePerMonthVal: false,
         slotThirdPricePerYearVal: false,

         slotForthName: "",
         slotForthDescription: "",
         slotForthPricePerDay: "",
         slotForthPricePerWeek: "",
         slotForthPricePerMonth: "",
         slotForthPricePerYear: "",

         slotForthNameVal: false,
         slotForthDescriptionVal: false,
         slotForthPricePerDayVal: false,
         slotForthPricePerWeekVal: false,
         slotForthPricePerMonthVal: false,
         slotForthPricePerYearVal: false,

         firstDaySwitch: "deactive",
         secondDaySwitch: "deactive",
         thirdDaySwitch: "deactive",
         forthDaySwitch: "deactive",

         firstMonthSwitch: "deactive",
         secondMonthSwitch: "deactive",
         thirdMonthSwitch: "deactive",
         forthMonthSwitch: "deactive",

         firstWeekSwitch: "deactive",
         secondWeekSwitch: "deactive",
         thirdWeekSwitch: "deactive",
         forthWeekSwitch: "deactive",

         firstYearSwitch: "deactive",
         secondYearSwitch: "deactive",
         thirdYearSwitch: "deactive",
         forthYearSwitch: "deactive",
      };
   }

   // async AddGroup() {
   //   try {
   //     let tokenAccess = localStorage.getItem("Token");
   //     let accessToken = JSON.parse(tokenAccess);
   //     const groupsItem = await AddGroups(accessToken);
   //     if (groupsItem.status === true) {
   //       // allGroupArray=[...this.state.groupsArray]
   //       //    allGroupArray.push(groups.groups.data)
   //       //    this.setState({groupsArray:allGroupArray})
   //       Swal.fire({
   //         position: "top",
   //         icon: "success",
   //         title: "New Group Added Successfully",
   //         showConfirmButton: false,
   //         timer: 1000,
   //       });
   //       this.getAllGroups();
   //     }
   //   } catch (err) {
   //     console.log("error", err);
   //   }
   // }
   async AddTableGroup() {
      if (
         this.state.slotNameValues == "" ||
         this.state.slotNameValues == undefined
      ) {
         this.setState({ slotNameVal: true });
      }
      if (
         this.state.slotDescriptionValues == "" ||
         this.state.slotDescriptionValues == undefined
      ) {
         this.setState({ slotDescriptionVal: true });
      }
      if (
         this.state.slotNameValues != "" &&
         this.state.slotNameValues != undefined &&
         this.state.slotDescriptionValues != "" &&
         this.state.slotDescriptionValues != undefined
      ) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            const groupsTablesItem = await AddTablesData(
               accessToken,
               this.state.tablePricePriceDay,
               this.state.daySwitch,
               this.state.tablePricePriceWeek,
               this.state.weekSwitch,
               this.state.tablePricePriceMonth,
               this.state.monthSwitch,
               this.state.tablePricePriceYear,
               this.state.yearSwitch,
               this.state.tablePriceSlotDay,
               // this.state.slotNameValues != undefined && this.state.slotDescriptionValues

               this.state.slotDescriptionValues,
               this.state.slotNameValues
            );
            if (groupsTablesItem.status === true) {
               this.setState({
                  tablePricePriceDay: "",

                  tablePricePriceWeek: "",

                  tablePricePriceMonth: "",

                  tablePricePriceYear: "",

                  slotDescriptionValues: "",
                  slotNameValues: "",
               });
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Deal updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.getAllGroups();
            } else {
               // Swal.fire({
               //   position: "top",
               //   icon: "success",
               //   title: "Deal already exist",
               //   showConfirmButton: false,
               //   timer: 2000,
               // });
               // this.getAllGroups();
            }
         } catch (err) {
            console.log("error", err);
         }
      }
   }
   AllClearTableFields = () => {
      this.setState({
         tablePricePriceDay: "",

         tablePricePriceWeek: "",

         tablePricePriceMonth: "",

         tablePricePriceYear: "",

         slotDescriptionValues: "",
         slotNameValues: "",
      });
   };

   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllDeals();
      this.getAllGroups();
   }

   async getAllGroups(page) {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         let IDArray = [];

         const groupsdata = await getGroups(accessToken, page);
         if (groupsdata.status === true) {
            this.setState({ currentPage: groupsdata.groups.current_page });
            this.setState({ lastPage: groupsdata.groups.last_page });
            this.setState({ groupAdd: true });
            this.setState({ loading: true });
            this.setState({ groupsArray: groupsdata.groups.data });
            for (
               let i = groupsdata.groups.from;
               i <= groupsdata.groups.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async setInitialValue(id) {
      this.setState({
         editGroupID: id,
      });
   }

   async DeleteGroupHandler(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await DeleteGroup(accessToken, id);
            if (result.status === true) {
               this.getAllGroups();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Group removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async InputHandler(id, price) {
      this.setState({ currentID: id });
      this.setState({ status: true });
      this.setState({ currentState: price });
   }

   async SavePrice(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      this.setState({ status: false });
      this.setState({ currentID: "" });
      const result = await EditGroup(accessToken, this.state.currentState, id);
      if (result.status == true) {
         this.getAllGroups();

         Swal.fire({
            position: "top",
            icon: "success",
            title: "Price updated successfully",
            showConfirmButton: false,
            timer: 1000,
         });
      } else {
         Swal.fire({
            position: "top",
            icon: "warning",
            title: "Price already exist",
            showConfirmButton: false,
            timer: 1000,
         });
      }
   }

   async submitFunctionFirstDeal(id, reason) {
      this.setState({ actionValue: "", actionDetail: "" });
      if (this.state.actionValue == "disapprover") {
         if (
            this.state.actionDetail == undefined ||
            this.state.actionDetail == ""
         ) {
            this.setState({ actionDetailVal: true });
         } else {
            this.setState({ actionDetailVal: false });
         }
      }

      if (this.state.actionValue == "" || this.state.actionValue == undefined) {
         this.setState({ actionValueVal: true });
      }
      if (
         this.state.actionValue == "disapprover" &&
         this.state.actionDetailVal !== true
      ) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            if (
               this.state.actionDetail !== undefined &&
               this.state.actionDetail !== ""
            ) {
               const deals = await getSubmitFirstDealDisApproved(
                  accessToken,
                  id,
                  reason
               );
               if (deals.status === true) {
                  this.getAllGroups();
                  Swal.fire({
                     position: "top",
                     icon: "success",
                     title: "Deal disapproved successfully",
                     showConfirmButton: false,
                     timer: 1000,
                  });
               } else {
                  Swal.fire({
                     position: "top",
                     icon: "warning",
                     title: deals.message,
                     showConfirmButton: false,
                     timer: 1000,
                  });
               }
            }
         } catch (err) {
            console.log("error", err);
         }
      } else if (
         this.state.actionValue != "" &&
         this.state.actionValue != undefined
      ) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            {
               const deals = await getSubmitFirstDeal(accessToken, id);
               if (deals.status === true) {
                  this.getAllGroups();
                  Swal.fire({
                     position: "top",
                     icon: "success",
                     title: "Deal approved successfully",
                     showConfirmButton: false,
                     timer: 1000,
                  });
               } else {
                  Swal.fire({
                     position: "top",
                     icon: "warning",
                     title: deals.message,
                     showConfirmButton: false,
                     timer: 1000,
                  });
               }
            }
         } catch (err) {
            console.log("error", err);
         }
      }
   }

   async getAllDeals(page) {
      // let vehiclesArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const deals = await getDeals(accessToken, page);

         if (deals.status === true) {
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   paginate = (pageNumber) => {
      this.getAllGroups(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };

   initialValueFirstDeal = (
      id,
      decprition,
      title,
      size,
      price,
      link,
      imageBG,
      isShowAction,
      dealPrice
   ) => {
      this.setState({
         idFirstDeal: id,
         titleFirstDeal: title,
         decpritionFirstDeal: decprition,
         dealSize: size,
         dealPrice: price,
         dealsLink: link,
         imageBackGround: imageBG,
         dealStatus: isShowAction,
         selectedDealPrice: dealPrice,
      });
   };

   initialTableValueDeal = (
      priceList,
      slotNameValue,
      slotDescriptionValue,
      slotID,
      deal
   ) => {
      this.setState({
         tablePricePriceDay: "",

         tablePricePriceWeek: "",

         tablePricePriceMonth: "",

         tablePricePriceYear: "",

         slotDescriptionValues: "",
         slotNameValues: "",
         isEditDeal: "",
         daySwitch: "deactive",
         monthSwitch: "deactive",
         weekSwitch: "deactive",
         yearSwitch: "deactive",
      });

      this.setState({
         slotNameValues: slotNameValue,
         slotDescriptionValues: slotDescriptionValue,
         tablePriceSlotDay: slotID,

         isEditDeal: deal,
      });

      priceList.map((price, priceIndex) => {
         if (priceIndex === 0) {
            this.setState({
               tablePricePriceDay: price.price,
               tablePriceStatusDay: price.status,
               tablePriceDurationDay: price.duration,
            });
            if (price.status == "active") {
               this.AllDaySwitch(true, "daySwitch");
            } else {
               this.AllDaySwitch(false, "daySwitch");
            }
         } else if (priceIndex === 1) {
            this.setState({
               tablePricePriceWeek: price.price,
               tablePriceStatusWeek: price.status,
               tablePriceDurationWeek: price.duration,
               weekSwitch: price.status,
            });
            if (price.status == "active") {
               this.AllDaySwitch(true, "weekSwitch");
            } else {
               this.AllDaySwitch(false, "weekSwitch");
            }
         } else if (priceIndex === 2) {
            this.setState({
               tablePricePriceMonth: price.price,
               tablePriceStatusMonth: price.status,
               tablePriceDurationMonth: price.duration,
               monthSwitch: price.status,
            });

            if (price.status == "active") {
               this.AllDaySwitch(true, "monthSwitch");
            } else {
               this.AllDaySwitch(false, "monthSwitch");
            }
         } else if (priceIndex === 3) {
            this.setState({
               tablePricePriceYear: price.price,
               tablePriceStatusYear: price.status,
               tablePriceDurationYear: price.duration,
               yearSwitch: price.status,
            });
            if (price.status == "active") {
               this.AllDaySwitch(true, "yearSwitch");
            } else {
               this.AllDaySwitch(false, "yearSwitch");
            }
         }
      });
   };

   AllclearFields = () => {
      this.setState({
         actionDetail: "",
         slotNameVal: false,
         slotDescriptionVal: false,
         slotDescriptionVal: false,
         slotNameVal: false,
         slotFirstNameVal: false,
         slotFirstDescriptionVal: false,
         slotFirstPricePerDayVal: false,
         slotFirstPricePerWeekVal: false,
         slotFirstPricePerMonthVal: false,
         slotFirstPricePerYearVal: false,

         slotSecondNameVal: false,
         slotSecondDescriptionVal: false,
         slotSecondPricePerDayVal: false,
         slotSecondPricePerWeekVal: false,
         slotSecondPricePerMonthVal: false,
         slotSecondPricePerYearVal: false,
         slotThirdNameVal: false,
         slotThirdDescriptionVal: false,
         slotThirdPricePerDayVal: false,
         slotThirdPricePerWeekVal: false,
         slotThirdPricePerMonthVal: false,
         slotThirdPricePerYearVal: false,
         slotForthNameVal: false,
         slotForthDescriptionVal: false,
         slotForthPricePerDayVal: false,
         slotForthPricePerWeekVal: false,
         slotForthPricePerMonthVal: false,
         slotForthPricePerYearVal: false,
      });
   };

   AllFirstDaySwitch = (e, valueInput) => {
      if (valueInput == "firstDaySwitch") {
         if (e == true) {
            this.setState({ firstDaySwitch: "active" });
         } else {
            this.setState({ firstDaySwitch: "deactive" });
         }
      } else if (valueInput == "secondDaySwitch") {
         if (e == true) {
            this.setState({ secondDaySwitch: "active" });
         } else {
            this.setState({ secondDaySwitch: "deactive" });
         }
      } else if (valueInput == "thirdDaySwitch") {
         if (e == true) {
            this.setState({ thirdDaySwitch: "active" });
         } else {
            this.setState({ thirdDaySwitch: "deactive" });
         }
      } else if (valueInput == "forthDaySwitch") {
         if (e == true) {
            this.setState({ forthDaySwitch: "active" });
         } else {
            this.setState({ forthDaySwitch: "deactive" });
         }
      } else if (valueInput == "firstWeekSwitch") {
         if (e == true) {
            this.setState({ firstWeekSwitch: "active" });
         } else {
            this.setState({ firstWeekSwitch: "deactive" });
         }
      } else if (valueInput == "secondWeekSwitch") {
         if (e == true) {
            this.setState({ secondWeekSwitch: "active" });
         } else {
            this.setState({ secondWeekSwitch: "deactive" });
         }
      } else if (valueInput == "thirdWeekSwitch") {
         if (e == true) {
            this.setState({ thirdWeekSwitch: "active" });
         } else {
            this.setState({ thirdWeekSwitch: "deactive" });
         }
      } else if (valueInput == "forthWeekSwitch") {
         if (e == true) {
            this.setState({ forthWeekSwitch: "active" });
         } else {
            this.setState({ forthWeekSwitch: "deactive" });
         }
      } else if (valueInput == "firstMonthSwitch") {
         if (e == true) {
            this.setState({ firstMonthSwitch: "active" });
         } else {
            this.setState({ firstMonthSwitch: "deactive" });
         }
      } else if (valueInput == "secondMonthSwitch") {
         if (e == true) {
            this.setState({ secondMonthSwitch: "active" });
         } else {
            this.setState({ secondMonthSwitch: "deactive" });
         }
      } else if (valueInput == "thirdMonthSwitch") {
         if (e == true) {
            this.setState({ thirdMonthSwitch: "active" });
         } else {
            this.setState({ thirdMonthSwitch: "deactive" });
         }
      } else if (valueInput == "forthMonthSwitch") {
         if (e == true) {
            this.setState({ forthMonthSwitch: "active" });
         } else {
            this.setState({ forthMonthSwitch: "deactive" });
         }
      } else if (valueInput == "firstYearSwitch") {
         if (e == true) {
            this.setState({ firstYearSwitch: "active" });
         } else {
            this.setState({ firstYearSwitch: "deactive" });
         }
      } else if (valueInput == "secondYearSwitch") {
         if (e == true) {
            this.setState({ secondYearSwitch: "active" });
         } else {
            this.setState({ secondYearSwitch: "deactive" });
         }
      } else if (valueInput == "thirdYearSwitch") {
         if (e == true) {
            this.setState({ thirdYearSwitch: "active" });
         } else {
            this.setState({ thirdYearSwitch: "deactive" });
         }
      } else if (valueInput == "forthYearSwitch") {
         if (e == true) {
            this.setState({ forthYearSwitch: "active" });
         } else {
            this.setState({ forthYearSwitch: "deactive" });
         }
      }
   };

   AllDaySwitch = (e, valueInput) => {
      if (valueInput == "daySwitch") {
         if (e == true) {
            this.setState({ daySwitch: "active" });
         } else {
            this.setState({ daySwitch: "deactive" });
         }
      } else if (valueInput == "weekSwitch") {
         if (e == true) {
            this.setState({ weekSwitch: "active" });
         } else {
            this.setState({ weekSwitch: "deactive" });
         }
      } else if (valueInput == "monthSwitch") {
         if (e == true) {
            this.setState({ monthSwitch: "active" });
         } else {
            this.setState({ monthSwitch: "deactive" });
         }
      } else if (valueInput == "yearSwitch") {
         if (e == true) {
            this.setState({ yearSwitch: "active" });
         } else {
            this.setState({ yearSwitch: "deactive" });
         }
      }
   };

   async AddSlotDeal(e) {
      e.preventDefault();
      let inputRefs = [
         this.inputRef1,

         this.inputRef2,
         this.inputRef3,
         this.inputRef4,
         this.inputRef5,
         this.inputRef6,
         this.inputRef7,
         this.inputRef8,
         this.inputRef9,
         this.inputRef10,
         this.inputRef11,
         this.inputRef12,
         this.inputRef13,
         this.inputRef14,
         this.inputRef15,
         this.inputRef16,
         this.inputRef17,
         this.inputRef18,
         this.inputRef19,
         this.inputRef20,
         this.inputRef21,
         this.inputRef22,
         this.inputRef23,
         this.inputRef24,
         this.inputRef25,
         this.inputRef26,
         this.inputRef27,
         this.inputRef28,
         this.inputRef29,
         this.inputRef30,
         this.inputRef31,
         this.inputRef32,
         this.inputRef33,
         this.inputRef34,
      ];
      for (let inputRef of inputRefs) {
         if (inputRef.value === "") {
            inputRef.scrollIntoView();
            break;
         }
      }

      if (
         this.state.slotFirstName == "" ||
         this.state.slotFirstName == undefined
      ) {
         this.setState({ slotFirstNameVal: true });
      }

      if (
         this.state.slotFirstDescription == "" ||
         this.state.slotFirstDescription == undefined
      ) {
         this.setState({ slotFirstDescriptionVal: true });
      }

      if (
         this.state.slotFirstPricePerDay == "" ||
         this.state.slotFirstPricePerDay == undefined
      ) {
         this.setState({ slotFirstPricePerDayVal: true });
      }

      if (
         this.state.slotFirstPricePerWeek == "" ||
         this.state.slotFirstPricePerWeek == undefined
      ) {
         this.setState({ slotFirstPricePerWeekVal: true });
      }
      if (
         this.state.slotFirstPricePerMonth == "" ||
         this.state.slotFirstPricePerMonth == undefined
      ) {
         this.setState({ slotFirstPricePerMonthVal: true });
      }
      if (
         this.state.slotFirstPricePerYear == "" ||
         this.state.slotFirstPricePerYear == undefined
      ) {
         this.setState({ slotFirstPricePerYearVal: true });
      }
      /////////////////////////////
      if (
         this.state.slotSecondName == "" ||
         this.state.slotFirstName == undefined
      ) {
         this.setState({ slotSecondNameVal: true });
      }

      if (
         this.state.slotSecondDescription == "" ||
         this.state.slotFirstDescription == undefined
      ) {
         this.setState({ slotSecondDescriptionVal: true });
      }

      if (
         this.state.slotSecondPricePerDay == "" ||
         this.state.slotSecondPricePerDay == undefined
      ) {
         this.setState({ slotSecondPricePerDayVal: true });
      }

      if (
         this.state.slotSecondPricePerWeek == "" ||
         this.state.slotSecondPricePerWeek == undefined
      ) {
         this.setState({ slotSecondPricePerWeekVal: true });
      }
      if (
         this.state.slotSecondPricePerMonth == "" ||
         this.state.slotSecondPricePerMonth == undefined
      ) {
         this.setState({ slotSecondPricePerMonthVal: true });
      }
      if (
         this.state.slotSecondPricePerYear == "" ||
         this.state.slotSecondPricePerYear == undefined
      ) {
         this.setState({ slotSecondPricePerYearVal: true });
      }
      ///////////////////////////////////////////////
      if (
         this.state.slotThirdName == "" ||
         this.state.slotThirdName == undefined
      ) {
         this.setState({ slotThirdNameVal: true });
      }

      if (
         this.state.slotThirdDescription == "" ||
         this.state.slotThirdDescription == undefined
      ) {
         this.setState({ slotThirdDescriptionVal: true });
      }

      if (
         this.state.slotThirdPricePerDay == "" ||
         this.state.slotThirdPricePerDay == undefined
      ) {
         this.setState({ slotThirdPricePerDayVal: true });
      }

      if (
         this.state.slotThirdPricePerWeek == "" ||
         this.state.slotThirdPricePerWeek == undefined
      ) {
         this.setState({ slotThirdPricePerWeekVal: true });
      }
      if (
         this.state.slotThirdPricePerMonth == "" ||
         this.state.slotThirdPricePerMonth == undefined
      ) {
         this.setState({ slotThirdPricePerMonthVal: true });
      }
      if (
         this.state.slotThirdPricePerYear == "" ||
         this.state.slotThirdPricePerYear == undefined
      ) {
         this.setState({ slotThirdPricePerYearVal: true });
      }
      //////////////////////////////////////
      if (
         this.state.slotForthName == "" ||
         this.state.slotForthName == undefined
      ) {
         this.setState({ slotForthNameVal: true });
      }

      if (
         this.state.slotForthDescription == "" ||
         this.state.slotForthDescription == undefined
      ) {
         this.setState({ slotForthDescriptionVal: true });
      }

      if (
         this.state.slotForthPricePerDay == "" ||
         this.state.slotForthPricePerDay == undefined
      ) {
         this.setState({ slotForthPricePerDayVal: true });
      }

      if (
         this.state.slotForthPricePerWeek == "" ||
         this.state.slotForthPricePerWeek == undefined
      ) {
         this.setState({ slotForthPricePerWeekVal: true });
      }
      if (
         this.state.slotForthPricePerMonth == "" ||
         this.state.slotForthPricePerMonth == undefined
      ) {
         this.setState({ slotForthPricePerMonthVal: true });
      }
      if (
         this.state.slotForthPricePerYear == "" ||
         this.state.slotForthPricePerYear == undefined
      ) {
         this.setState({ slotForthPricePerYearVal: true });
      }

      if (
         this.state.slotFirstName != "" &&
         this.state.slotFirstName != undefined &&
         this.state.slotFirstDescription != "" &&
         this.state.slotFirstDescription != undefined &&
         this.state.slotSecondPricePerDay != "" &&
         this.state.slotSecondPricePerDay != undefined &&
         this.state.slotSecondPricePerWeek != "" &&
         this.state.slotSecondPricePerWeek != undefined &&
         this.state.slotSecondPricePerMonth != "" &&
         this.state.slotSecondPricePerMonth != undefined &&
         this.state.slotSecondPricePerYear != "" &&
         this.state.slotSecondPricePerYear != undefined &&
         this.state.slotSecondName != "" &&
         this.state.slotSecondName != undefined &&
         this.state.slotSecondDescription != "" &&
         this.state.slotSecondDescription != undefined &&
         this.state.slotFirstPricePerDay != "" &&
         this.state.slotFirstPricePerDay != undefined &&
         this.state.slotFirstPricePerWeek != "" &&
         this.state.slotFirstPricePerWeek != undefined &&
         this.state.slotFirstPricePerMonth != "" &&
         this.state.slotFirstPricePerMonth != undefined &&
         this.state.slotFirstPricePerYear != "" &&
         this.state.slotFirstPricePerYear != undefined &&
         this.state.slotThirdName != "" &&
         this.state.slotThirdName != undefined &&
         this.state.slotThirdDescription != "" &&
         this.state.slotThirdDescription != undefined &&
         this.state.slotThirdPricePerDay != "" &&
         this.state.slotThirdPricePerDay != undefined &&
         this.state.slotThirdPricePerWeek != "" &&
         this.state.slotThirdPricePerWeek != undefined &&
         this.state.slotThirdPricePerMonth != "" &&
         this.state.slotThirdPricePerMonth != undefined &&
         this.state.slotThirdPricePerYear != "" &&
         this.state.slotThirdPricePerYear != undefined &&
         this.state.slotForthName != "" &&
         this.state.slotForthName != undefined &&
         this.state.slotForthDescription != "" &&
         this.state.slotForthDescription != undefined &&
         this.state.slotForthPricePerDay != "" &&
         this.state.slotForthPricePerDay != undefined &&
         this.state.slotForthPricePerWeek != "" &&
         this.state.slotForthPricePerWeek != undefined &&
         this.state.slotForthPricePerMonth != "" &&
         this.state.slotForthPricePerMonth != undefined &&
         this.state.slotForthPricePerYear != "" &&
         this.state.slotForthPricePerYear != undefined
      ) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);
            this.setState({ loadingModel: false });

            const response = await AddGroups(
               accessToken,
               this.state.slotFirstName,
               this.state.slotFirstDescription,
               this.state.slotFirstPricePerDay,
               this.state.slotFirstPricePerWeek,
               this.state.slotFirstPricePerMonth,
               this.state.slotFirstPricePerYear,
               this.state.slotSecondName,
               this.state.slotSecondDescription,
               this.state.slotSecondPricePerDay,
               this.state.slotSecondPricePerWeek,
               this.state.slotSecondPricePerMonth,
               this.state.slotSecondPricePerYear,
               this.state.slotThirdName,
               this.state.slotThirdDescription,
               this.state.slotThirdPricePerDay,
               this.state.slotThirdPricePerWeek,
               this.state.slotThirdPricePerMonth,
               this.state.slotThirdPricePerYear,
               this.state.slotForthName,
               this.state.slotForthDescription,
               this.state.slotForthPricePerDay,
               this.state.slotForthPricePerWeek,
               this.state.slotForthPricePerMonth,
               this.state.slotForthPricePerYear,

               this.state.firstDaySwitch,
               this.state.secondDaySwitch,
               this.state.thirdDaySwitch,
               this.state.forthDaySwitch,

               this.state.firstMonthSwitch,
               this.state.secondMonthSwitch,
               this.state.thirdMonthSwitch,
               this.state.forthMonthSwitch,

               this.state.firstWeekSwitch,
               this.state.secondWeekSwitch,
               this.state.thirdWeekSwitch,
               this.state.forthWeekSwitch,

               this.state.firstYearSwitch,
               this.state.secondYearSwitch,
               this.state.thirdYearSwitch,
               this.state.forthYearSwitch
            );
            console.log(".,.,.", response);
            if (response.status == true) {
               this.getAllGroups();
               this.setState({ loadingModel: true });
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Group added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  slotFirstName: "",
                  slotFirstDescription: "",
                  slotFirstPricePerDay: "",
                  slotFirstPricePerWeek: "",
                  slotFirstPricePerMonth: "",
                  slotFirstPricePerYear: "",
                  slotSecondName: "",
                  slotSecondDescription: "",
                  slotSecondPricePerDay: "",
                  slotSecondPricePerWeek: "",
                  slotSecondPricePerMonth: "",
                  slotSecondPricePerYear: "",
                  slotThirdName: "",
                  slotThirdDescription: "",
                  slotThirdPricePerDay: "",
                  slotThirdPricePerWeek: "",
                  slotThirdPricePerMonth: "",
                  slotThirdPricePerYear: "",
                  slotForthName: "",
                  slotForthDescription: "",
                  slotForthPricePerDay: "",
                  slotForthPricePerWeek: "",
                  slotForthPricePerMonth: "",
                  slotForthPricePerYear: "",

                  firstDaySwitch: "",
                  secondDaySwitch: "",
                  thirdDaySwitch: "",
                  forthDaySwitch: "",

                  firstMonthSwitch: "",
                  secondMonthSwitch: "",
                  thirdMonthSwitch: "",
                  forthMonthSwitch: "",

                  firstWeekSwitch: "",
                  secondWeekSwitch: "",
                  thirdWeekSwitch: "",
                  forthWeekSwitch: "",

                  firstYearSwitch: "",
                  secondYearSwitch: "",
                  thirdYearSwitch: "",
                  forthYearSwitch: "",
               });
            } else {
               this.setState({ loadingModel: false });
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 2000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllGroups(page);
   };

   render() {
      const content = this.props.board;
      return (
         <React.Fragment>
            {/* <Row className="mb-4 "> */}
            <div style={{ marginTop: -53 }}>
               <div className="d-flex justify-content-end">
                  <div className="mb-2 ">
                     <Button
                        type="button"
                        style={{
                           background: "linear-gradient(#3E94A6, #323F4B)",
                           color: "white",
                        }}
                        className="btn-rounded "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={this.AllclearFields}
                     >
                        {this.state.loadingModel === true ? (
                           <span>Add Group</span>
                        ) : (
                           <span className="">
                              <div
                                 class="spinner-border text-center"
                                 role="status"
                              >
                                 <span class="visually-hidden"></span>
                              </div>
                           </span>
                        )}
                     </Button>
                  </div>
                  {/* </div> */}
                  {/*Add Modal */}
                  <div
                     className="modal fade"
                     id="staticBackdrop"
                     data-bs-backdrop="static"
                     data-bs-keyboard="false"
                     tabIndex={-1}
                     aria-labelledby="staticBackdropLabel"
                     aria-hidden="true"
                  >
                     <div className="modal-dialog">
                        <div className="modal-content">
                           <div className="modal-header">
                              <h5
                                 className="modal-title"
                                 id="staticBackdropLabel"
                              >
                                 Add Group
                              </h5>
                              <button
                                 type="button"
                                 onClick={this.AllClearField}
                                 className="btn-close"
                                 data-bs-dismiss="modal"
                                 aria-label="Close"
                              />
                           </div>
                           <PerfectScrollbar style={{ maxHeight: "600px" }}>
                              <div className="modal-body ">
                                 <div className=" text-start text-black border-bottom border-4 ">
                                    <h3
                                       className="modal-title text-uppercase fw-bold"
                                       id="staticBackdropLabel"
                                    ></h3>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="me-2 fw-bolder  form-label mb-3"
                                       >
                                          <h5>
                                             First Slot Detail
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                          </h5>
                                       </label>
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Name
                                          </h6>
                                       </label>
                                       <input
                                          type="text"
                                          ref={(inputRef) =>
                                             (this.inputRef1 = inputRef)
                                          }
                                          value={this.state.slotFirstName}
                                          onChange={(e) =>
                                             this.setState({
                                                slotFirstName: e.target.value,
                                                slotFirstNameVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotFirstNameVal ? (
                                          <div className="modelValidation">
                                             Enter slot name
                                          </div>
                                       ) : null}
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Description
                                          </h6>
                                       </label>
                                       <textarea
                                          ref={(inputRef) =>
                                             (this.inputRef2 = inputRef)
                                          }
                                          type="text"
                                          value={
                                             this.state.slotFirstDescription
                                          }
                                          onChange={(e) =>
                                             this.setState({
                                                slotFirstDescription:
                                                   e.target.value,
                                                slotFirstDescriptionVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotFirstDescriptionVal ? (
                                          <div className="modelValidation">
                                             Enter slot description
                                          </div>
                                       ) : null}
                                    </div>
                                    <br />
                                    <div className="table_data">
                                       <table class="table  shadow border rounded-0 text-center">
                                          <thead className="bg-light">
                                             <tr>
                                                <th scope="col">Price</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Status</th>
                                             </tr>
                                          </thead>
                                          <tbody className="text-center">
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef3 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotFirstPricePerDay
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotFirstPricePerDay:
                                                               e.target.value,
                                                            slotFirstPricePerDayVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotFirstPricePerDayVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per day price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Day
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef4 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                         checked={
                                                            this.state
                                                               .firstDaySwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "firstDaySwitch"
                                                            )
                                                         }

                                                         // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef5 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotFirstPricePerWeek
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotFirstPricePerWeek:
                                                               e.target.value,
                                                            slotFirstPricePerWeekVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotFirstPricePerWeekVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per week price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Week
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef6 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .firstWeekSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "firstWeekSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef7 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotFirstPricePerMonth
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotFirstPricePerMonth:
                                                               e.target.value,
                                                            slotFirstPricePerMonthVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotFirstPricePerMonthVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap "
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per month price{" "}
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Month
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef8 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .firstMonthSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "firstMonthSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef9 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotFirstPricePerYear
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotFirstPricePerYear:
                                                               e.target.value,
                                                            slotFirstPricePerYearVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotFirstPricePerYearVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per year price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Year
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef10 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .firstYearSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "firstYearSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="mt-3 text-start text-black border-bottom border-4">
                                    <h3
                                       className="modal-title text-uppercase fw-bold"
                                       id="staticBackdropLabel"
                                    ></h3>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="me-2 fw-bolder  form-label mb-3"
                                       >
                                          <h5>
                                             Second Slot Detail
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                          </h5>
                                       </label>
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Name
                                          </h6>
                                       </label>

                                       <input
                                          ref={(inputRef) =>
                                             (this.inputRef11 = inputRef)
                                          }
                                          type="text"
                                          value={this.state.slotSecondName}
                                          onChange={(e) =>
                                             this.setState({
                                                slotSecondName: e.target.value,
                                                slotSecondNameVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotSecondNameVal ? (
                                          <div className="modelValidation">
                                             Enter slot name
                                          </div>
                                       ) : null}
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Description
                                          </h6>
                                       </label>
                                       <textarea
                                          ref={(inputRef) =>
                                             (this.inputRef12 = inputRef)
                                          }
                                          type="text"
                                          value={
                                             this.state.slotSecondDescription
                                          }
                                          onChange={(e) =>
                                             this.setState({
                                                slotSecondDescription:
                                                   e.target.value,
                                                slotSecondDescriptionVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotSecondDescriptionVal ? (
                                          <div className="modelValidation">
                                             Enter slot description
                                          </div>
                                       ) : null}
                                    </div>
                                    <br />
                                    <div className="table_data">
                                       <table class="table  shadow border rounded-0 text-center">
                                          <thead className="bg-light">
                                             <tr>
                                                <th scope="col">Price</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Status</th>
                                             </tr>
                                          </thead>
                                          <tbody className="text-center">
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef13 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotSecondPricePerDay
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotSecondPricePerDay:
                                                               e.target.value,
                                                            slotSecondPricePerDayVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotSecondPricePerDayVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per day price
                                                      </div>
                                                   ) : null}
                                                </td>

                                                <td className="text-nowrap">
                                                   Per Day
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef14 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                         checked={
                                                            this.state
                                                               .secondDaySwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "secondDaySwitch"
                                                            )
                                                         }

                                                         // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef15 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotSecondPricePerWeek
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotSecondPricePerWeek:
                                                               e.target.value,
                                                            slotSecondPricePerWeekVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotSecondPricePerWeekVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per week price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Week
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef16 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .secondWeekSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "secondWeekSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef17 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotSecondPricePerMonth
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotSecondPricePerMonth:
                                                               e.target.value,
                                                            slotSecondPricePerMonthVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotSecondPricePerMonthVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per month price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Month
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef18 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .secondMonthSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "secondMonthSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      type="number"
                                                      ref={(inputRef) =>
                                                         (this.inputRef19 =
                                                            inputRef)
                                                      }
                                                      value={
                                                         this.state
                                                            .slotSecondPricePerYear
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotSecondPricePerYear:
                                                               e.target.value,
                                                            slotSecondPricePerYearVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotSecondPricePerYearVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per year price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Year
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef20 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .secondYearSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "secondYearSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="mt-3 text-start text-black border-bottom border-4">
                                    <h3
                                       className="modal-title text-uppercase fw-bold"
                                       id="staticBackdropLabel"
                                    ></h3>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="me-2 fw-bolder  form-label mb-3"
                                       >
                                          <h5>
                                             Third Slot Detail
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                          </h5>
                                       </label>
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Name
                                          </h6>
                                       </label>

                                       <input
                                          ref={(inputRef) =>
                                             (this.inputRef21 = inputRef)
                                          }
                                          type="text"
                                          value={this.state.slotThirdName}
                                          onChange={(e) =>
                                             this.setState({
                                                slotThirdName: e.target.value,
                                                slotThirdNameVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotThirdNameVal ? (
                                          <div className="modelValidation">
                                             Enter slot name
                                          </div>
                                       ) : null}
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Description
                                          </h6>
                                       </label>
                                       <textarea
                                          ref={(inputRef) =>
                                             (this.inputRef22 = inputRef)
                                          }
                                          type="text"
                                          value={
                                             this.state.slotThirdDescription
                                          }
                                          onChange={(e) =>
                                             this.setState({
                                                slotThirdDescription:
                                                   e.target.value,
                                                slotThirdDescriptionVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotThirdDescriptionVal ? (
                                          <div className="modelValidation">
                                             Enter slot description
                                          </div>
                                       ) : null}
                                    </div>
                                    <br />
                                    <div className="table_data">
                                       <table class="table  shadow border rounded-0 text-center">
                                          <thead className="bg-light">
                                             <tr>
                                                <th scope="col">Price</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Status</th>
                                             </tr>
                                          </thead>
                                          <tbody className="text-center">
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef23 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotThirdPricePerDay
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotThirdPricePerDay:
                                                               e.target.value,
                                                            slotThirdPricePerDayVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotThirdPricePerDayVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per day price{" "}
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Day
                                                </td>

                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef24 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                         checked={
                                                            this.state
                                                               .thirdDaySwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "thirdDaySwitch"
                                                            )
                                                         }

                                                         // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef25 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotThirdPricePerWeek
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotThirdPricePerWeek:
                                                               e.target.value,
                                                            slotThirdPricePerWeekVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotThirdPricePerWeekVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per week price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Week
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef26 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .thirdWeekSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "thirdWeekSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef27 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotThirdPricePerMonth
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotThirdPricePerMonth:
                                                               e.target.value,
                                                            slotThirdPricePerMonthVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotThirdPricePerMonthVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per month price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Month
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef28 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .thirdMonthSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "thirdMonthSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef29 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotThirdPricePerYear
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotThirdPricePerYear:
                                                               e.target.value,
                                                            slotThirdPricePerYearVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotThirdPricePerYearVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per year price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Year
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef30 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .thirdYearSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "thirdYearSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="mt-3 text-start text-black border-bottom border-4">
                                    <h3
                                       className="modal-title text-uppercase fw-bold"
                                       id="staticBackdropLabel"
                                    ></h3>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="me-2 fw-bolder  form-label mb-3"
                                       >
                                          <h5>
                                             Forth Slot Detail
                                             &nbsp;&nbsp;&nbsp;&nbsp;
                                          </h5>
                                       </label>
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Name
                                          </h6>
                                       </label>
                                       <input
                                          ref={(inputRef) =>
                                             (this.inputRef31 = inputRef)
                                          }
                                          type="text"
                                          value={this.state.slotForthName}
                                          onChange={(e) =>
                                             this.setState({
                                                slotForthName: e.target.value,
                                                slotForthNameVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotForthNameVal ? (
                                          <div className="modelValidation">
                                             Enter slot name
                                          </div>
                                       ) : null}
                                    </div>
                                    <div>
                                       <label
                                          htmlFor="exampleFormControlInput1"
                                          className="mb-0 fw-bolder  form-label "
                                       >
                                          <h6 className="required">
                                             Slot Description
                                          </h6>
                                       </label>
                                       <textarea
                                          ref={(inputRef) =>
                                             (this.inputRef32 = inputRef)
                                          }
                                          type="text"
                                          value={
                                             this.state.slotForthDescription
                                          }
                                          onChange={(e) =>
                                             this.setState({
                                                slotForthDescription:
                                                   e.target.value,
                                                slotForthDescriptionVal: false,
                                             })
                                          }
                                          class="form-control w-100"
                                       />
                                       {this.state.slotForthDescriptionVal ? (
                                          <div className="modelValidation">
                                             Enter slot description
                                          </div>
                                       ) : null}
                                    </div>
                                    <br />
                                    <div className="table_data">
                                       <table class="table  shadow border rounded-0 text-center">
                                          <thead className="bg-light">
                                             <tr>
                                                <th scope="col">Price</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Status</th>
                                             </tr>
                                          </thead>
                                          <tbody className="text-center">
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      ref={(inputRef) =>
                                                         (this.inputRef33 =
                                                            inputRef)
                                                      }
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotForthPricePerDay
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotForthPricePerDay:
                                                               e.target.value,
                                                            slotForthPricePerDayVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotForthPricePerDayVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap "
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per day price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Day
                                                </td>

                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         ref={(inputRef) =>
                                                            (this.inputRef34 =
                                                               inputRef)
                                                         }
                                                         class="form-check-input"
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                         checked={
                                                            this.state
                                                               .forthDaySwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "forthDaySwitch"
                                                            )
                                                         }

                                                         // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotForthPricePerWeek
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotForthPricePerWeek:
                                                               e.target.value,
                                                            slotForthPricePerWeekVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotForthPricePerWeekVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per week price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Week
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .forthWeekSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "forthWeekSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotForthPricePerMonth
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotForthPricePerMonth:
                                                               e.target.value,
                                                            slotForthPricePerMonthVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotForthPricePerMonthVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per month price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Month
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .forthMonthSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "forthMonthSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="w-50">
                                                   $
                                                   <input
                                                      type="number"
                                                      value={
                                                         this.state
                                                            .slotForthPricePerYear
                                                      }
                                                      onChange={(e) =>
                                                         this.setState({
                                                            slotForthPricePerYear:
                                                               e.target.value,
                                                            slotForthPricePerYearVal: false,
                                                         })
                                                      }
                                                      className="w-50"
                                                   />
                                                   {this.state
                                                      .slotForthPricePerYearVal ? (
                                                      <div
                                                         className="modelValidation text-nowrap"
                                                         style={{
                                                            marginRight:
                                                               "3.8rem",
                                                            marginLeft:
                                                               "4.5rem",
                                                         }}
                                                      >
                                                         Enter per year price
                                                      </div>
                                                   ) : null}
                                                </td>
                                                <td className="text-nowrap">
                                                   Per Year
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                   <div class="form-check form-switch">
                                                      <input
                                                         class="form-check-input"
                                                         checked={
                                                            this.state
                                                               .forthYearSwitch ==
                                                            "active"
                                                               ? true
                                                               : false
                                                         }
                                                         onChange={(e) =>
                                                            this.AllFirstDaySwitch(
                                                               e.target.checked,
                                                               "forthYearSwitch"
                                                            )
                                                         }
                                                         type="checkbox"
                                                         id="flexSwitchCheckChecked"
                                                      />
                                                   </div>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </PerfectScrollbar>
                           <div className="modal-footer">
                              <button
                                 type="button"
                                 className="btn btn-secondary "
                                 data-bs-dismiss="modal"
                              >
                                 Close
                              </button>
                              {this.state.slotFirstName != "" &&
                              this.state.slotFirstName != undefined &&
                              this.state.slotFirstDescription != "" &&
                              this.state.slotFirstDescription != undefined &&
                              this.state.slotSecondPricePerDay != "" &&
                              this.state.slotSecondPricePerDay != undefined &&
                              this.state.slotSecondPricePerWeek != "" &&
                              this.state.slotSecondPricePerWeek != undefined &&
                              this.state.slotSecondPricePerMonth != "" &&
                              this.state.slotSecondPricePerMonth != undefined &&
                              this.state.slotSecondPricePerYear != "" &&
                              this.state.slotSecondPricePerYear != undefined &&
                              this.state.slotSecondName != "" &&
                              this.state.slotSecondName != undefined &&
                              this.state.slotSecondDescription != "" &&
                              this.state.slotSecondDescription != undefined &&
                              this.state.slotFirstPricePerDay != "" &&
                              this.state.slotFirstPricePerDay != undefined &&
                              this.state.slotFirstPricePerWeek != "" &&
                              this.state.slotFirstPricePerWeek != undefined &&
                              this.state.slotFirstPricePerMonth != "" &&
                              this.state.slotFirstPricePerMonth != undefined &&
                              this.state.slotFirstPricePerYear != "" &&
                              this.state.slotFirstPricePerYear != undefined &&
                              this.state.slotThirdName != "" &&
                              this.state.slotThirdName != undefined &&
                              this.state.slotThirdDescription != "" &&
                              this.state.slotThirdDescription != undefined &&
                              this.state.slotThirdPricePerDay != "" &&
                              this.state.slotThirdPricePerDay != undefined &&
                              this.state.slotThirdPricePerWeek != "" &&
                              this.state.slotThirdPricePerWeek != undefined &&
                              this.state.slotThirdPricePerMonth != "" &&
                              this.state.slotThirdPricePerMonth != undefined &&
                              this.state.slotThirdPricePerYear != "" &&
                              this.state.slotThirdPricePerYear != undefined &&
                              this.state.slotForthName != "" &&
                              this.state.slotForthName != undefined &&
                              this.state.slotForthDescription != "" &&
                              this.state.slotForthDescription != undefined &&
                              this.state.slotForthPricePerDay != "" &&
                              this.state.slotForthPricePerDay != undefined &&
                              this.state.slotForthPricePerWeek != "" &&
                              this.state.slotForthPricePerWeek != undefined &&
                              this.state.slotForthPricePerMonth != "" &&
                              this.state.slotForthPricePerMonth != undefined &&
                              this.state.slotForthPricePerYear != "" &&
                              this.state.slotForthPricePerYear != undefined ? (
                                 <button
                                    // data-bs-dismiss="modal"
                                    type="button"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn btn-primary   me-2"
                                    onClick={this.AddSlotDeal}
                                    data-bs-dismiss="modal"
                                    ref={(node) => (this.btn = node)}
                                 >
                                    Add Group
                                 </button>
                              ) : (
                                 <button
                                    // data-bs-dismiss="modal"
                                    type="button"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn btn-primary   me-2"
                                    onClick={this.AddSlotDeal}
                                    ref={(node) => (this.btn = node)}
                                 >
                                    Add Group
                                 </button>
                              )}
                              {/* //////////////////////////////// */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="DealsBox">
               <div className="row">
                  <div className="col-md-12">
                     <div className="container"></div>
                  </div>
               </div>
            </div>
            {this.state.loading === true ? (
               <div className="row">
                  <Col className="col-md-4">
                     {this.state.groupsArray.map((group, outer) => {
                        return (
                           <div
                              className="mb-4 py-2 rounded wide"
                              style={{ backgroundColor: "#fff" }}
                           >
                              <div className="d-flex justify-content-between">
                                 <div>
                                    {this.state.pageIDs?.map((item, inner) =>
                                       outer == inner ? (
                                          <h4 className="card-title ms-2">
                                             Group Number: {item.id}
                                          </h4>
                                       ) : null
                                    )}

                                    <p className="ms-2">
                                       Total Slots: {group.slots?.length}
                                    </p>
                                 </div>
                                 <div>
                                    <i
                                       class="me-2"
                                       title="Delete Group"
                                       style={{ cursor: "pointer" }}
                                       onClick={() =>
                                          this.DeleteGroupHandler(group.id)
                                       }
                                    >
                                       <img
                                          src={crossIcon}
                                          alt=""
                                          width={22}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                             this.DeleteGroupHandler(group.id)
                                          }
                                       />
                                    </i>
                                 </div>
                              </div>
                              {/* 0:size: '320x120', 1: size: '320x260', 2: size: '145x120', 3:size: '145x120', */}
                              {group.slots.map((slot, index) => (
                                 <>
                                    <div className="main p-3">
                                       <div className="row">
                                          <div className="col-md-12">
                                             {index == 0 ? (
                                                <>
                                                   <div className="card shadow-none bg-light p-2">
                                                      <div className="Topicons d-flex justify-content-between">
                                                         <div className="badges">
                                                            <span className="badge bg-success">
                                                               {
                                                                  slot.deal
                                                                     ?.status
                                                               }
                                                            </span>
                                                            {slot.deal
                                                               ?.published ==
                                                            "under_review" ? (
                                                               <span className="badge bg-danger mx-1">
                                                                  under review
                                                               </span>
                                                            ) : (
                                                               <span className="badge bg-danger mx-1">
                                                                  {
                                                                     slot.deal
                                                                        ?.published
                                                                  }
                                                               </span>
                                                            )}
                                                            <Link
                                                               to="#"
                                                               className="me-0 mb-2 text-primary"
                                                            >
                                                               <i
                                                                  className="mdi mdi-pencil font-size-18 me-2"
                                                                  title="Take Action"
                                                                  onClick={() =>
                                                                     this.initialValueFirstDeal(
                                                                        slot
                                                                           .deal
                                                                           ?.id,

                                                                        slot
                                                                           .deal
                                                                           ?.description,
                                                                        slot
                                                                           .deal
                                                                           ?.title,
                                                                        slot?.size,
                                                                        slot
                                                                           .deal
                                                                           ?.deal_price
                                                                           ?.price,
                                                                        slot
                                                                           .deal
                                                                           ?.link,

                                                                        `${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name}`,
                                                                        slot
                                                                           .deal
                                                                           ?.status,
                                                                        slot
                                                                           .deal
                                                                           ?.slot_price
                                                                     )
                                                                  }
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#Backdrop"
                                                               ></i>
                                                            </Link>
                                                         </div>
                                                         <div className="icons">
                                                            <Link
                                                               to="#"
                                                               className="me-0 mb-2 text-primary"
                                                            >
                                                               <i
                                                                  className="mdi mdi-table font-size-18 "
                                                                  title="Slot Detail"
                                                                  onClick={() => {
                                                                     this.initialTableValueDeal(
                                                                        slot.slot_pricing,
                                                                        slot.name,
                                                                        slot.description,
                                                                        slot.id,
                                                                        slot.deal
                                                                     );
                                                                     this.AllclearFields();
                                                                  }}
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#BackdropTwo"
                                                               ></i>
                                                            </Link>
                                                         </div>
                                                      </div>
                                                      <hr className="mt-0" />

                                                      {slot.deal === null ? (
                                                         <div
                                                            className="SlotOne SlotBackground  text-white rounded d-flex justify-content-center align-items-center"
                                                            style={{
                                                               width: "320px",
                                                               height: "120px",
                                                            }}
                                                         >
                                                            <div className="Text text-center SlotTextColor">
                                                               <h5 className="mb-0">
                                                                  Slot Ready For
                                                                  Booking
                                                               </h5>
                                                               <p className="mb-0">
                                                                  Recommended
                                                                  Size
                                                               </p>
                                                               <p className="mb-0">
                                                                  320 x 120
                                                               </p>
                                                            </div>
                                                         </div>
                                                      ) : (
                                                         <div
                                                            className="card-body"
                                                            style={{
                                                               width: "-webkit-fill-available",
                                                               height: "120px",
                                                               margin: "auto",
                                                               borderRadius:
                                                                  "5px",
                                                               backgroundColor:
                                                                  slot.deal
                                                                     ?.deal_image[0]
                                                                     ? ""
                                                                     : "#848784",
                                                               backgroundImage: `url(${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name}`,
                                                            }}
                                                         ></div>
                                                      )}
                                                   </div>

                                                   {/* ///////////////////////////////edit model/ */}
                                                   <div
                                                      className="modal fade "
                                                      id="Backdrop"
                                                      tabIndex={-1}
                                                      data-bs-backdrop="static"
                                                      data-bs-keyboard="false"
                                                      aria-labelledby="Backdrop"
                                                      aria-hidden="true"
                                                   >
                                                      <div className="modal-dialog modal-lg ">
                                                         <button
                                                            type="button"
                                                            className="btn-close"
                                                            style={{
                                                               position:
                                                                  "absolute",
                                                               right: "20px",
                                                            }}
                                                            // onClick={this.AllclearFields}
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            // onClick={this.AllClearTableFields}
                                                         />
                                                         <div className="modal-content rounded-0 ">
                                                            {/* <div className="modal-header">
                                    </div> */}
                                                            {this.state
                                                               .imageBackGround ==
                                                            "undefinedundefined" ? (
                                                               <>
                                                                  <div className="modal-header">
                                                                     <h5 className="modal-title">
                                                                        Edit
                                                                        Deal
                                                                     </h5>
                                                                     <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                     />
                                                                  </div>
                                                                  <div className="modal-body ">
                                                                     <h4 className="text-center ">
                                                                        Deal Not
                                                                        Booked
                                                                     </h4>
                                                                  </div>
                                                               </>
                                                            ) : (
                                                               <div className="modal-body ">
                                                                  <div className="text-start text-black">
                                                                     <div className="row">
                                                                        <div className="col-md-5 text-center">
                                                                           <img
                                                                              className="img-fluid"
                                                                              src={
                                                                                 this
                                                                                    .state
                                                                                    .imageBackGround
                                                                              }
                                                                              alt=""
                                                                           />
                                                                        </div>
                                                                        <button
                                                                           type="button"
                                                                           className="btn-close"
                                                                           style={{
                                                                              position:
                                                                                 "absolute",
                                                                              right: "20px",
                                                                           }}
                                                                           onClick={
                                                                              this
                                                                                 .AllclearFields
                                                                           }
                                                                           data-bs-dismiss="modal"
                                                                           aria-label="Close"
                                                                        />
                                                                        <div className="col-md-6 align-self-center">
                                                                           <h4
                                                                              className="modal-title text-uppercase"
                                                                              id="staticBackdropLabel"
                                                                           >
                                                                              Edit
                                                                              Deal
                                                                           </h4>
                                                                           <hr />
                                                                           <div className="row">
                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Title
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 {
                                                                                    this
                                                                                       .state
                                                                                       .titleFirstDeal
                                                                                 }
                                                                              </div>

                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Price
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 {
                                                                                    this
                                                                                       .state
                                                                                       .selectedDealPrice
                                                                                       ?.price
                                                                                 }
                                                                              </div>
                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Duration
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 {this
                                                                                    .state
                                                                                    .selectedDealPrice
                                                                                    ?.duration ==
                                                                                 "per_day"
                                                                                    ? "Per Day"
                                                                                    : this
                                                                                         .state
                                                                                         .selectedDealPrice
                                                                                         ?.duration ==
                                                                                      "per_week"
                                                                                    ? "Per Week"
                                                                                    : this
                                                                                         .state
                                                                                         .selectedDealPrice
                                                                                         ?.duration ==
                                                                                      "per_month"
                                                                                    ? "Per Month"
                                                                                    : this
                                                                                         .state
                                                                                         .selectedDealPrice
                                                                                         ?.duration ==
                                                                                      "per_year"
                                                                                    ? "Per Year"
                                                                                    : ""}
                                                                              </div>
                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Size
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 {
                                                                                    this
                                                                                       .state
                                                                                       .dealSize
                                                                                 }
                                                                              </div>
                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Link
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 <a
                                                                                    target="_blank"
                                                                                    href={
                                                                                       this
                                                                                          .state
                                                                                          .dealsLink
                                                                                    }
                                                                                 >
                                                                                    {
                                                                                       this
                                                                                          .state
                                                                                          .dealsLink
                                                                                    }
                                                                                 </a>
                                                                              </div>
                                                                              <div className="col-3">
                                                                                 <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="me-2 fw-bolder  form-label mb-3"
                                                                                 >
                                                                                    Description
                                                                                 </label>
                                                                              </div>
                                                                              <div className="col-9">
                                                                                 {
                                                                                    this
                                                                                       .state
                                                                                       .decpritionFirstDeal
                                                                                 }
                                                                              </div>
                                                                              {this
                                                                                 .state
                                                                                 .dealStatus ==
                                                                              "approved" ? (
                                                                                 ""
                                                                              ) : (
                                                                                 <>
                                                                                    <div className="col-12">
                                                                                       <label
                                                                                          htmlFor="exampleFormControlInput1"
                                                                                          className="me-2 fw-bolder  form-label"
                                                                                       >
                                                                                          Take
                                                                                          Action
                                                                                       </label>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                       <select
                                                                                          class="form-select w-50 shadow rounded-0 "
                                                                                          aria-label="Default select example"
                                                                                          value={
                                                                                             this
                                                                                                .state
                                                                                                .actionValue
                                                                                          }
                                                                                          onChange={(
                                                                                             e
                                                                                          ) =>
                                                                                             this.setState(
                                                                                                {
                                                                                                   actionValue:
                                                                                                      e
                                                                                                         .target
                                                                                                         .value,
                                                                                                   actionValueVal: false,
                                                                                                }
                                                                                             )
                                                                                          }
                                                                                       >
                                                                                          <option
                                                                                             hidden
                                                                                          >
                                                                                             Take
                                                                                             Action
                                                                                          </option>

                                                                                          <option value="approver">
                                                                                             Approve
                                                                                          </option>
                                                                                          <option value="disapprover">
                                                                                             Dis-approve
                                                                                          </option>
                                                                                       </select>
                                                                                       {this
                                                                                          .state
                                                                                          .actionValueVal ? (
                                                                                          <div className="modelValidation">
                                                                                             Take
                                                                                             Action
                                                                                          </div>
                                                                                       ) : null}
                                                                                    </div>
                                                                                 </>
                                                                              )}

                                                                              {this
                                                                                 .state
                                                                                 .actionValue ==
                                                                              "disapprover" ? (
                                                                                 <div className="mt-3">
                                                                                    <div>
                                                                                       <textarea
                                                                                          value={
                                                                                             this
                                                                                                .state
                                                                                                .actionDetail
                                                                                          }
                                                                                          onChange={(
                                                                                             e
                                                                                          ) =>
                                                                                             this.setState(
                                                                                                {
                                                                                                   actionDetail:
                                                                                                      e
                                                                                                         .target
                                                                                                         .value,
                                                                                                   actionDetailVal: false,
                                                                                                }
                                                                                             )
                                                                                          }
                                                                                          name=""
                                                                                          placeholder="Enter Reasons"
                                                                                          id=""
                                                                                          cols="40"
                                                                                          rows="6"
                                                                                       ></textarea>
                                                                                       {this
                                                                                          .state
                                                                                          .actionDetailVal ? (
                                                                                          <div className="modelValidation">
                                                                                             Enter
                                                                                             Reasons
                                                                                          </div>
                                                                                       ) : null}
                                                                                    </div>
                                                                                 </div>
                                                                              ) : null}
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            )}
                                                            <div className="modal-footer ">
                                                               <button
                                                                  type="button"
                                                                  onClick={
                                                                     this
                                                                        .AllclearFields
                                                                  }
                                                                  className="btn btn-secondary rounded-0"
                                                                  data-bs-dismiss="modal"
                                                               >
                                                                  Close
                                                               </button>
                                                               {this.state
                                                                  .imageBackGround ==
                                                                  "undefinedundefined" ||
                                                               this.state
                                                                  .dealStatus ==
                                                                  "approved" ? null : (
                                                                  <>
                                                                     {(this
                                                                        .state
                                                                        .actionValue ==
                                                                     "disapprover"
                                                                        ? this
                                                                             .state
                                                                             .actionDetail !=
                                                                             "" &&
                                                                          this
                                                                             .state
                                                                             .actionDetail !=
                                                                             undefined
                                                                        : true) &&
                                                                     this.state
                                                                        .actionValue !=
                                                                        "" &&
                                                                     this.state
                                                                        .actionValue !=
                                                                        undefined ? (
                                                                        <button
                                                                           // data-bs-dismiss="modal"
                                                                           type="button"
                                                                           style={{
                                                                              background:
                                                                                 "linear-gradient(#3E94A6, #323F4B)",
                                                                           }}
                                                                           className="btn btn-primary"
                                                                           // onClick={(e) => this.SaveUser(e)}
                                                                           onClick={() =>
                                                                              this.submitFunctionFirstDeal(
                                                                                 this
                                                                                    .state
                                                                                    .idFirstDeal,
                                                                                 this
                                                                                    .state
                                                                                    .actionDetail
                                                                              )
                                                                           }
                                                                           data-bs-dismiss="modal"
                                                                        >
                                                                           Submit
                                                                        </button>
                                                                     ) : (
                                                                        <button
                                                                           // data-bs-dismiss="modal"
                                                                           type="button"
                                                                           style={{
                                                                              background:
                                                                                 "linear-gradient(#3E94A6, #323F4B)",
                                                                           }}
                                                                           className="btn btn-primary rounded-0"
                                                                           onClick={() =>
                                                                              this.submitFunctionFirstDeal(
                                                                                 this
                                                                                    .state
                                                                                    .idFirstDeal,
                                                                                 this
                                                                                    .state
                                                                                    .actionDetail
                                                                              )
                                                                           }

                                                                           // onClick={this.SavePlates}
                                                                        >
                                                                           Submit
                                                                        </button>
                                                                     )}
                                                                  </>
                                                               )}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>

                                                   {/* ////////////End Edit model////////////// */}
                                                   {/* ////////////table model////////////// */}
                                                   {this.state.isEditDeal ===
                                                   null ? (
                                                      <div
                                                         className="modal fade "
                                                         id="BackdropTwo"
                                                         tabIndex={-1}
                                                         data-bs-backdrop="static"
                                                         data-bs-keyboard="false"
                                                         aria-labelledby="BackdropTwo"
                                                         aria-hidden="true"
                                                      >
                                                         <div className="modal-dialog ">
                                                            <div className="modal-content rounded-0 ">
                                                               <div className="modal-body ">
                                                                  <div className="text-start text-black">
                                                                     <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        style={{
                                                                           position:
                                                                              "absolute",
                                                                           right: "20px",
                                                                        }}
                                                                        // onClick={this.AllclearFields}
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={
                                                                           this
                                                                              .AllClearTableFields
                                                                        }
                                                                     />
                                                                     <h3
                                                                        className="modal-title text-uppercase fw-bold"
                                                                        id="staticBackdropLabel"
                                                                     ></h3>
                                                                     <div>
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="me-2 fw-bolder  form-label mb-3"
                                                                        >
                                                                           <h5>
                                                                              Size:
                                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                                              {
                                                                                 slot?.size
                                                                              }
                                                                           </h5>
                                                                        </label>
                                                                     </div>
                                                                     <div>
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="mb-0 fw-bolder  form-label "
                                                                        >
                                                                           <h6 className="required">
                                                                              Slot
                                                                              Name
                                                                           </h6>
                                                                        </label>
                                                                        <input
                                                                           type="text"
                                                                           value={
                                                                              this
                                                                                 .state
                                                                                 .slotNameValues
                                                                           }
                                                                           onChange={(
                                                                              e
                                                                           ) =>
                                                                              this.setState(
                                                                                 {
                                                                                    slotNameValues:
                                                                                       e
                                                                                          .target
                                                                                          .value,
                                                                                    slotNameVal: false,
                                                                                 }
                                                                              )
                                                                           }
                                                                           class="form-control w-100"
                                                                        />
                                                                        {this
                                                                           .state
                                                                           .slotNameVal ? (
                                                                           <div className="modelValidation">
                                                                              Enter
                                                                              slot
                                                                              name
                                                                           </div>
                                                                        ) : null}
                                                                     </div>
                                                                     <div className="mt-2">
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="mb-0 fw-bolder  form-label "
                                                                        >
                                                                           <h6 className="required">
                                                                              Slot
                                                                              Description
                                                                           </h6>
                                                                        </label>
                                                                        <textarea
                                                                           type="text"
                                                                           value={
                                                                              this
                                                                                 .state
                                                                                 .slotDescriptionValues
                                                                           }
                                                                           onChange={(
                                                                              e
                                                                           ) =>
                                                                              this.setState(
                                                                                 {
                                                                                    slotDescriptionValues:
                                                                                       e
                                                                                          .target
                                                                                          .value,
                                                                                    slotDescriptionVal: false,
                                                                                 }
                                                                              )
                                                                           }
                                                                           class="form-control w-100"
                                                                        />
                                                                        {this
                                                                           .state
                                                                           .slotDescriptionVal ? (
                                                                           <div className="modelValidation">
                                                                              Enter
                                                                              slot
                                                                              description
                                                                           </div>
                                                                        ) : null}
                                                                     </div>
                                                                     <br />
                                                                     <div className="table_data">
                                                                        <table class="table  shadow border rounded-0 text-center">
                                                                           <thead className="bg-light">
                                                                              <tr>
                                                                                 <th scope="col">
                                                                                    Price
                                                                                 </th>
                                                                                 <th scope="col">
                                                                                    Duration
                                                                                 </th>
                                                                                 <th scope="col">
                                                                                    Status
                                                                                 </th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody className="text-center">
                                                                              <tr>
                                                                                 <td className="w-50">
                                                                                    $
                                                                                    <input
                                                                                       type="number"
                                                                                       value={
                                                                                          this
                                                                                             .state
                                                                                             .tablePricePriceDay
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) => {
                                                                                          this.setState(
                                                                                             {
                                                                                                tablePricePriceDay:
                                                                                                   e
                                                                                                      .target
                                                                                                      .value,
                                                                                             }
                                                                                          );

                                                                                          if (
                                                                                             e
                                                                                                .target
                                                                                                .value ===
                                                                                             ""
                                                                                          ) {
                                                                                             this.setState(
                                                                                                {
                                                                                                   daySwitch:
                                                                                                      "inactive",
                                                                                                }
                                                                                             );
                                                                                          } else {
                                                                                             this.setState(
                                                                                                {
                                                                                                   daySwitch:
                                                                                                      "active",
                                                                                                }
                                                                                             );
                                                                                          }
                                                                                       }}
                                                                                       className="w-50"
                                                                                    />
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Day
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <div class="form-check form-switch">
                                                                                       {this
                                                                                          .state
                                                                                          .tablePricePriceDay ===
                                                                                       "" ? (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             type="checkbox"
                                                                                             checked={
                                                                                                false
                                                                                             }
                                                                                             id="flexSwitchCheckChecked"
                                                                                             title="Enter price "

                                                                                             // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                          />
                                                                                       ) : (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             type="checkbox"
                                                                                             id="flexSwitchCheckChecked"
                                                                                             checked={
                                                                                                this
                                                                                                   .state
                                                                                                   .daySwitch ==
                                                                                                "active"
                                                                                                   ? true
                                                                                                   : false
                                                                                             }
                                                                                             onChange={(
                                                                                                e
                                                                                             ) =>
                                                                                                this.AllDaySwitch(
                                                                                                   e
                                                                                                      .target
                                                                                                      .checked,
                                                                                                   "daySwitch"
                                                                                                )
                                                                                             }

                                                                                             // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                          />
                                                                                       )}
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="w-50">
                                                                                    $
                                                                                    <input
                                                                                       type="number"
                                                                                       value={
                                                                                          this
                                                                                             .state
                                                                                             .tablePricePriceWeek
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) => {
                                                                                          this.setState(
                                                                                             {
                                                                                                tablePricePriceWeek:
                                                                                                   e
                                                                                                      .target
                                                                                                      .value,
                                                                                             }
                                                                                          );
                                                                                          if (
                                                                                             e
                                                                                                .target
                                                                                                .value ===
                                                                                             ""
                                                                                          ) {
                                                                                             this.setState(
                                                                                                {
                                                                                                   weekSwitch:
                                                                                                      "inactive",
                                                                                                }
                                                                                             );
                                                                                          } else {
                                                                                             this.setState(
                                                                                                {
                                                                                                   weekSwitch:
                                                                                                      "active",
                                                                                                }
                                                                                             );
                                                                                          }
                                                                                       }}
                                                                                       className="w-50"
                                                                                    />
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Week
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <div class="form-check form-switch">
                                                                                       {this
                                                                                          .state
                                                                                          .tablePricePriceWeek ===
                                                                                       "" ? (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             type="checkbox"
                                                                                             checked={
                                                                                                false
                                                                                             }
                                                                                             id="flexSwitchCheckChecked"
                                                                                             title="Enter price "

                                                                                             // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                          />
                                                                                       ) : (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             checked={
                                                                                                this
                                                                                                   .state
                                                                                                   .weekSwitch ==
                                                                                                "active"
                                                                                                   ? true
                                                                                                   : false
                                                                                             }
                                                                                             onChange={(
                                                                                                e
                                                                                             ) =>
                                                                                                this.AllDaySwitch(
                                                                                                   e
                                                                                                      .target
                                                                                                      .checked,
                                                                                                   "weekSwitch"
                                                                                                )
                                                                                             }
                                                                                             type="checkbox"
                                                                                             id="flexSwitchCheckChecked"
                                                                                          />
                                                                                       )}
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="w-50">
                                                                                    $
                                                                                    <input
                                                                                       type="number"
                                                                                       value={
                                                                                          this
                                                                                             .state
                                                                                             .tablePricePriceMonth
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.setState(
                                                                                             {
                                                                                                tablePricePriceMonth:
                                                                                                   e
                                                                                                      .target
                                                                                                      .value,
                                                                                             }
                                                                                          )
                                                                                       }
                                                                                       className="w-50"
                                                                                    />
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Month
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <div class="form-check form-switch">
                                                                                       {this
                                                                                          .state
                                                                                          .tablePricePriceMonth ===
                                                                                       "" ? (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             type="checkbox"
                                                                                             checked={
                                                                                                false
                                                                                             }
                                                                                             id="flexSwitchCheckChecked"
                                                                                             title="Enter price "

                                                                                             // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                          />
                                                                                       ) : (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             checked={
                                                                                                this
                                                                                                   .state
                                                                                                   .monthSwitch ==
                                                                                                "active"
                                                                                                   ? true
                                                                                                   : false
                                                                                             }
                                                                                             onChange={(
                                                                                                e
                                                                                             ) =>
                                                                                                this.AllDaySwitch(
                                                                                                   e
                                                                                                      .target
                                                                                                      .checked,
                                                                                                   "monthSwitch"
                                                                                                )
                                                                                             }
                                                                                             type="checkbox"
                                                                                             id="flexSwitchCheckChecked"
                                                                                          />
                                                                                       )}
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="w-50">
                                                                                    $
                                                                                    <input
                                                                                       type="number"
                                                                                       value={
                                                                                          this
                                                                                             .state
                                                                                             .tablePricePriceYear
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.setState(
                                                                                             {
                                                                                                tablePricePriceYear:
                                                                                                   e
                                                                                                      .target
                                                                                                      .value,
                                                                                             }
                                                                                          )
                                                                                       }
                                                                                       className="w-50"
                                                                                    />
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Year
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <div class="form-check form-switch">
                                                                                       {this
                                                                                          .state
                                                                                          .tablePricePriceYear ===
                                                                                       "" ? (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             type="checkbox"
                                                                                             checked={
                                                                                                false
                                                                                             }
                                                                                             id="flexSwitchCheckChecked"
                                                                                             title="Enter price "

                                                                                             // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                          />
                                                                                       ) : (
                                                                                          <input
                                                                                             class="form-check-input"
                                                                                             checked={
                                                                                                this
                                                                                                   .state
                                                                                                   .yearSwitch ==
                                                                                                "active"
                                                                                                   ? true
                                                                                                   : false
                                                                                             }
                                                                                             onChange={(
                                                                                                e
                                                                                             ) =>
                                                                                                this.AllDaySwitch(
                                                                                                   e
                                                                                                      .target
                                                                                                      .checked,
                                                                                                   "yearSwitch"
                                                                                                )
                                                                                             }
                                                                                             type="checkbox"
                                                                                             id="flexSwitchCheckChecked"
                                                                                          />
                                                                                       )}
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div className="modal-footer ">
                                                                  <button
                                                                     type="button"
                                                                     onClick={
                                                                        this
                                                                           .AllClearTableFields
                                                                     }
                                                                     className="btn btn-secondary rounded-0"
                                                                     data-bs-dismiss="modal"
                                                                  >
                                                                     Close
                                                                  </button>
                                                                  {/* // this.state.plateNumber.length > 4 && */}
                                                                  {this.state
                                                                     .slotNameValues !=
                                                                     "" &&
                                                                  this.state
                                                                     .slotNameValues !=
                                                                     undefined &&
                                                                  this.state
                                                                     .slotDescriptionValues !=
                                                                     "" &&
                                                                  this.state
                                                                     .slotDescriptionValues !=
                                                                     undefined ? (
                                                                     <button
                                                                        // data-bs-dismiss="modal"
                                                                        type="button"
                                                                        style={{
                                                                           background:
                                                                              "linear-gradient(#3E94A6, #323F4B)",
                                                                        }}
                                                                        className="btn btn-primary rounded-0"
                                                                        onClick={(
                                                                           e
                                                                        ) =>
                                                                           this.AddTableGroup(
                                                                              e
                                                                           )
                                                                        }
                                                                        // onClick={() => this.submitFunctionFirstDeal(this.state.idFirstDeal, this.state.actionDetail)}
                                                                        data-bs-dismiss="modal"
                                                                     >
                                                                        Submit
                                                                     </button>
                                                                  ) : (
                                                                     <button
                                                                        // data-bs-dismiss="modal"
                                                                        type="button"
                                                                        style={{
                                                                           background:
                                                                              "linear-gradient(#3E94A6, #323F4B)",
                                                                        }}
                                                                        className="btn btn-primary rounded-0"
                                                                        // onClick={() => this.submitFunctionFirstDeal(this.state.idFirstDeal)}
                                                                        onClick={(
                                                                           e
                                                                        ) =>
                                                                           this.AddTableGroup(
                                                                              e
                                                                           )
                                                                        }
                                                                     >
                                                                        Submit
                                                                     </button>
                                                                  )}
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   ) : (
                                                      <div
                                                         className="modal fade "
                                                         id="BackdropTwo"
                                                         tabIndex={-1}
                                                         data-bs-backdrop="static"
                                                         data-bs-keyboard="false"
                                                         aria-labelledby="BackdropTwo"
                                                         aria-hidden="true"
                                                      >
                                                         <div className="modal-dialog ">
                                                            <div className="modal-content rounded-0 ">
                                                               <div className="modal-body ">
                                                                  <div className="text-start text-black">
                                                                     <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        style={{
                                                                           position:
                                                                              "absolute",
                                                                           right: "20px",
                                                                        }}
                                                                        // onClick={this.AllclearFields}
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={
                                                                           this
                                                                              .AllClearTableFields
                                                                        }
                                                                     />
                                                                     <h3
                                                                        className="modal-title text-uppercase fw-bold"
                                                                        id="staticBackdropLabel"
                                                                     ></h3>
                                                                     <div>
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="me-2 fw-bolder  form-label mb-3"
                                                                        >
                                                                           <h5>
                                                                              Size:
                                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                                              {
                                                                                 slot?.size
                                                                              }
                                                                           </h5>
                                                                        </label>
                                                                     </div>
                                                                     <div>
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="mb-0 fw-bolder  form-label "
                                                                        >
                                                                           <h6 className="">
                                                                              Slot
                                                                              Name
                                                                           </h6>
                                                                        </label>
                                                                        <p>
                                                                           {
                                                                              this
                                                                                 .state
                                                                                 .slotNameValues
                                                                           }
                                                                        </p>

                                                                        {this
                                                                           .state
                                                                           .slotNameVal ? (
                                                                           <div className="modelValidation">
                                                                              Enter
                                                                              slot
                                                                              name
                                                                           </div>
                                                                        ) : null}
                                                                     </div>
                                                                     <div>
                                                                        <label
                                                                           htmlFor="exampleFormControlInput1"
                                                                           className="mb-0 fw-bolder  form-label "
                                                                        >
                                                                           <h6 className="">
                                                                              Slot
                                                                              Description
                                                                           </h6>
                                                                        </label>
                                                                        <p>
                                                                           {
                                                                              this
                                                                                 .state
                                                                                 .slotDescriptionValues
                                                                           }
                                                                        </p>

                                                                        {this
                                                                           .state
                                                                           .slotDescriptionVal ? (
                                                                           <div className="modelValidation">
                                                                              Enter
                                                                              slot
                                                                              description
                                                                           </div>
                                                                        ) : null}
                                                                     </div>
                                                                     <br />
                                                                     <div className="table_data">
                                                                        <table class="table  shadow border rounded-0 text-center">
                                                                           <thead className="bg-light">
                                                                              <tr>
                                                                                 <th scope="col">
                                                                                    Price
                                                                                 </th>
                                                                                 <th scope="col">
                                                                                    Duration
                                                                                 </th>
                                                                                 <th scope="col">
                                                                                    Status
                                                                                 </th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody className="text-center">
                                                                              <tr>
                                                                                 <td className="">
                                                                                    <p>{`$${
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceDay ??
                                                                                       "0"
                                                                                    }`}</p>
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Day
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <p>
                                                                                       {
                                                                                          this
                                                                                             .state
                                                                                             .daySwitch
                                                                                       }
                                                                                    </p>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="">
                                                                                    <p>{`$${
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceWeek ??
                                                                                       "0"
                                                                                    }`}</p>
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Week
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <p>
                                                                                       {
                                                                                          this
                                                                                             .state
                                                                                             .weekSwitch
                                                                                       }
                                                                                    </p>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="">
                                                                                    <p>{`$${
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceMonth ??
                                                                                       "0"
                                                                                    }`}</p>
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Month
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <p>
                                                                                       {
                                                                                          this
                                                                                             .state
                                                                                             .monthSwitch
                                                                                       }
                                                                                    </p>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td className="">
                                                                                    <p>{`$${
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceYear ??
                                                                                       "0"
                                                                                    }`}</p>
                                                                                 </td>
                                                                                 <td className="text-nowrap">
                                                                                    Per
                                                                                    Year
                                                                                 </td>
                                                                                 <td className="d-flex justify-content-center">
                                                                                    <p>
                                                                                       {
                                                                                          this
                                                                                             .state
                                                                                             .yearSwitch
                                                                                       }
                                                                                    </p>
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div className="modal-footer ">
                                                                  <button
                                                                     type="button"
                                                                     // onClick={this.AllClearTableFields}
                                                                     className="btn btn-secondary rounded-0"
                                                                     data-bs-dismiss="modal"
                                                                  >
                                                                     Close
                                                                  </button>
                                                                  {/* // this.state.plateNumber.length > 4 && */}
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   )}
                                                   {/* ////////////End Table model////////////// */}
                                                   <div
                                                      className="modal fade "
                                                      id="BackdropTwo"
                                                      tabIndex={-1}
                                                      data-bs-backdrop="static"
                                                      data-bs-keyboard="false"
                                                      aria-labelledby="BackdropTwo"
                                                      aria-hidden="true"
                                                   >
                                                      <div className="modal-dialog ">
                                                         <div className="modal-content rounded-0 ">
                                                            <div className="modal-body ">
                                                               <div className="text-start text-black">
                                                                  <button
                                                                     type="button"
                                                                     className="btn-close"
                                                                     style={{
                                                                        position:
                                                                           "absolute",
                                                                        right: "20px",
                                                                     }}
                                                                     // onClick={this.AllclearFields}
                                                                     data-bs-dismiss="modal"
                                                                     aria-label="Close"
                                                                     onClick={
                                                                        this
                                                                           .AllClearTableFields
                                                                     }
                                                                  />
                                                                  <h3
                                                                     className="modal-title text-uppercase fw-bold"
                                                                     id="staticBackdropLabel"
                                                                  ></h3>
                                                                  <div>
                                                                     <label
                                                                        htmlFor="exampleFormControlInput1"
                                                                        className="me-2 fw-bolder  form-label mb-3"
                                                                     >
                                                                        <h5>
                                                                           Size:
                                                                           &nbsp;&nbsp;&nbsp;&nbsp;
                                                                           {
                                                                              slot?.size
                                                                           }
                                                                        </h5>
                                                                     </label>
                                                                  </div>
                                                                  <div>
                                                                     <label
                                                                        htmlFor="exampleFormControlInput1"
                                                                        className="mb-0 fw-bolder  form-label "
                                                                     >
                                                                        <h6 className="required">
                                                                           Slot
                                                                           Name
                                                                        </h6>
                                                                     </label>
                                                                     <input
                                                                        type="text"
                                                                        value={
                                                                           this
                                                                              .state
                                                                              .slotNameValues
                                                                        }
                                                                        onChange={(
                                                                           e
                                                                        ) =>
                                                                           this.setState(
                                                                              {
                                                                                 slotNameValues:
                                                                                    e
                                                                                       .target
                                                                                       .value,
                                                                                 slotNameVal: false,
                                                                              }
                                                                           )
                                                                        }
                                                                        class="form-control w-100"
                                                                     />
                                                                     {this.state
                                                                        .slotNameVal ? (
                                                                        <div className="modelValidation">
                                                                           Enter
                                                                           slot
                                                                           name
                                                                        </div>
                                                                     ) : null}
                                                                  </div>
                                                                  <div>
                                                                     <label
                                                                        htmlFor="exampleFormControlInput1"
                                                                        className="mb-0 fw-bolder  form-label "
                                                                     >
                                                                        <h6 className="required">
                                                                           Slot
                                                                           Description
                                                                        </h6>
                                                                     </label>
                                                                     <textarea
                                                                        type="text"
                                                                        value={
                                                                           this
                                                                              .state
                                                                              .slotDescriptionValues
                                                                        }
                                                                        onChange={(
                                                                           e
                                                                        ) =>
                                                                           this.setState(
                                                                              {
                                                                                 slotDescriptionValues:
                                                                                    e
                                                                                       .target
                                                                                       .value,
                                                                                 slotDescriptionVal: false,
                                                                              }
                                                                           )
                                                                        }
                                                                        class="form-control w-100"
                                                                     />
                                                                     {this.state
                                                                        .slotDescriptionVal ? (
                                                                        <div className="modelValidation">
                                                                           Enter
                                                                           slot
                                                                           description
                                                                        </div>
                                                                     ) : null}
                                                                  </div>
                                                                  <br />
                                                                  <div className="table_data">
                                                                     <table class="table  shadow border rounded-0 text-center">
                                                                        <thead className="bg-light">
                                                                           <tr>
                                                                              <th scope="col">
                                                                                 Price
                                                                              </th>
                                                                              <th scope="col">
                                                                                 Duration
                                                                              </th>
                                                                              <th scope="col">
                                                                                 Status
                                                                              </th>
                                                                           </tr>
                                                                        </thead>
                                                                        <tbody className="text-center">
                                                                           <tr>
                                                                              <td className="w-50">
                                                                                 $
                                                                                 <input
                                                                                    type="number"
                                                                                    value={
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceDay
                                                                                    }
                                                                                    onChange={(
                                                                                       e
                                                                                    ) =>
                                                                                       this.setState(
                                                                                          {
                                                                                             tablePricePriceDay:
                                                                                                e
                                                                                                   .target
                                                                                                   .value,
                                                                                          }
                                                                                       )
                                                                                    }
                                                                                    className="w-50"
                                                                                 />
                                                                              </td>
                                                                              <td className="text-nowrap">
                                                                                 Per
                                                                                 Day
                                                                              </td>
                                                                              <td className="d-flex justify-content-center">
                                                                                 <div class="form-check form-switch">
                                                                                    <input
                                                                                       class="form-check-input"
                                                                                       type="checkbox"
                                                                                       id="flexSwitchCheckChecked"
                                                                                       checked={
                                                                                          this
                                                                                             .state
                                                                                             .daySwitch ==
                                                                                          "active"
                                                                                             ? true
                                                                                             : false
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.AllDaySwitch(
                                                                                             e
                                                                                                .target
                                                                                                .checked,
                                                                                             "daySwitch"
                                                                                          )
                                                                                       }

                                                                                       // onChange={(e) => this.setState({ daySwitch: e.target.value })}
                                                                                    />
                                                                                 </div>
                                                                              </td>
                                                                           </tr>
                                                                           <tr>
                                                                              <td className="w-50">
                                                                                 $
                                                                                 <input
                                                                                    type="number"
                                                                                    value={
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceWeek
                                                                                    }
                                                                                    onChange={(
                                                                                       e
                                                                                    ) =>
                                                                                       this.setState(
                                                                                          {
                                                                                             tablePricePriceWeek:
                                                                                                e
                                                                                                   .target
                                                                                                   .value,
                                                                                          }
                                                                                       )
                                                                                    }
                                                                                    className="w-50"
                                                                                 />
                                                                              </td>
                                                                              <td className="text-nowrap">
                                                                                 Per
                                                                                 Week
                                                                              </td>
                                                                              <td className="d-flex justify-content-center">
                                                                                 <div class="form-check form-switch">
                                                                                    <input
                                                                                       class="form-check-input"
                                                                                       checked={
                                                                                          this
                                                                                             .state
                                                                                             .weekSwitch ==
                                                                                          "active"
                                                                                             ? true
                                                                                             : false
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.AllDaySwitch(
                                                                                             e
                                                                                                .target
                                                                                                .checked,
                                                                                             "weekSwitch"
                                                                                          )
                                                                                       }
                                                                                       type="checkbox"
                                                                                       id="flexSwitchCheckChecked"
                                                                                    />
                                                                                 </div>
                                                                              </td>
                                                                           </tr>
                                                                           <tr>
                                                                              <td className="w-50">
                                                                                 $
                                                                                 <input
                                                                                    type="number"
                                                                                    value={
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceMonth
                                                                                    }
                                                                                    onChange={(
                                                                                       e
                                                                                    ) =>
                                                                                       this.setState(
                                                                                          {
                                                                                             tablePricePriceMonth:
                                                                                                e
                                                                                                   .target
                                                                                                   .value,
                                                                                          }
                                                                                       )
                                                                                    }
                                                                                    className="w-50"
                                                                                 />
                                                                              </td>
                                                                              <td className="text-nowrap">
                                                                                 Per
                                                                                 Month
                                                                              </td>
                                                                              <td className="d-flex justify-content-center">
                                                                                 <div class="form-check form-switch">
                                                                                    <input
                                                                                       class="form-check-input"
                                                                                       checked={
                                                                                          this
                                                                                             .state
                                                                                             .monthSwitch ==
                                                                                          "active"
                                                                                             ? true
                                                                                             : false
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.AllDaySwitch(
                                                                                             e
                                                                                                .target
                                                                                                .checked,
                                                                                             "monthSwitch"
                                                                                          )
                                                                                       }
                                                                                       type="checkbox"
                                                                                       id="flexSwitchCheckChecked"
                                                                                    />
                                                                                 </div>
                                                                              </td>
                                                                           </tr>
                                                                           <tr>
                                                                              <td className="w-50">
                                                                                 $
                                                                                 <input
                                                                                    type="number"
                                                                                    value={
                                                                                       this
                                                                                          .state
                                                                                          .tablePricePriceYear
                                                                                    }
                                                                                    onChange={(
                                                                                       e
                                                                                    ) =>
                                                                                       this.setState(
                                                                                          {
                                                                                             tablePricePriceYear:
                                                                                                e
                                                                                                   .target
                                                                                                   .value,
                                                                                          }
                                                                                       )
                                                                                    }
                                                                                    className="w-50"
                                                                                 />
                                                                              </td>
                                                                              <td className="text-nowrap">
                                                                                 Per
                                                                                 Year
                                                                              </td>
                                                                              <td className="d-flex justify-content-center">
                                                                                 <div class="form-check form-switch">
                                                                                    <input
                                                                                       class="form-check-input"
                                                                                       checked={
                                                                                          this
                                                                                             .state
                                                                                             .yearSwitch ==
                                                                                          "active"
                                                                                             ? true
                                                                                             : false
                                                                                       }
                                                                                       onChange={(
                                                                                          e
                                                                                       ) =>
                                                                                          this.AllDaySwitch(
                                                                                             e
                                                                                                .target
                                                                                                .checked,
                                                                                             "yearSwitch"
                                                                                          )
                                                                                       }
                                                                                       type="checkbox"
                                                                                       id="flexSwitchCheckChecked"
                                                                                    />
                                                                                 </div>
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                  </div>
                                                               </div>
                                                            </div>

                                                            <div className="modal-footer ">
                                                               <button
                                                                  type="button"
                                                                  onClick={
                                                                     this
                                                                        .AllClearTableFields
                                                                  }
                                                                  className="btn btn-secondary rounded-0"
                                                                  data-bs-dismiss="modal"
                                                               >
                                                                  Close
                                                               </button>
                                                               {/* // this.state.plateNumber.length > 4 && */}
                                                               {this.state
                                                                  .slotNameValues !=
                                                                  "" &&
                                                               this.state
                                                                  .slotNameValues !=
                                                                  undefined &&
                                                               this.state
                                                                  .slotDescriptionValues !=
                                                                  "" &&
                                                               this.state
                                                                  .slotDescriptionValues !=
                                                                  undefined ? (
                                                                  <button
                                                                     // data-bs-dismiss="modal"
                                                                     type="button"
                                                                     style={{
                                                                        background:
                                                                           "linear-gradient(#3E94A6, #323F4B)",
                                                                     }}
                                                                     className="btn btn-primary rounded-0"
                                                                     onClick={(
                                                                        e
                                                                     ) =>
                                                                        this.AddTableGroup(
                                                                           e
                                                                        )
                                                                     }
                                                                     // onClick={() => this.submitFunctionFirstDeal(this.state.idFirstDeal, this.state.actionDetail)}
                                                                     data-bs-dismiss="modal"
                                                                  >
                                                                     Submit
                                                                  </button>
                                                               ) : (
                                                                  <button
                                                                     // data-bs-dismiss="modal"
                                                                     type="button"
                                                                     style={{
                                                                        background:
                                                                           "linear-gradient(#3E94A6, #323F4B)",
                                                                     }}
                                                                     className="btn btn-primary rounded-0"
                                                                     // onClick={() => this.submitFunctionFirstDeal(this.state.idFirstDeal)}
                                                                     onClick={(
                                                                        e
                                                                     ) =>
                                                                        this.AddTableGroup(
                                                                           e
                                                                        )
                                                                     }
                                                                  >
                                                                     Submit
                                                                  </button>
                                                               )}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {/* ////////////End Table model////////////// */}
                                                </>
                                             ) : index == 1 ? (
                                                <div className="card shadow-none bg-light p-2">
                                                   <div className="Topicons d-flex justify-content-between">
                                                      <div className="badges">
                                                         <span className="badge bg-success">
                                                            {slot.deal?.status}
                                                         </span>
                                                         {slot.deal
                                                            ?.published ==
                                                         "under_review" ? (
                                                            <span className="badge bg-danger mx-1">
                                                               under review
                                                            </span>
                                                         ) : (
                                                            <span className="badge bg-danger mx-1">
                                                               {
                                                                  slot.deal
                                                                     ?.published
                                                               }
                                                            </span>
                                                         )}
                                                         <Link
                                                            to="#"
                                                            className="me-0 mx-1 text-primary"
                                                         >
                                                            <i
                                                               className="mdi mdi-pencil font-size-18 me-2"
                                                               title="Take Action"
                                                               onClick={() =>
                                                                  this.initialValueFirstDeal(
                                                                     slot.deal
                                                                        ?.id,

                                                                     slot.deal
                                                                        ?.description,
                                                                     slot.deal
                                                                        ?.title,
                                                                     slot?.size,
                                                                     slot.deal
                                                                        ?.deal_price
                                                                        ?.price,
                                                                     slot.deal
                                                                        ?.link,
                                                                     `${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name}`,
                                                                     slot.deal
                                                                        ?.status,
                                                                     slot.deal
                                                                        ?.slot_price
                                                                  )
                                                               }
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#Backdrop"
                                                            ></i>
                                                         </Link>
                                                      </div>
                                                      <div className="icons">
                                                         <Link
                                                            to="#"
                                                            className="me-0 mb-2 text-primary"
                                                         >
                                                            <i
                                                               title="Slot Detail"
                                                               className="mdi mdi-table font-size-18 "
                                                               onClick={() =>
                                                                  this.initialTableValueDeal(
                                                                     slot?.slot_pricing,
                                                                     slot?.name,
                                                                     slot?.description,
                                                                     slot?.id,
                                                                     slot?.deal
                                                                  )
                                                               }
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#BackdropTwo"
                                                            ></i>
                                                         </Link>
                                                      </div>
                                                   </div>
                                                   <hr className="mt-0" />
                                                   {slot.deal === null ? (
                                                      <div
                                                         className="SlotTwo SlotBackground   text-white rounded d-flex justify-content-center align-items-center"
                                                         style={{
                                                            width: "320px",
                                                            height: "260px",
                                                         }}
                                                      >
                                                         <div className="Text text-center SlotTextColor">
                                                            <h5 className="mb-0">
                                                               Slot Ready For
                                                               Booking
                                                            </h5>
                                                            <p className="mb-0">
                                                               Recommended Size
                                                            </p>
                                                            <p className="mb-0">
                                                               320 x 260
                                                            </p>
                                                         </div>
                                                      </div>
                                                   ) : (
                                                      <div
                                                         className="card-body"
                                                         style={{
                                                            width: "-webkit-fill-available",
                                                            height: "260px",
                                                            margin: "auto",
                                                            borderRadius: "5px",
                                                            backgroundColor:
                                                               slot.deal
                                                                  ?.deal_image[0]
                                                                  ? ""
                                                                  : "#848784",
                                                            backgroundImage: `url(${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name})`,
                                                         }}
                                                      ></div>
                                                   )}
                                                </div>
                                             ) : index == 2 ? (
                                                <div className="card shadow-none bg-light p-2">
                                                   <div className="Topicons d-flex justify-content-between">
                                                      <div className="badges">
                                                         <span className="badge bg-success">
                                                            {slot.deal?.status}
                                                         </span>
                                                         {slot.deal
                                                            ?.published ==
                                                         "under_review" ? (
                                                            <span className="badge bg-danger mx-1">
                                                               under review
                                                            </span>
                                                         ) : (
                                                            <span className="badge bg-danger mx-1">
                                                               {
                                                                  slot.deal
                                                                     ?.published
                                                               }
                                                            </span>
                                                         )}
                                                         <Link
                                                            to="#"
                                                            className="me-0 mx-2 text-primary"
                                                         >
                                                            <i
                                                               className="mdi mdi-pencil font-size-18 me-2"
                                                               title="Take Action"
                                                               onClick={() =>
                                                                  this.initialValueFirstDeal(
                                                                     slot.deal
                                                                        ?.id,

                                                                     slot.deal
                                                                        ?.description,
                                                                     slot.deal
                                                                        ?.title,
                                                                     slot?.size,
                                                                     slot.deal
                                                                        ?.deal_price
                                                                        ?.price,
                                                                     slot.deal
                                                                        ?.link,
                                                                     `${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name}`,
                                                                     slot.deal
                                                                        ?.status,
                                                                     slot.deal
                                                                        ?.slot_price
                                                                  )
                                                               }
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#Backdrop"
                                                            ></i>
                                                         </Link>
                                                      </div>
                                                      <div className="icons">
                                                         <Link
                                                            to="#"
                                                            className="me-0 mb-2 text-primary"
                                                         >
                                                            <i
                                                               className="mdi mdi-table font-size-18 "
                                                               title="Slot Detail"
                                                               onClick={() =>
                                                                  this.initialTableValueDeal(
                                                                     slot?.slot_pricing,
                                                                     slot?.name,
                                                                     slot?.description,
                                                                     slot?.id,
                                                                     slot?.deal
                                                                  )
                                                               }
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#BackdropTwo"
                                                            ></i>
                                                         </Link>
                                                      </div>
                                                   </div>
                                                   <hr className="mt-0" />
                                                   {slot.deal === null ? (
                                                      <div
                                                         className="SlotThree SlotBackground  text-white rounded d-flex justify-content-center align-items-center"
                                                         style={{
                                                            width: "145px",
                                                            height: "120px",
                                                            margin: "auto",
                                                         }}
                                                      >
                                                         <div className="Text text-center SlotTextColor">
                                                            <h5 className="mb-0 fs-6">
                                                               Slot Ready For
                                                               Booking
                                                            </h5>
                                                            <p className="mb-0">
                                                               Recommended Size
                                                            </p>
                                                            <p className="mb-0">
                                                               145 x 120
                                                            </p>
                                                         </div>
                                                      </div>
                                                   ) : (
                                                      <div
                                                         className="card-body"
                                                         style={{
                                                            width: "145px",
                                                            height: "120px",
                                                            margin: "auto",
                                                            borderRadius: "5px",
                                                            backgroundColor:
                                                               slot.deal
                                                                  ?.deal_image[0]
                                                                  ? ""
                                                                  : "#848784",
                                                            backgroundImage: `url(${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name})`,
                                                         }}
                                                      ></div>
                                                   )}
                                                </div>
                                             ) : index === 3 ? (
                                                <div className="card shadow-none bg-light p-2">
                                                   <div className="Topicons d-flex justify-content-between">
                                                      {slot.deal?.status ==
                                                         "" ||
                                                      slot.deal?.published ==
                                                         "" ? (
                                                         <div className="badges">
                                                            <span className="badge bg-success">
                                                               approve
                                                            </span>
                                                            <span className="badge bg-danger mx-1">
                                                               approve
                                                            </span>
                                                         </div>
                                                      ) : (
                                                         <>
                                                            <div className="badges">
                                                               <span className="badge bg-success">
                                                                  {
                                                                     slot.deal
                                                                        ?.status
                                                                  }
                                                               </span>
                                                               {slot.deal
                                                                  ?.published ==
                                                               "under_review" ? (
                                                                  <span className="badge bg-danger mx-1">
                                                                     under
                                                                     review
                                                                  </span>
                                                               ) : (
                                                                  <span className="badge bg-danger mx-1">
                                                                     {
                                                                        slot
                                                                           .deal
                                                                           ?.published
                                                                     }
                                                                  </span>
                                                               )}
                                                               <Link
                                                                  to="#"
                                                                  className="me-0  mx-1 text-primary"
                                                               >
                                                                  <i
                                                                     className="mdi mdi-pencil font-size-18 me-2"
                                                                     title="Take Action"
                                                                     onClick={() =>
                                                                        this.initialValueFirstDeal(
                                                                           slot
                                                                              .deal
                                                                              ?.id,

                                                                           slot
                                                                              .deal
                                                                              ?.description,
                                                                           slot
                                                                              .deal
                                                                              ?.title,
                                                                           slot?.size,
                                                                           slot
                                                                              .deal
                                                                              ?.deal_price
                                                                              ?.price,
                                                                           slot
                                                                              .deal
                                                                              ?.link,
                                                                           `${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name}`,
                                                                           slot
                                                                              .deal
                                                                              ?.status,
                                                                           slot
                                                                              .deal
                                                                              ?.slot_price
                                                                        )
                                                                     }
                                                                     data-bs-toggle="modal"
                                                                     data-bs-target="#Backdrop"
                                                                  ></i>
                                                               </Link>
                                                            </div>
                                                         </>
                                                      )}

                                                      <div className="icons">
                                                         <Link
                                                            to="#"
                                                            className="me-0 mb-2 text-primary"
                                                         >
                                                            <i
                                                               className="mdi mdi-table font-size-18 "
                                                               title="Slot Detail"
                                                               onClick={() =>
                                                                  this.initialTableValueDeal(
                                                                     slot?.slot_pricing,
                                                                     slot?.name,
                                                                     slot?.description,
                                                                     slot?.id,
                                                                     slot?.deal
                                                                  )
                                                               }
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#BackdropTwo"
                                                            ></i>
                                                         </Link>
                                                      </div>
                                                   </div>
                                                   <hr className="mt-0" />
                                                   {slot.deal === null ? (
                                                      <div
                                                         className="SlotFour SlotBackground  text-white rounded d-flex justify-content-center align-items-center"
                                                         style={{
                                                            width: "145px",
                                                            height: "120px",
                                                            margin: "auto",
                                                            fontSize: "15px",
                                                         }}
                                                      >
                                                         <div className="Text text-center SlotTextColor">
                                                            <h5 className="mb-0 fs-6 ">
                                                               Slot Ready For
                                                               Booking
                                                            </h5>
                                                            <p className="mb-0 ">
                                                               Recommended Size
                                                            </p>
                                                            <p className="mb-0">
                                                               145 x 120
                                                            </p>
                                                         </div>
                                                      </div>
                                                   ) : (
                                                      <div
                                                         className="card-body"
                                                         style={{
                                                            width: "145px",
                                                            height: "120px",
                                                            margin: "auto",
                                                            borderRadius: "5px",
                                                            backgroundColor:
                                                               slot.deal
                                                                  ?.deal_image[0]
                                                                  ? ""
                                                                  : "#848784",
                                                            backgroundImage: `url(${slot.deal?.deal_image[0]?.folder_path}${slot.deal?.deal_image[0]?.file_name})`,
                                                         }}
                                                      ></div>
                                                   )}
                                                </div>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              ))}
                           </div>
                        );
                     })}
                     {this.state.lastPage > 1 && (
                        <Pagination
                           pageCount={this.state.lastPage}
                           paginate={this.paginate}
                        />
                     )}
                  </Col>

                  <div className="col-md-8">
                     <Col
                        className="position-fixed right_box w-50 "
                        style={{
                           // width:"840px",
                           marginTop: "2px",
                           zIndex: "9",
                           // position: "fixed",
                           // top: "26%",
                           // right:"1.75%"
                           right: "28px",
                        }}
                     >
                        <SalesAnalytics />
                     </Col>
                  </div>

                  {/* <Col lg={3} className="mt-1">
            <SideContent />
          </Col> */}
                  {/* <div
            className="col-7"
           
          > */}
               </div>
            ) : (
               <div
                  className="text-center d-flex align-items-center justify-content-center text-bold"
                  style={{
                     height: "50vh",
                  }}
               >
                  <div class="spinner-border text-enter" role="status">
                     <span class="visually-hidden"></span>
                  </div>
                  {/* //////////////edit mdel */}
               </div>
            )}
            {/* </div> */}
            {/* </Row> */}
         </React.Fragment>
      );
   }
}

export default KanbanBoard;
