import { BaseURL } from "../BaseURL";

export const VetyRegister = async (phoneNumber) => {
  const response = await fetch(`${BaseURL}/admin-register`, {
    body: JSON.stringify({
      phone: phoneNumber,
      // country: 3,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json",
      // Authorization: 'Bearer' + '  ' + accessToken,
    },
  });
  const result = await response.json();
  return result;
};

export const OTPverifying = async (phone, otp) => {
  console.log("phoneOTP", phone);
  console.log("OTP", otp);
  const response = await fetch(`${BaseURL}/otpVerifing`, {
    body: JSON.stringify({
      phone: phone,
      otp: otp,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization: 'Bearer' + '  ' + accessToken,
    },
  });
  const result = await response.json();
  console.log("xxxxxxxxxxxx", result);
  return result;
};
