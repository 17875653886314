import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
// import Multiselect from "multiselect-react-dropdown";
import {
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
   Card,
   Button,
   Container,
} from "reactstrap";
import { getPackageFeature, getPackageTitle } from "../Services/managePackage";
import PerfectScrollbar from "react-perfect-scrollbar";

// import { MdDelete, MdModeEdit } from "react-icons/md";
// import { BsFillEyeFill } from "react-icons/bs";
import {
   getPackages,
   createPackage,
   deletePackage,
   getPermission,
   updatePackage,
} from "../../components/Services/managePackage";
import Pagination from "../../components/VerticalLayout/pagination/Pagination";
import "./ManagePackages.css";
import { Link } from "react-router-dom";
import PackageFeatures from "./PackageFeatures";
import { set } from "lodash";
import { map } from "lodash";
const PackageManagement = () => {
   const [loading, setLoading] = useState(false);
   const [openModel, setOpenModel] = useState("");
   const [editOpenModel, setEditOpenModel] = useState("");
   const [modelControl, setModelControl] = useState(true);
   const [feature, setFeature] = useState([]);

   let tokenAccess = localStorage.getItem("Token");
   let accessToken = JSON.parse(tokenAccess);
   const [loader, setLoader] = useState(false);
   const [selectedId, setSelectedId] = useState(0);
   const [title, setTitle] = useState("");
   const [titleValue, setTitleValue] = useState([]);
   const [description, setDescription] = useState("");

   const [packageType, setPackageType] = useState("");

   const [packageDuration, setPackageDuration] = useState("");
   const [packageAmount, setPackageAmount] = useState("");
   const [status, setStatus] = useState("");
   const [permission, setPermission] = useState("");
   const [features, setFeatures] = useState("");

   const [oneMonthStatus, setOneMonthStatus] = useState("Deactive");
   const [threeMonthStatus, setThreeMonthStatus] = useState("Deactive");
   const [sixMonthStatus, setSixMonthStatus] = useState("Deactive");
   const [nineMonthStatus, setNineMonthStatus] = useState("Deactive");
   const [oneYearStatus, setOneYearStatus] = useState("Deactive");
   const [oneMonthDuration, setOneMonthDuration] = useState("");
   const [threeMonthDuration, setThreeMonthDuration] = useState("");
   const [sixMonthDuration, setSixMonthDuration] = useState("");
   const [nineMonthDuration, setNineMonthDuration] = useState("");
   const [packageLevel, setPackageLevel] = useState("");
   const [yearDuration, setYearDuration] = useState("");
   const [oneMonthValue, setOneMonthValue] = useState("");
   const [ParkingSpotsFeatured, setParkingSpotsFeatured] = useState(0);
   const [ParkingSpotsFeaturedID, setParkingSpotsFeaturedID] = useState(5);
   const [threeMonthValue, setThreeMonthValue] = useState("");
   const [hot, setHot] = useState(0);
   const [hotID, setHotID] = useState(6);
   const [superHot, setSuperHot] = useState(0);
   const [superHotID, setSuperHotID] = useState(7);
   const [sixMonthValue, setSixMonthValue] = useState("");
   const [nineMonthValue, setNineMonthValue] = useState("");
   const [yearValue, setYearValue] = useState("");
   const [numberOfListingsID, setNumberOfListingsID] = useState("");
   const [titleError, setTitleError] = useState(false);
   const [packageTypeError, setPackageTypeError] = useState(false);
   const [descriptionError, setDescriptionError] = useState(false);
   const [packageDurationError, setPackageDurationError] = useState(false);
   const [packageAmountError, setPackageAmountError] = useState(false);
   const [permissionError, setPermissionError] = useState(false);
   const [numberOfListingsError, setNumberOfListingsError] = useState(false);
   // const [featuresError, setFeaturesError] = useState(false)
   const [statusError, setStatusError] = useState(false);
   const [featureError, setFeatureError] = useState(false);
   const [allPackages, setAllPackages] = useState([]);
   const [allPermission, setAllPermission] = useState([]);
   const [allFeatures, setAllFeatures] = useState([]);
   const [featuresTitle, setFeaturesTitle] = useState([]);
   const [showData, setShowData] = useState([]);
   const [lastPage, setLastPage] = useState([]);
   const [oneMonthError, setOneMonthError] = useState(false);

   /////////////////// model funtion start///////////////////
   const [style, setStyle] = useState("sideMenu");
   const [menuStatus, setMenuStatus] = useState("open");

   const [featureType, setFeatureType] = useState("Unlimited");
   const [packageID, setPackageID] = useState("");
   const [isFeatureTypeModal, setIsFeatureTypeModal] = useState(false);
   const [numberOfListings, setNumberOfListings] = useState("");
   const [oneParkingSpots, setOneParkingSpots] = useState(1);
   const [oneParkingSpotsID, setOneParkingSpotsID] = useState(2);
   const [oneParkingSpotsPhotos, setOneParkingSpotsPhotos] = useState(1);
   const [oneParkingSpotsPhotosID, setOneParkingSpotsPhotosID] = useState(3);
   const [fiveParkingSpots, setFiveParkingSpots] = useState("");
   const [fiveParkingSpotsID, setFiveParkingSpotsID] = useState("");
   const [FiveParkingSpotsPhotos, setFiveParkingSpotsPhotos] = useState("");
   const [FiveParkingSpotsPhotosID, setFiveParkingSpotsPhotosID] = useState("");
   const [tenParkingSpots, setTenParkingSpots] = useState("");
   const [tenParkingSpotsID, setTenParkingSpotsID] = useState("");
   const [TenParkingSpotsPhotos, setTenParkingSpotsPhotos] = useState("");
   const [TenParkingSpotsPhotosID, setTenParkingSpotsPhotosID] = useState("");
   const [pageIDs, setPageIDs] = useState([]);

   const [oneMonthParkingStatus, setOneMonthParkingStatus] =
      useState("Deactive");
   const [oneMonthParkingStatusPhotos, setOneMonthParkingStatusPhotos] =
      useState("Deactive");
   const [fiveMonthParkingStatus, setFiveMonthParkingStatus] =
      useState("Deactive");
   const [fiveMonthParkingStatusPhotos, setFiveMonthParkingStatusPhotos] =
      useState("Deactive");
   const [tenMonthParkingStatus, setTenMonthParkingStatus] =
      useState("Deactive");
   const [tenMonthParkingStatusPhotos, setTenMonthParkingStatusPhotos] =
      useState("Deactive");

   const [allFeature, setAllFeature] = useState([]);
   const [allFeatureEdit, setAllFeatureEdit] = useState([]);

   const myFunction = (data) => {
      setShowData(data);
      switch (menuStatus) {
         case "open":
            setMenuStatus("close");
            setStyle("sideMenu activeSideMenu");
            break;
         // case 'close':
         //   setMenuStatus('open')
         //   setStyle('sideMenu')
         //   break
      }
   };

   ///////////// model funtion end///////////////////
   useEffect(() => {
      setLoader(false);
      getAllPackages(1);
      PackageTitle();
      PackageFeatures();

      // getAllPermission();
   }, []);
   const clear = () => {
      setTitle("");
      setDescription("");
      setStatus("");
      setFeatures("");
      setOneMonthValue("");
      setThreeMonthValue("");
      setSixMonthValue("");
      setNineMonthValue("");
      setYearValue("");
      setPackageType("");
      setTitleError(false);
      setPackageTypeError(false);
      setDescriptionError(false);
      setStatusError(false);
      setOneMonthStatus("Deactive");
      setThreeMonthStatus("Deactive");
      setSixMonthStatus("Deactive");
      setNineMonthStatus("Deactive");
      setOneYearStatus("Deactive");
      setLoading(false);
   };
   const getAllPackages = async (page) => {
      try {
         const result = await getPackages(accessToken, page);
         let IDArray = [];
         if (result.status === true) {
            setLoader(true);
            setLastPage(result.package.last_page);
            setAllPackages(result.package.data);
            for (let i = result.package.from; i <= result.package.to; i++) {
               IDArray.push({ id: i });
            }
            setPageIDs(IDArray);
         } else {
            setLoader(true);
         }
      } catch (err) {
         setLoader(true);
      }
   };
   const getAllPermission = async () => {
      try {
         // setLoader(false)
         const result = await getPermission(accessToken);

         if (result.status === true) {
            // setLoader(true)

            setAllPermission(result.permission);
         } else {
            // setLoader(true)
         }
      } catch (err) {
         // setLoader(true)
      }
   };

   const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
   });
   const addPackage = async () => {
      try {
         if (
            packageID == "" ||
            packageID == undefined ||
            packageID?.length < 1
         ) {
            setTitleError(true);
         }
         if (description === "" || description === undefined) {
            setDescriptionError(true);
         }
         if (
            packageType === "" ||
            packageType === undefined ||
            packageType.length < 1
         ) {
            setPackageTypeError(true);
         }
         // if (permission === '' || permission === undefined || permission.length < 1) {
         //   setPermissionError(true)
         // }
         // if (packageType == 'buyer') {
         // if (features === '' || features === undefined || features.length < 1) {
         //   setFeaturesError(true)

         // }

         // if (packageAmount === '' || packageAmount === undefined) {
         //   setPackageAmountError(true)
         // }

         if (status === "" || status === undefined) {
            setStatusError(true);
         }
         if (
            packageID != "" &&
            packageID != undefined &&
            description != "" &&
            description != undefined &&
            status != "" &&
            status != undefined
         ) {
            // let permissionId = []
            // permissionId = permission.map((item, index) => item.id)

            setLoading(true);
            const result = await createPackage(
               packageID,
               description,
               oneMonthValue,
               threeMonthValue,
               sixMonthValue,
               nineMonthValue,
               yearValue,
               status,
               packageType,
               oneMonthStatus,
               threeMonthStatus,
               sixMonthStatus,
               nineMonthStatus,
               oneYearStatus,
               featureType,
               isFeatureTypeModal,
               numberOfListings,
               numberOfListingsID,
               oneParkingSpots,
               oneParkingSpotsID,
               oneParkingSpotsPhotos,
               oneParkingSpotsPhotosID,
               ParkingSpotsFeatured,
               ParkingSpotsFeaturedID,
               hot,
               hotID,
               superHot,
               superHotID,
               FiveParkingSpotsPhotos,
               FiveParkingSpotsPhotosID,
               tenParkingSpots,
               tenParkingSpotsID,
               TenParkingSpotsPhotos,
               TenParkingSpotsPhotosID,
               oneMonthParkingStatus,
               oneMonthParkingStatusPhotos,
               fiveMonthParkingStatus,
               fiveMonthParkingStatusPhotos,
               tenMonthParkingStatus,
               tenMonthParkingStatusPhotos,
               accessToken
            );
            console.log("object", result);
            if (result.status === true) {
               setLoading(false);
               Toast.fire({
                  icon: "success",
                  title: "Package added successfully",
               });
               getAllPackages(1);
               // let packageArray = [...allPackages];
               // packageArray.unshift(result.package);

               // setAllPackages(packageArray);
               setOpenModel(false);
               clear();
            } else {
               setLoading(false);
            }
         }
      } catch (err) {
         console.log(err);
         Toast.fire({
            icon: "error",
            title: "Please try again",
         });
         setOpenModel(false);
      }
   };
   const editPackage = async () => {
      try {
         if (packageID === "" || packageID === undefined) {
            setTitleError(true);
         }
         if (description === "" || description === undefined) {
            setDescriptionError(true);
         }
         if (packageType === "" || packageType === undefined) {
            setPackageTypeError(true);
         }
         // if (permission === '' || permission === undefined || permission.length < 1) {
         //   setPermissionError(true)
         // }
         // if (features === '' || features === undefined || features.length < 1) {
         //   setFeaturesError(true)
         // }
         // if (packageAmount === '' || packageAmount === undefined) {
         //   setPackageAmountError(true)
         // }
         if (status === "" || status === undefined) {
            setStatusError(true);
         }

         if (
            packageID != "" &&
            packageID != undefined &&
            description != "" &&
            description != undefined &&
            status != "" &&
            status != undefined
            // packageType != "" &&
            // packageType != undefined &&
            // features != '' &&
            // features != undefined &&
            // featuresError == false
         ) {
            // let permissionId = []

            // permissionId = permission.map((item, index) => item.id)
            let featuresId = [];

            if (features.length > 0) {
               featuresId = features.map((item, index) => item.id);
            }

            setLoading(true);
            const result = await updatePackage(
               packageID,
               description,
               oneMonthValue,
               threeMonthValue,
               sixMonthValue,
               nineMonthValue,
               yearValue,
               status,
               packageType,
               oneMonthStatus,
               threeMonthStatus,
               sixMonthStatus,
               nineMonthStatus,
               oneYearStatus,
               featureType,
               isFeatureTypeModal,
               numberOfListings,
               numberOfListingsID,
               oneParkingSpots,
               oneParkingSpotsID,
               oneParkingSpotsPhotos,
               oneParkingSpotsPhotosID,
               ParkingSpotsFeatured,
               ParkingSpotsFeaturedID,
               hot,
               hotID,
               superHot,
               superHotID,
               FiveParkingSpotsPhotos,
               FiveParkingSpotsPhotosID,
               tenParkingSpots,
               tenParkingSpotsID,
               TenParkingSpotsPhotos,
               TenParkingSpotsPhotosID,
               oneMonthParkingStatus,
               oneMonthParkingStatusPhotos,
               fiveMonthParkingStatus,
               fiveMonthParkingStatusPhotos,
               tenMonthParkingStatus,
               tenMonthParkingStatusPhotos,
               selectedId,
               accessToken
            );
            console.log(result, "aaaa");
            if (result.status === true) {
               setLoading(false);
               Toast.fire({
                  icon: "success",
                  title: "Package updated successfully",
               });
               setEditOpenModel(false);
               let packageArray = [...allPackages];
               packageArray.map((item, index) => {
                  if (item.id === result.package.id) {
                     packageArray[index] = result?.package;
                     // packageArray[index].title = result.package.title
                     // packageArray[index].description = result.package.description
                     // packageArray[index].status = result.package.status
                     // packageArray[index].package_prices = result.package.package_prices
                     // packageArray[index].features = result.package.features
                  }
               });
               setAllPackages(packageArray);
               clear();
            } else {
               setLoading(false);
            }
         }
      } catch (err) {
         console.log(err);
         setLoading(false);
         Toast.fire({
            icon: "error",
            title: "Please try again",
         });
      }
   };
   const removePackage = async (id, packageCount) => {
      // if (packageCount < 1) {
      //    Swal.fire({
      //       text: "You can not edit or delete this package because package is in use",
      //       icon: "warning",
      //       showCancelButton: false,
      //       confirmButtonColor: "#3a7786",
      //       reverseButtons: true,
      //       reverseButtons: true,

      //       confirmButtonText: "Yes",
      //    });
      // }

      try {
         const result = await Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this package?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#7e8299",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });

         if (result.isConfirmed) {
            const reasonResult = await deletePackage(accessToken, id);
            if (reasonResult.status === true) {
               Toast.fire({
                  icon: "success",
                  title: "Package remove successfully",
               });
               let reasonArray = [...allPackages];

               reasonArray.map((item, index) => {
                  if (item.id === id) {
                     reasonArray.splice(index, 1);
                  }
               });

               setAllPackages(reasonArray);
            } else if (reasonResult.status === false) {
               Swal.fire({
                  icon: "error",
                  // title: `${reasonResult.package}`,
                  text: `${reasonResult.package}`,
                  confirmButtonColor: "#3a7786",
                  confirmButtonText: "Ok",
                  reverseButtons: true,
               });
            }
         } else if (result.isDismissed) {
            // console.log('isDenied')
         }
      } catch (err) {
         // Toast.fire({
         //    icon: "error",
         //    title: "Please try again",
         // });
         console.log("resultDelete", err);
         Swal.fire({
            text: "You can not edit or delete this package because package is in use",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            confirmButtonText: "Yes",
         });
      }
   };

   /////////
   // const permissionChange = (e) => {
   //   setPermission(e)
   //   setPermissionError(false)
   // }
   const featuresChange = (e) => {
      setFeatures(e);
      // setFeaturesError(false)
   };
   /////////////////////////validation////////
   const handleChange = (e, valueName) => {
      if (valueName == "title") {
         setTitle(e.target.value);
         if (e.target.value.length < 1) {
            setTitleError(true);
         } else setTitleError(false);
      } else if (valueName == "description") {
         setDescription(e.target.value);
         if (e.target.value.length < 1) {
            setDescriptionError(true);
         } else setDescriptionError(false);
      } else if (valueName == "packageDuration") {
         setPackageDuration(e.target.value);
         if (e.target.value.length < 1) {
            setPackageDurationError(true);
         } else setPackageDurationError(false);
      } else if (valueName == "packageAmount") {
         setPackageAmount(e.target.value);
         if (e.target.value.length < 1) {
            setPackageAmountError(true);
         } else setPackageAmountError(false);
      } else if (valueName == "status") {
         setStatus(e.target.value);
         if (e.target.value.length < 1) {
            setStatusError(true);
         } else setStatusError(false);
      } else if (valueName == "packageType") {
         setPackageType(e.target.value);
         if (e.target.value.length < 1) {
            setPackageTypeError(true);
         } else setPackageTypeError(false);
      } else if (valueName == "1monthDuration") {
         setOneMonthValue(e.target.value);

         if (e.target.value.length < 1) {
            setOneMonthError(true);
         } else setOneMonthError(false);
      } else if (valueName == "3monthDuration") {
         setThreeMonthValue(e.target.value);
         // if (e.target.value.length < 1) {
         //   setPackageAmountError(true)
         // } else setPackageAmountError(false)
      } else if (valueName == "6monthDuration") {
         setSixMonthValue(e.target.value);
         // if (e.target.value.length < 1) {
         //   setStatusError(true)
         // } else setStatusError(false)
      } else if (valueName == "9monthDuration") {
         setNineMonthValue(e.target.value);
         // if (e.target.value.length < 1) {
         //   setStatusError(true)
         // } else setStatusError(false)
      } else if (valueName == "yearDuration") {
         setYearValue(e.target.value);
         // if (e.target.value.length < 1) {
         //   setStatusError(true)
         // } else setStatusError(false)
      }
   };
   const handleStatusChange = (value, valueInput) => {
      if (valueInput == "oneMonthStatus") {
         if (value === true) {
            setOneMonthStatus("active");
            setOneMonthError(true);
         } else if (value === false) {
            setOneMonthStatus("Deactive");
            setOneMonthError(false);
         }
      } else if (valueInput == "threeMonthStatus") {
         if (value === true) {
            setThreeMonthStatus("active");
         } else if (value === false) {
            setThreeMonthStatus("Deactive");
         }
      } else if (valueInput == "sixMonthStatus") {
         if (value === true) {
            setSixMonthStatus("active");
         } else if (value === false) {
            setSixMonthStatus("Deactive");
         }
      } else if (valueInput == "nineMonthStatus") {
         if (value === true) {
            setNineMonthStatus("active");
         } else if (value === false) {
            setNineMonthStatus("Deactive");
         }
      } else if (valueInput == "oneYearStatus") {
         if (value === true) {
            setOneYearStatus("active");
         } else if (value === false) {
            setOneYearStatus("Deactive");
         }
      }
   };
   const setInitialValues = (
      id,
      dbTitle,
      dbDescription,
      dbStatus,
      dbFeatures,
      dbPackagePrice,
      packageFor,
      packageCount,
      features,
      packageid
   ) => {
      setAllFeatureEdit(features);

      // if (packageCount < 1) {
      //    Swal.fire({
      //       text: "You can not edit or delete this package because package is in use",
      //       icon: "warning",
      //       showCancelButton: false,
      //       confirmButtonColor: "#3a7786",
      //       reverseButtons: true,
      //       confirmButtonText: "Yes",
      //    });
      // }

      features?.map((item) => {
         if (item?.title == "Number of listings") {
            setNumberOfListingsID(item?.id);
         }
      });
      setEditOpenModel(true);
      setSelectedId(id);
      setTitle(dbTitle);
      setDescription(dbDescription);
      setStatus(dbStatus);
      setPackageType(packageFor);
      setPackageID(packageid);

      dbPackagePrice.map((item, index) => {
         if (index == 0) {
            setOneMonthValue(item.price);
            setOneMonthStatus(item.status);
         }
         // else if (index == 1) {
         //   setThreeMonthValue(item.price)
         //   setThreeMonthStatus(item.status)
         // } else
         if (index == 1) {
            setSixMonthValue(item.price);
            setSixMonthStatus(item.status);
         }
         // else if (index == 3) {
         //   setNineMonthValue(item.price)
         //   setNineMonthStatus(item.status)
         // }
         else if (index == 2) {
            setYearValue(item.price);
            setOneYearStatus(item.status);
         }
      });
      // setOneMonthValue('dd', monthly)

      let mapFeatures = [];
      dbFeatures.map((item, index) =>
         mapFeatures.push({ id: item.id, name: item.title })
      );
      setFeatures(mapFeatures);
      // if (dbMonthlyPrice) {
      //   setIs1MonthInput(false)
      // }
      // if (dbQuarterlyPrice) {
      //   setIs3MonthInput(false)
      // }
      // if (dbSixMonthPrice) {
      //   setIs6MonthInput(false)
      // }
      // if (dbNineMonthPrice) {
      //   setIs9MonthInput(false)
      // }
      // if (dbYearlyPrice) {
      //   setIsYearInput(false)
      // }
   };

   let pageCount = lastPage;

   const paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await getAllPackages(page);
   };
   const onKeyPressAdd = (e) => {
      if (e.key === "Enter") {
         addPackage();
      }
   };
   const onKeyPressEdit = (e) => {
      if (e.key === "Enter") {
         editPackage();
      }
   };
   const dateFormateHandler = (createdAt) => {
      let today = new Date(createdAt);
      let date = new Intl.DateTimeFormat("en-US", {
         year: "numeric",
         month: "2-digit",
         day: "2-digit",
         // hour: '2-digit',
         // minute: '2-digit',
         // second: '2-digit',
      }).format(today);
      return date;
   };
   const onChangeValue = (e) => {
      if (e.target.value == "Unlimited") {
         setFeatureType(e.target.value);
         setNumberOfListings("");
      } else if (e.target.value == "Custom") {
         setIsFeatureTypeModal(true);
      }
   };

   const PackageFeatures = async () => {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      const result = await getPackageFeature(accessToken);

      if (result.status == true) {
         setAllFeature(result?.features);
         console.log("result", result);
         result?.features?.map((item) => {
            if (item?.title == "Number of listings") {
               setNumberOfListingsID(item?.id);
            }
         });
      }
   };
   const PackageTitle = async () => {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      const result = await getPackageTitle(accessToken);

      if (result.status == true) {
         setTitleValue(result?.package_types);
         console.log("result title", result);
         // result?.features?.map((item) => {
         //    if (item?.title == "Number of listings") {
         //       setNumberOfListingsID(item?.id);
         //    }
         // });
      }
   };
   const FeatureChange = async (e) => {
      switch (e.target.name) {
         case "parking-spots":
            setOneParkingSpots(e.target.value);
            setOneParkingSpotsID(e.target.id);
            break;
         case "parking-spots-photos":
            setOneParkingSpotsPhotos(e.target.value);
            setOneParkingSpotsPhotosID(e.target.id);
            break;
         case "featured":
            setParkingSpotsFeatured(e.target.value);
            setParkingSpotsFeaturedID(e.target.id);
            break;
         case "hot":
            setHot(e.target.value);
            setHotID(e.target.id);
            break;
         case "super-hot":
            setSuperHot(e.target.value);
            setSuperHotID(e.target.id);
            break;
         case "ten-parking-spots-photos":
            setTenParkingSpotsPhotos(e.target.value);
            setTenParkingSpotsPhotosID(e.target.id);
            break;

         default:
            break;
      }
   };
   const handleFeatureStatusChange = (value, valueInput) => {
      if (valueInput == "one-parking-spots") {
         if (value === true) {
            setOneMonthParkingStatus("active");
            // setOneMonthError(true);
         } else if (value === false) {
            setOneMonthParkingStatus("Deactive");
            // setOneMonthError(false);
         }
      } else if (valueInput == "one-parking-spots-photos") {
         if (value === true) {
            setTenMonthParkingStatusPhotos("active");
         } else if (value === false) {
            setTenMonthParkingStatusPhotos("Deactive");
         }
      } else if (valueInput == "five-parking-spot") {
         if (value === true) {
            setFiveMonthParkingStatus("active");
         } else if (value === false) {
            setFiveMonthParkingStatus("Deactive");
         }
      } else if (valueInput == "five-parking-spots-photos") {
         if (value === true) {
            setFiveMonthParkingStatusPhotos("active");
         } else if (value === false) {
            setFiveMonthParkingStatusPhotos("Deactive");
         }
      } else if (valueInput == "ten-parking-spots") {
         if (value === true) {
            setTenMonthParkingStatus("active");
         } else if (value === false) {
            setTenMonthParkingStatus("Deactive");
         }
      } else if (valueInput == "ten-parking-spots-photos") {
         if (value === true) {
            setTenMonthParkingStatusPhotos("active");
         } else if (value === false) {
            setTenMonthParkingStatusPhotos("Deactive");
         }
      }
   };
   return (
      <>
         <div className="page-content">
            <Container>
               {/* ///////////////////////////// */}
               <h5 className="fs-5 mb-3 fw-bold">Package Management </h5>
               {loader ? (
                  <>
                     <Card className="p-4 ">
                        <div className="d-flex justify-content-between">
                           <div>
                              {/* <div className="search-box "> */}
                              {/* <input
                type="search"
                class="form-control rounded-pill"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) => this.SearchChangeHandler(e)}
              /> */}
                              {/* </div> */}
                           </div>
                           <div>
                              <Button
                                 type="button"
                                 onClick={() => {
                                    setOpenModel(true);
                                    clear();
                                    setOneParkingSpots(1);
                                    setOneParkingSpotsPhotos(1);
                                    setParkingSpotsFeatured(0);
                                    setHot(0);
                                    setSuperHot(0);
                                    setPackageLevel("");
                                 }}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn-rounded mb-2 me-2"
                              >
                                 <span>Add Package</span>
                              </Button>
                           </div>
                        </div>
                        <div className="overflow-auto">
                           <table class="table bg-white table-responsive">
                              <thead>
                                 <tr>
                                    <th className="text-start min-w-100px">
                                       No.
                                    </th>
                                    <th className="text-start min-w-100px">
                                       Title
                                    </th>
                                    {/* <th className='text-center min-w-100px'>Duration</th>
                            <th className='text-center min-w-100px'>Price</th> */}
                                    <th className="text-center min-w-100px">
                                       Status
                                    </th>
                                    {/* <th className="text-center min-w-100px">
                                       Type
                                    </th> */}
                                    <th className="text-center min-w-100px">
                                       Date
                                    </th>
                                    <th className="text-center  min-w-100px">
                                       Action
                                    </th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {allPackages?.length > 0 ? (
                                    <>
                                       {" "}
                                       {allPackages?.map((item, outer) => (
                                          <tr key={outer}>
                                             {pageIDs?.map((item, inner) =>
                                                outer == inner ? (
                                                   <td>{item.id}</td>
                                                ) : null
                                             )}
                                             <td
                                                className="text-start min-w-100px mb-1 ms-2 "
                                                // onClick={() => handleSlideBar(item)}
                                             >
                                                {item?.package_type?.name?.substring(
                                                   0,
                                                   30
                                                ) ?? "NaN"}
                                                {item?.package_type?.name >=
                                                   30 && "..."}
                                             </td>

                                             <td className="text-center min-w-100px">
                                                <div>
                                                   {item.status === "active" ? (
                                                      <span className="badge bg-success">
                                                         Active
                                                      </span>
                                                   ) : item.status ===
                                                     "Deactive" ? (
                                                      <span className="badge bg-danger me-1">
                                                         Deactive
                                                      </span>
                                                   ) : (
                                                      "No Status"
                                                   )}
                                                </div>
                                             </td>
                                             {/* <td className="text-center min-w-100px">
                                          {item?.package_for}
                                       </td> */}
                                             <td className="text-center min-w-100px">
                                                {dateFormateHandler(
                                                   item?.created_at
                                                ) ?? "NO Date"}
                                             </td>
                                             <td className="text-center min-w-100px">
                                                <Link
                                                   to="#"
                                                   className="me-3 text-primary"
                                                >
                                                   <i
                                                      title="View"
                                                      className="mdi mdi-eye font-size-18"
                                                      onClick={() =>
                                                         myFunction(item)
                                                      }
                                                   ></i>
                                                </Link>
                                                <Link
                                                   to="#"
                                                   className="me-3 text-primary"
                                                >
                                                   <i
                                                      title="Edit"
                                                      className="mdi mdi-pencil font-size-18"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdropTwo"
                                                      onClick={() => {
                                                         console.log(item);
                                                         setInitialValues(
                                                            item.id,
                                                            item?.package_type
                                                               ?.name,
                                                            item?.description,
                                                            item?.status,
                                                            item?.features,
                                                            item?.package_prices,
                                                            item?.package_for,
                                                            item?.packageCount,
                                                            item?.features,
                                                            item?.package_type
                                                               ?.id
                                                         );
                                                      }}
                                                   ></i>
                                                </Link>
                                                <Link
                                                   to="#"
                                                   className="text-danger"
                                                >
                                                   <i
                                                      title="Delete"
                                                      className="mdi mdi-trash-can font-size-18 me-3"
                                                      onClick={() =>
                                                         removePackage(
                                                            item.id,
                                                            item?.packageCount
                                                         )
                                                      }
                                                   ></i>
                                                </Link>
                                             </td>
                                          </tr>
                                       ))}
                                    </>
                                 ) : (
                                    <>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>

                                       <tr className="pt-5">
                                          <td className="pt-5">
                                             <div class="record-not-found pt-5">
                                                Record Not Found
                                             </div>
                                          </td>
                                       </tr>
                                    </>
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </Card>
                     {lastPage > 1 && (
                        <Pagination pageCount={pageCount} paginate={paginate} />
                     )}
                  </>
               ) : (
                  <div
                     className="text-center d-flex align-items-center justify-content-center "
                     style={{ height: "50vh" }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </Container>
            {/* ///////////////////////add model */}
            <Modal size="lg" isOpen={openModel} centered={true} toggle={null}>
               <ModalHeader
                  toggle={() => setOpenModel(!openModel)}
                  onClick={() => clear()}
               >
                  <h5 className="modal-title">Add Package</h5>
               </ModalHeader>

               <ModalBody>
                  <div className="row ">
                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label"
                        >
                           Package Title
                        </label>
                        <select
                           className="form-select  form-select-solid "
                           aria-label="Default select example"
                           onChange={(e) => {
                              setPackageID(e.target.value);
                              setTitleError(false);
                              // setTitle(e.target.value);
                              setPackageLevel(e.target.value);

                              // console.log(e.target.value);
                           }}
                           // onKeyPress={(e) => onKeyPressEdit(e)}
                           // defaultValue={status}
                           // value={title}
                        >
                           <option selected hidden>
                              Select Package
                           </option>

                           {titleValue?.map((item, index) => (
                              <option
                                 id={item?.name}
                                 value={item?.id}
                                 key={index}
                              >
                                 {item?.name}
                              </option>
                           ))}
                        </select>
                        {/* <input
                           type="text"
                           id="userEmail"
                           className="form-control form-control-solid "
                           onChange={(e) => handleChange(e, "title")}
                           onKeyPress={(e) => onKeyPressAdd(e)}
                           style={{
                              marginTop: "3px",
                           }}
                           placeholder="Enter Title"
                           required
                        /> */}
                        {titleError && (
                           <div className="modelValidation">Select title</div>
                        )}
                     </div>

                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label"
                        >
                           Package Description
                        </label>
                        <textarea
                           type="text"
                           id="userEmail"
                           className="form-control form-control-solid "
                           onChange={(e) => {
                              handleChange(e, "description");
                              console.log(packageLevel);
                           }}
                           style={{
                              marginTop: "3px",
                           }}
                           placeholder="Enter Description"
                           required
                        />
                        {descriptionError && (
                           <div className="modelValidation">
                              Enter Description
                           </div>
                        )}
                     </div>

                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label mb-2"
                        >
                           Package Status
                        </label>
                        <select
                           className="form-select  form-select-solid "
                           aria-label="Default select example"
                           onChange={(e) => handleChange(e, "status")}
                           onKeyPress={(e) => onKeyPressAdd(e)}
                           // defaultValue={status}
                        >
                           <option selected hidden>
                              Select Status
                           </option>
                           <option value="active">Active</option>
                           <option value="Deactive">Deactive</option>
                        </select>

                        {statusError && (
                           <div className="modelValidation">Select status</div>
                        )}
                     </div>
                  </div>
                  {packageLevel !== "1" && (
                     <>
                        <div className="row ">
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className="form-label"
                              >
                                 Package Duration
                              </label>
                           </div>
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className=" form-label"
                              >
                                 Package Amount
                              </label>
                           </div>
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className=" d-flex justify-content-center form-label"
                              >
                                 Deactive/Active
                              </label>
                           </div>
                        </div>
                        <div className="row mb-2">
                           <div className="col-4 d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 1 month
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "1monthDuration")
                                 }
                                 value={oneMonthValue}
                                 placeholder="1 Month"
                              />
                           </div>
                           <div className="col-4 d-flex justify-content-center align-items-center">
                              <div className="form-check form-switch  ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "oneMonthStatus"
                                       )
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row mb-2">
                           <div className="col-4  d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 6 Month
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 id="userEmail"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "6monthDuration")
                                 }
                                 value={sixMonthValue}
                                 placeholder="6 Month"
                              />
                           </div>
                           <div className="col-4 d-flex align-items-center justify-content-center">
                              {" "}
                              <div className="form-check form-switch ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "sixMonthStatus"
                                       )
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row  mb-2">
                           <div className="col-4  d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 1 Year
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 id="userEmail"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "yearDuration")
                                 }
                                 value={yearValue}
                                 placeholder="1 Year"
                              />
                           </div>
                           <div className="col-4 d-flex align-items-center justify-content-center">
                              <div className="form-check form-switch ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "oneYearStatus"
                                       )
                                    }
                                 />
                              </div>
                           </div>
                        </div>
                     </>
                  )}

                  <div className="row  mt-4">
                     <div className="col-4">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="form-label"
                        >
                           Features
                        </label>
                     </div>
                     <div className="col-4">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className=" form-label"
                        >
                           Features Count
                        </label>
                     </div>
                  </div>
                  {console.log("allFeature", allFeature)}
                  {allFeature?.length > 0
                     ? allFeature?.map((item, index) => (
                          <div className="row  my-2 " key={index}>
                             <div className="col-4  d-flex align-items-center">
                                <label
                                   className="form-check-label fw-bold"
                                   htmlFor="flexCheckDefault"
                                >
                                   {item?.title}
                                </label>
                             </div>
                             <div className="col-4">
                                {item?.title == "Number of listings" ? (
                                   <select
                                      className="form-select  form-select-solid "
                                      aria-label="Default select example"
                                      onChange={(e) => onChangeValue(e)}
                                      // onKeyPress={(e) => onKeyPressAdd(e)}
                                      // defaultValue={status}
                                   >
                                      {/* <option selected hidden>
                                         Feature Type
                                      </option> */}

                                      <option value="Unlimited">
                                         Unlimited
                                      </option>
                                      {numberOfListings !== "" &&
                                      numberOfListings !== undefined ? (
                                         <option
                                            selected
                                            hidden
                                            value={numberOfListings}
                                         >
                                            {numberOfListings}
                                         </option>
                                      ) : (
                                         <option value="Custom">Custom</option>
                                      )}
                                      {numberOfListings == "" &&
                                         numberOfListings == undefined && (
                                            <option value="Custom">
                                               Custom
                                            </option>
                                         )}
                                   </select>
                                ) : item?.slug == "parking-spots" ? (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      value={oneParkingSpots}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      placeholder="Number"
                                      required
                                   />
                                ) : item.slug == "parking-spots-photos" ? (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      value={oneParkingSpotsPhotos}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      placeholder="Number"
                                      required
                                   />
                                ) : item.slug == "featured" ? (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      value={ParkingSpotsFeatured}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      placeholder="Number"
                                      required
                                   />
                                ) : item?.slug == "hot" ? (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      value={hot}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      placeholder="Number"
                                      required
                                   />
                                ) : item?.slug == "super-hot" ? (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      value={superHot}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      placeholder="Number"
                                      required
                                   />
                                ) : null}
                             </div>
                          </div>
                       ))
                     : null}
               </ModalBody>

               <ModalFooter className="text-center py-3">
                  <button
                     type="reset"
                     className="btn btn-secondary me-3"
                     onClick={() => {
                        setOpenModel(!openModel);
                        clear();
                        // setThrowEmailError(false)
                     }}
                  >
                     Close
                  </button>

                  <button
                     type="button"
                     className="btn "
                     onClick={addPackage}
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                        color: "white",
                     }}
                  >
                     {!loading && <span className="indicator-label">Save</span>}
                     {loading && (
                        <span
                           className="indicator-progress"
                           style={{ display: "block" }}
                        >
                           Please wait...
                           <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                     )}
                  </button>
               </ModalFooter>
            </Modal>
            {/* Add Model End */}

            {/*edit Model Start */}
            <Modal
               size="lg"
               isOpen={editOpenModel}
               centered={true}
               toggle={null}
            >
               <ModalHeader
                  toggle={() => {
                     setEditOpenModel(!editOpenModel);
                     clear();
                  }}
               >
                  <h5 className="modal-title">Edit Package</h5>
               </ModalHeader>
               <ModalBody>
                  <div className="row ">
                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label"
                        >
                           Package Title
                        </label>
                        <select
                           className="form-select  form-select-solid "
                           aria-label="Default select example"
                           onChange={(e) => {
                              setPackageID(e.target.value);
                              setPackageLevel(e.target.value);
                              setTitle("");
                              // setTitle(e.target.value);
                              // console.log(e.target.value);
                           }}
                           // onKeyPress={(e) => onKeyPressEdit(e)}
                           // defaultValue={title}
                           // value={title}
                           // value={title}
                        >
                           {title !== "" && title !== undefined && (
                              <option selected hidden>
                                 {title}
                              </option>
                           )}
                           {titleValue?.map((item, index) => (
                              <option
                                 id={item?.name}
                                 value={item?.id}
                                 key={index}
                              >
                                 {item?.name}
                              </option>
                           ))}
                        </select>
                        {titleError && (
                           <div className="modelValidation">Select title</div>
                        )}
                     </div>

                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label"
                        >
                           Package Description
                        </label>
                        <textarea
                           type="text"
                           id="userEmail"
                           className="form-control form-control-solid "
                           onChange={(e) => handleChange(e, "description")}
                           value={description}
                           style={{
                              marginTop: "3px",
                           }}
                           placeholder="Enter Description"
                           required
                        />
                        {descriptionError && (
                           <div className="modelValidation">
                              Enter description
                           </div>
                        )}
                     </div>

                     <div className="mb-3 col-md-12 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label mb-2"
                        >
                           Package Status
                        </label>
                        <select
                           className="form-select  form-select-solid "
                           aria-label="Default select example"
                           onChange={(e) => handleChange(e, "status")}
                           onKeyPress={(e) => onKeyPressEdit(e)}
                           defaultValue={status}
                           value={status}
                        >
                           <option selected hidden>
                              Select status
                           </option>
                           <option value="active">Active</option>
                           <option value="Deactive">Deactive</option>
                        </select>

                        {statusError && (
                           <div className="modelValidation">Select status</div>
                        )}
                     </div>
                  </div>
                  {packageLevel !== "1" && title !== "Basic" && (
                     <>
                        <div className="row ">
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className="form-label"
                              >
                                 Package Duration
                              </label>
                           </div>
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className=" form-label"
                              >
                                 Package Amount
                              </label>
                           </div>
                           <div className="col-4">
                              <label
                                 htmlFor="exampleFormControlInput1"
                                 className=" d-flex justify-content-center form-label"
                              >
                                 Deactive/Active
                              </label>
                           </div>
                        </div>
                        <div className="row mb-2">
                           <div className="col-4 d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 1 month
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 id="userEmail"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "1monthDuration")
                                 }
                                 value={oneMonthValue}
                                 placeholder="1 Month"
                              />
                           </div>
                           <div className="col-4 d-flex justify-content-center align-items-center">
                              <div className="form-check form-switch  ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "oneMonthStatus"
                                       )
                                    }
                                    defaultChecked={
                                       oneMonthStatus == "active" ? true : false
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row mb-2">
                           <div className="col-4  d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 6 Month
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 id="userEmail"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "6monthDuration")
                                 }
                                 // disabled={is6MonthInput ? true : false}
                                 value={sixMonthValue}
                                 placeholder="6 Month"
                              />
                           </div>
                           <div className="col-4 d-flex align-items-center justify-content-center">
                              {" "}
                              <div className="form-check form-switch ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    defaultChecked={
                                       sixMonthStatus == "active" ? true : false
                                    }
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "sixMonthStatus"
                                       )
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row  mb-2">
                           <div className="col-4  d-flex align-items-center">
                              <label
                                 className="form-check-label fw-bold"
                                 htmlFor="flexCheckDefault"
                              >
                                 1 Year
                              </label>
                           </div>
                           <div className="col-4">
                              <input
                                 type="number"
                                 id="userEmail"
                                 className="form-control form-control-solid "
                                 onChange={(e) =>
                                    handleChange(e, "yearDuration")
                                 }
                                 // disabled={isYearInput ? true : false}
                                 value={yearValue}
                                 placeholder="1 Year"
                              />
                           </div>
                           <div className="col-4 d-flex align-items-center justify-content-center">
                              <div className="form-check form-switch ">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    defaultChecked={
                                       oneYearStatus == "active" ? true : false
                                    }
                                    onChange={(e) =>
                                       handleStatusChange(
                                          e.target.checked,
                                          "oneYearStatus"
                                       )
                                    }
                                 />
                              </div>
                           </div>
                        </div>
                     </>
                  )}

                  <div className="row  mt-4">
                     <div className="col-4">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="form-label"
                        >
                           Features
                        </label>
                     </div>
                     <div className="col-4">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className=" form-label"
                        >
                           Features Count
                        </label>
                     </div>
                  </div>
                  {allFeatureEdit?.length > 0
                     ? allFeatureEdit?.map((item, index) => (
                          <div className="row  my-2 " key={index}>
                             <div className="col-4  d-flex align-items-center">
                                <label
                                   className="form-check-label fw-bold"
                                   htmlFor="flexCheckDefault"
                                >
                                   {item?.title}
                                </label>
                             </div>
                             <div className="col-4">
                                {item?.title == "Number of listings" ? (
                                   <select
                                      className="form-select  form-select-solid "
                                      aria-label="Default select example"
                                      onChange={(e) => onChangeValue(e)}
                                      // onKeyPress={(e) => onKeyPressAdd(e)}
                                      // defaultValue={status}
                                   >
                                      {/* <option selected hidden>
                                         Feature Type
                                      </option> */}

                                      <option value="Unlimited">
                                         Unlimited
                                      </option>
                                      {numberOfListings !== "" &&
                                      numberOfListings !== undefined ? (
                                         <option
                                            selected
                                            hidden
                                            value={numberOfListings}
                                         >
                                            {numberOfListings}
                                         </option>
                                      ) : (
                                         <option value="Custom">Custom</option>
                                      )}
                                      {numberOfListings == "" &&
                                         numberOfListings == undefined && (
                                            <option value="Custom">
                                               Custom
                                            </option>
                                         )}
                                   </select>
                                ) : (
                                   <input
                                      type="number"
                                      id={item?.id}
                                      name={item?.slug}
                                      className="form-control form-control-solid "
                                      onChange={(e) => FeatureChange(e)}
                                      defaultValue={item?.pivot?.value}
                                      //  value={numberOfListings}
                                      // onKeyPress={(e) => onKeyPressEdit(e)}
                                      // style={{
                                      //   marginTop: '3px',
                                      // }}
                                      placeholder="Number"
                                      required
                                   />
                                )}
                             </div>
                          </div>
                       ))
                     : null}
               </ModalBody>

               <ModalFooter className="text-center py-3">
                  <button
                     type="reset"
                     className="btn btn-secondary me-3"
                     onClick={() => {
                        setEditOpenModel(!editOpenModel);
                        clear();
                     }}
                  >
                     Close
                  </button>

                  <button
                     type="submit"
                     className="btn btn-primary"
                     onClick={editPackage}
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                        color: "white",
                     }}
                  >
                     {!loading && (
                        <span className="indicator-label">Update</span>
                     )}
                     {loading && (
                        <span
                           className="indicator-progress"
                           style={{ display: "block" }}
                        >
                           Please wait...
                           <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                     )}
                  </button>
               </ModalFooter>
            </Modal>
            {/*feature Type Model Start */}
         </div>
         {/* //////////////////////Side Model start/////////////// */}
         <div>
            {/* {modelControl ? ( */}
            <div className={`${style} shadow-sm px-5 pt-4 pb-5`}>
               <div className="d-flex justify-content-between align-items-center ">
                  <div></div>
                  <button
                     type="button"
                     className="btn-close"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     onClick={() => {
                        setMenuStatus("open");
                        setStyle("sideMenu");
                     }}
                  ></button>
               </div>

               <PerfectScrollbar
                  style={{ maxHeight: "70vh", overflowX: "hidden" }}
               >
                  {showData?.package_type?.name !== "" &&
                     showData?.package_type?.name !== undefined &&
                     showData?.package_type?.name !== null && (
                        <div className="row mt-3  border-1 border-bottom">
                           <div className="col-4">
                              <h6 className="modal-title">Title</h6>
                           </div>
                           <div className="col-8">
                              <p className="mb-1 text-start">
                                 {" "}
                                 {showData?.package_type?.name ?? "No title"}
                              </p>
                           </div>
                        </div>
                     )}

                  {showData?.description !== "" &&
                     showData?.description !== null &&
                     showData?.description !== undefined && (
                        <div className="row mt-3  border-1 border-bottom">
                           <div className="col-4">
                              <h6 className="modal-title">Description</h6>
                           </div>
                           <div className="col-8">
                              <p className="mb-1 text-start">
                                 {" "}
                                 {showData?.description ?? "No description"}
                              </p>
                           </div>
                        </div>
                     )}

                  <div className="row mt-3  border-1 border-bottom">
                     <div className="col-4">
                        <h6 className="modal-title">Status</h6>
                     </div>
                     <div className="col-8">
                        {showData?.status == "active" ? (
                           <p className={"mb-1 text-start badge bg-success"}>
                              {" "}
                              {showData?.status ?? " "}
                           </p>
                        ) : showData?.status == "Deactive" ? (
                           <p className="mb-1 text-start badge bg-danger">
                              {" "}
                              {showData?.status ?? " "}
                           </p>
                        ) : null}
                     </div>
                  </div>
                  {showData?.features?.length !== 0 && (
                     <div className="row mt-3  border-1 border-bottom">
                        <div className="col-12">
                           <h6 className="modal-title">Features</h6>
                        </div>
                        <div className="col-12">
                           {showData?.features?.map((item, index) => (
                              <>
                                 {item?.title !== "" &&
                                    item?.title !== undefined &&
                                    item?.title !== null &&
                                    item?.pivot?.value !== "" &&
                                    item?.pivot?.value !== undefined &&
                                    item?.pivot?.value !== null && (
                                       <div className="row">
                                          <div className="col-5 my-2">
                                             {item?.title}
                                          </div>
                                          <div className="col-7 my-2">
                                             {item?.pivot?.value}
                                          </div>
                                       </div>
                                    )}
                              </>
                           ))}
                        </div>
                     </div>
                  )}

                  {showData?.package_prices?.length !== 0 && (
                     <div className="row mt-3  border-1 border-bottom">
                        <div className="col-12">
                           <h6 className="modal-title">Package Price</h6>
                        </div>
                        <div className="col-12">
                           {showData?.package_prices?.map((item, index) => (
                              <>
                                 {item?.type !== "" &&
                                    item?.type !== undefined &&
                                    item?.type !== null &&
                                    item?.price !== "" &&
                                    item?.price !== undefined &&
                                    item?.price !== null}
                                 <div className="row">
                                    <div className="col-5 my-2">
                                       {item?.type}
                                    </div>
                                    <div className="col-7 my-2">
                                       ${item?.price}
                                    </div>
                                 </div>
                              </>
                           ))}
                        </div>
                     </div>
                  )}
               </PerfectScrollbar>
            </div>
            {/* ) : null} */}
         </div>
         <Modal
            size="md"
            isOpen={isFeatureTypeModal}
            centered={true}
            toggle={null}
         >
            <ModalHeader
               toggle={() => {
                  setIsFeatureTypeModal(!isFeatureTypeModal);
                  setFeatureType("unlimited");
               }}
            >
               <h5 className="modal-title">Features</h5>
            </ModalHeader>
            <ModalBody>
               <div className="row ">
                  <div className="mb-3 col-md-12 ">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label required"
                     >
                        Number of listings
                     </label>
                     <input
                        type="number"
                        id="userEmail"
                        className="form-control form-control-solid "
                        onChange={(e) => {
                           setNumberOfListings(e.target.value);
                           setNumberOfListingsError(false);
                        }}
                        value={numberOfListings}
                        placeholder="Number of listings"
                        required
                     />
                     {numberOfListingsError && (
                        <div className="d-flex myError">
                           <br />
                           <small className="fw-lighter">Required field</small>
                        </div>
                     )}
                  </div>
               </div>
            </ModalBody>

            <ModalFooter className="text-center py-1">
               {numberOfListings == "" || numberOfListings == undefined ? (
                  <button
                     type="reset"
                     className="btn btn-secondary me-3"
                     onClick={() => {
                        setFeatureType("Unlimited");
                        setIsFeatureTypeModal(!isFeatureTypeModal);
                        // setThrowEmailError(false)
                     }}
                  >
                     Close
                  </button>
               ) : (
                  <button
                     type="reset"
                     className="btn btn-secondary me-3"
                     onClick={() => {
                        setIsFeatureTypeModal(!isFeatureTypeModal);
                        // setThrowEmailError(false)
                        setFeatureType("Unlimited");
                     }}
                  >
                     Close
                  </button>
               )}

               {numberOfListings == "" || numberOfListings == undefined ? (
                  <button
                     type="submit"
                     className="btn btn-primary"
                     onClick={() => {
                        setNumberOfListingsError(true);
                        // setThrowEmailError(false)
                     }}
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                        color: "white",
                     }}
                  >
                     Ok
                  </button>
               ) : (
                  <button
                     type="submit"
                     className="btn btn-primary"
                     onClick={() => {
                        setIsFeatureTypeModal(!isFeatureTypeModal);
                        // setThrowEmailError(false)
                     }}
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                        color: "white",
                     }}
                  >
                     Ok
                  </button>
               )}
            </ModalFooter>
         </Modal>
      </>
   );
};

export default PackageManagement;
