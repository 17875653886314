import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";

function RatingSection(props) {
   const getDate = (date) => {
      if (date !== null && date !== undefined && date !== "") {
         return moment(date).format("DD/MM/YYYY HH:mm");
      } else {
         return "";
      }
   };
   const getTime = (timeString) => {
      if (
         timeString !== null &&
         timeString !== undefined &&
         timeString !== ""
      ) {
         return moment(timeString, ["h:mm A"]).format("HH:mm");
      } else {
         return "";
      }
   };
   return (
      <>
         <h6
            className="vety-business-detail-heading mb-0 mt-2 text-wrap"
            style={{
               color: "#3E94A6",
               fontWeight: 500,
            }}
         >
            {/* <i className="mdi mdi-rate-review me-3"></i> */}
            Reviews & Ratings
         </h6>
         {props.reviewData.map((item, index) =>
            item?.rating ? (
               <>
                  <div
                     className="col-12 mt-4 sliderScreen border shadow rounded bg-white pt-0  pb-2 px-0"
                     key={index}
                  >
                     <div
                        className="py-2"
                        style={{ backgroundColor: "#3e94a6" }}
                     >
                        <span className=" mx-4 text-white">
                           Amount:{" "}
                           <span className="ps-3">
                              ${" "}
                              {props?.price !== null &&
                              props?.price !== undefined
                                 ? props?.price
                                 : "No price"}
                           </span>{" "}
                        </span>
                        <span className=" mx-4 text-white">
                           Timing:{" "}
                           <span className="ps-3">
                              {" "}
                              From: {getTime(item?.checkin)}
                           </span>
                           <span className="ps-3">
                              {" "}
                              To: {getTime(item?.checkout)}
                           </span>
                        </span>
                     </div>

                     <div className="d-flex mt-2 pt-2 justify-content-between px-3">
                        <div className="d-flex gap-2">
                           {item?.rating?.user.profile_image === null ||
                           item?.rating?.user.profile_image === undefined ||
                           item?.rating?.user.profile_image?.length < 1 ? (
                              <img
                                 src="https://www.goarabic.com/vm/wp-content/uploads/2019/05/dummy-profile-pic.jpg"
                                 alt=""
                                 width={35}
                                 height={35}
                                 style={{ borderRadius: "50%" }}
                              />
                           ) : (
                              <img
                                 src={
                                    item?.rating?.user?.profile_image
                                       ?.folder_path +
                                    item?.rating?.user?.profile_image?.file_name
                                 }
                                 alt=""
                                 width={35}
                                 height={35}
                                 style={{ borderRadius: "50%" }}
                              />
                           )}

                           <div>
                              <h5 className="mb-0 pb-0">
                                 {item?.rating?.user.first_name +
                                    " " +
                                    item?.rating?.user.last_name}
                              </h5>
                              <p className="mb-1">{getDate(item.created_at)}</p>
                           </div>
                        </div>
                        <div className="">
                           <ReactStars
                              count={5}
                              // onChange={ratingChanged}
                              size={24}
                              isHalf={true}
                              edit={false}
                              value={item?.rating?.rate}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                           />
                        </div>
                     </div>
                     <div className="  text-start mx-0 px-3">
                        <p>{item?.rating?.comment}</p>
                     </div>
                  </div>
               </>
            ) : (
               <div className="fs-4 pt-4">No Reviews</div>
            )
         )}
      </>
   );
}

export default RatingSection;
