import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label,  } from 'reactstrap';
import Select from 'react-select';
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
 
// action
import { registerUser, registerUserFailed, apiError } from '../../store/actions';

// Redux
import { connect } from "react-redux";

// import images


const ProfessionOption = [
  {
    label: 'Professions',
    options: [
      { label: 'Software Developer', value: 'Software Developer' },
      {
        label: 'Computer Systems Analyst',
        value: 'Computer Systems Analyst',
      },
      { label: 'Actor', value: 'Actor' },
    ],
  },
];
const CountryOption = [
  {
    label: 'Countries',
    options: [
      { label: 'Pakistan', value: 'Pakistan' },
      {label: 'India',value: 'India', },
      { label: 'Algeria', value: 'Algeria' },
    ],
  },
];
const recipientOption = [
  {
 
    label: 'Who are they receiving or sending a charm from or to?',
    options: [
      { label: 'Friend', value: 'Friend' },
      { label: 'Family', value: 'Family' },
      { label: 'Manager', value: 'Manager' },
       { label: 'World citizen', value: 'World citizen' },
      { label: 'Workmate', value: 'Workmate' },
      { label: 'Manager', value: 'Manager' },
    ],
  },
];
const charmOption = [
  {
 
    label: 'Select Multiple options',
    options: [
      { label: 'Simple Charms', value: 'Simple Charms' },
      { label: 'Promotions', value: 'Promotions' },
      { label: 'Charm Vouchers', value: 'Charm Vouchers' },
       { label: 'Betting Charms', value: 'Betting Charms' },
      { label: 'Petitions', value: 'Petitions' },
      { label: 'Poll Research', value: 'Poll Research' },
       { label: 'Solicitation for Assistance', value: 'Solicitation for Assistance' },
      { label: 'Fund Pooling Campaigns', value: 'Fund Pooling Campaigns' },
      { label: 'Community Mobilization', value: 'Community Mobilization' },
     
    ],
  },
];
const communityOption = [
  {
    label: 'Would you like to be a community leader',
    options: [
      { label: 'Charmpion', value: 'Charmpion' },
      { label: 'Good samaritan', value: 'Good samaritan' },
      { label: 'Prophet', value: 'Prophet' },
      { label: 'Chancellor', value: 'Chancellor' },
    ],
  },
];



class IndividualRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      password: '',
      RepeatPassword: '',

      //   selectedGroup: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    this.props.registerUser(values);
  }

  componentDidMount() {
    this.props.registerUserFailed('');
    this.props.apiError('');
    document.body.classList.add('auth-body-bg');
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className='p-0'>
            <Row className='g-0'>
              <Col lg={6}>
                <div className=' lg-p-5 d-flex align-items-center'>
                  <div className='w-100'>
                    <Row className='justify-content-center'>
                      <Col lg={9}>
                        <div>
                          {this.props.user && (
                            <Alert color='success'>
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color='danger'>
                              {this.props.registrationError}
                            </Alert>
                          )}

                          <div className='p-2 mt-5'>
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className='form-horizontal'
                            >
                              <div className='auth-form-group-custom mb-4'>
                                <i className='ri-user-2-line auti-custom-input-icon'></i>
                                <Label htmlFor='username'>
                                  Please Enter Name
                                </Label>
                                <AvField
                                  name='username'
                                  value={this.state.username}
                                  type='text'
                                  className='form-control'
                                  id='username'
                                  placeholder='Enter Your Name'
                                />
                              </div>

                              <div className='auth-form-group-custom mb-4'>
                                <i className='ri-mail-line auti-custom-input-icon'></i>
                                <Label htmlFor='useremail'>Email</Label>
                                <AvField
                                  name='email'
                                  value={this.state.email}
                                  validate={{ email: true, required: true }}
                                  type='email'
                                  className='form-control'
                                  id='useremail1'
                                  placeholder='Enter email'
                                />
                              </div>
                              <div className='auth-form-group-custom mb-4'>
                                <i className='ri-lock-2-line auti-custom-input-icon'></i>
                                <Label htmlFor='usernumber'>
                                  Mobile number
                                </Label>
                                <AvField
                                  name='email'
                                  value={this.state.username}
                                  validate={{
                                    username: true,
                                    required: true,
                                  }}
                                  type='email'
                                  className='form-control'
                                  id='useremail2'
                                  placeholder='Enter Mobile number'
                                />
                              </div>
                              <div className='mb-3'>
                                <Label className='form-label'>
                                  Select Your Profession
                                </Label>
                                <Select
                                  //   value={selectedGroup}
                                  //   onChange={this.handleSelectGroup}
                                  options={ProfessionOption}
                                  classNamePrefix='select2-selection'
                                />
                              </div>
                              <div className='mb-3'>
                                <Label className='form-label'>
                                  Select Your Country
                                </Label>
                                <Select
                                  //   value={selectedGroup}
                                  //   onChange={this.handleSelectGroup}
                                  options={CountryOption}
                                  classNamePrefix='select2-selection'
                                />
                              </div>
                              <div className='mb-3'>
                                <Label className='form-label'>
                                  How would you like your charm recipients to
                                  know you as?
                                </Label>
                                <Select
                                  //   value={selectedGroup}
                                  //   onChange={this.handleSelectGroup}
                                  options={recipientOption}
                                  classNamePrefix='select2-selection'
                                />
                              </div>
                              <div className='mb-3'>
                                <Label className='form-label'>
                                  Please tick the charms you would like to be
                                  receiving
                                </Label>
                                <Select
                                  //   value={selectedMulti}
                                  isMulti={true}
                                  //   onChange={this.handleMulti}
                                  options={charmOption}
                                  classNamePrefix='select2-selection'
                                />
                              </div>
                              <div className='mb-3'>
                                <Label className='form-label'>
                                  Would you like to be a community leader
                                </Label>
                                <Select
                                  //   value={selectedGroup}
                                  //   onChange={this.handleSelectGroup}
                                  options={communityOption}
                                  classNamePrefix='select2-selection'
                                />
                              </div>

                              <div className='auth-form-group-custom mb-4'>
                                <i className='ri-lock-2-line auti-custom-input-icon'></i>
                                <Label htmlFor='userpassword'>Password</Label>
                                <AvField
                                  name='password'
                                  value={this.state.password}
                                  type='password'
                                  className='form-control'
                                  id='userpassword1'
                                  placeholder='Enter password'
                                />
                              </div>

                              <div className='auth-form-group-custom mb-4'>
                                <i className='ri-lock-2-line auti-custom-input-icon'></i>
                                <Label htmlFor='userpassword'>
                                  Repeat Password
                                </Label>
                                <AvField
                                  name='password'
                                  value={this.state.RepeatPassword}
                                  type='password'
                                  className='form-control'
                                  id='userpassword2'
                                  placeholder='Enter Repeat Password'
                                />
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className='authentication-bg h-100'>
                  <div className='bg-overlay'></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {

    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(IndividualRegister);
