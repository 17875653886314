import React, { Component, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Swal from "sweetalert2";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { useLocation, useHistory } from "react-router-dom";
import {
   VetyRegister,
   OTPverifying,
} from "../../components/Services/AuthServices";
const LoginOTP = () => {
   const [phone, setPhone] = useState();
   // this.state = { username: "admin@themesdesign.in", password: "123456" };

   const [contries, setCountries] = useState([]);
   const [btnLoader, setLoader] = useState(false);
   const [storeLocation, setStoreLocation] = useState("");
   const [redirect, setRedirect] = useState(false);
   const location = useLocation();
   const history = useHistory();
   const [otp, setOtp] = useState("");
   const checkAuth = async () => {
      let token = localStorage.getItem("Token");
      if (token) {
         setRedirect(false);
         history.push({
            pathname: "/users-list",
         });
      } else {
         setRedirect(true);
      }
   };

   useEffect(() => {
      checkAuth();
   });
   const onChangeHandler = (e) => {
      setOtp(e);
   };

   const LoginForm = async (e) => {
      setLoader(true);
      try {
         let number = localStorage.getItem("number");
         let phoneNumber = JSON.parse(number);
         const response = await OTPverifying(phoneNumber, otp);
         if (response.status === true) {
            localStorage.setItem("Token", JSON.stringify(response.token));
            history.push({
               pathname: "/users-list",
            });
         } else {
            Swal.fire({
               position: "top",
               icon: "warning",
               title: response.message,
               showConfirmButton: false,
               timer: 3000,
            });
            setLoader(false);
            history.push({
               pathname: "/login-otp",
            });
         }
      } catch (error) {
         setLoader(false);
      }
   };
   const handleSubmit = (values) => {};

   //   const very

   return (
      <React.Fragment>
         {redirect && (
            <div>
               <Container fluid className="p-0">
                  <Row className="g-0">
                     <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                           <div className="w-100">
                              <Row className="justify-content-center">
                                 <Col lg={9}>
                                    <div>
                                       <div className="text-center">
                                          <div>
                                             {/* <Link to="/" class=""> */}
                                             <img
                                                src={logodark}
                                                alt=""
                                                height="190"
                                                class="auth-logo logo-dark mx-auto"
                                             />
                                             <img
                                                src={logolight}
                                                alt=""
                                                height="70"
                                                class="auth-logo logo-light mx-auto"
                                             />
                                             {/* </Link> */}
                                          </div>
                                          {/* 
                                                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>                    
                                                        <p className="text-muted">Sign in to continue to Nazox.</p> */}
                                       </div>
                                       {/* {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">   
                              {this.props.loginError}
                            </Alert>                 
                          ) : null} */}

                                       <div className="p-2 mt-5">
                                          <AvForm
                                             className="form-horizontal text-center"
                                             onValidSubmit={handleSubmit}
                                          >
                                             {/* <div className="auth-form-group-custom"> */}
                                             {/* <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <AvField name="username" value={this.state.username} type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Enter phone Number" /> */}
                                             <Label
                                                htmlFor="phone"
                                                className="mb-4"
                                             >
                                                Enter OTP
                                             </Label>
                                             <div className="d-flex justify-content-center">
                                                <OtpInput
                                                   value={otp}
                                                   onChange={(e) =>
                                                      onChangeHandler(e)
                                                   }
                                                   numInputs={6}
                                                   separator={<span>-</span>}
                                                   inputStyle={{
                                                      width: "2rem",
                                                      height: "2rem",
                                                   }}
                                                />
                                             </div>
                                             {/* </div> */}

                                             {/* <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password</Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                            </div> */}

                                             {/* <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customControlInline">
                                  Remember me
                                </Label>
                              </div> */}

                                             {/* <div className="mt-4 text-center">
                              <Button color="primary" className="w-md waves-effect waves-light" type="submit" onClick={(e) => LoginForm(e)}>
                                Login
                              </Button>
                            </div> */}
                                             {btnLoader == false && (
                                                <div className="mt-4 text-center">
                                                   <button
                                                      style={{
                                                         background:
                                                            "linear-gradient(#3E94A6, #323F4B)",
                                                      }}
                                                      className="w-md btn btn-primary waves-effect waves-light"
                                                      type="submit"
                                                      onClick={(e) =>
                                                         LoginForm(e)
                                                      }
                                                   >
                                                      Submit
                                                   </button>
                                                </div>
                                             )}
                                             {btnLoader == true && (
                                                <div className="mt-4 text-center">
                                                   <button
                                                      style={{
                                                         background:
                                                            "linear-gradient(#3E94A6, #323F4B)",
                                                      }}
                                                      className="w-md btn btn-primary waves-effect waves-light"
                                                      type="submit"
                                                      onClick={(e) =>
                                                         LoginForm(e)
                                                      }
                                                      disabled
                                                   >
                                                      <div
                                                         class="spinner-border"
                                                         role="status"
                                                      >
                                                         <span class="sr-only"></span>
                                                      </div>
                                                   </button>
                                                </div>
                                             )}

                                             {/* <div className="mt-4 text-center">
                                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                            </div> */}
                                          </AvForm>
                                       </div>
                                       {/* <div className="mt-5 text-center">                                                        
                         <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p>   
                                                    </div> */}
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </div>
                     </Col>
                     <Col lg={8}>
                        <div className="authentication-bg">
                           <div className="bg-overlay"></div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         )}
      </React.Fragment>
   );
};
//   export default LoginOTP

const mapStatetoProps = (state) => {
   const { loginError } = state.Login;
   return { loginError };
};

export default withRouter(
   connect(mapStatetoProps, { checkLogin, apiError })(LoginOTP)
);
