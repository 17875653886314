import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";
import Video from "react-video-renderer";
import ReactPlayer from "react-player";
import { DocumentViewer } from "react-documents";
import {
   Button,
   Col,
   CardBody,
   Container,
   Dropdown,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   Form,
   Input,
   Nav,
   NavItem,
   NavLink,
   Row,
   TabContent,
   TabPane,
} from "reactstrap";

import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import user1 from "../../assets/images/users/avatar-1.jpg";

//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb";

import {
   addMessage,
   getChats,
   getContacts,
   getGroups,
   getMessages,
} from "../../store/actions";
import { getAllChats, getAllConversation } from "../Services/Chats/index";
import ProfileImg from "../../assets/images/profile/images.jpg";
import ReactAudioPlayer from "react-audio-player";

class Chat extends Component {
   constructor(props) {
      super(props);
      this.messageListRef = React.createRef();
      this.messagesEndRef = React.createRef();
      this.messageListRefs = {};
      this.messageListRef = React.createRef();

      this.state = {
         
         chatsArray: [],
         chats: [],
         senderID: "",
         receiverID: "",
         loading: false,
         activeChat: 0,
         msgLoader: false,
         selectedPlateName: "",
         coversationData: [],
         selectedChatId: null,
         currentRoomId: 1,
         currentUser: {
            name: "Henry Wells",
            isActive: true,
         },
         notification_Menu: false,
         search_Menu: false,
         settings_Menu: false,
         other_Menu: false,
         activeTab: "1",
         Chat_Box_Username: "Steven Franklin",
         Chat_Box_User_Status: "online",
         Chat_Box_User_isActive: false,
         curMessage: "",
         breadcrumbItems: [
            { title: "Chat", link: "/" },
            { title: "Chat", link: "#" },
         ],
         // page:1,
         chatListCurrentPage: 1,
         chatListLastPage: 2,
         conversationsCurrentPage: 1,
         conversationsLastPage: 2,
         selectedChatID:''

      };
      this.messageBox = null;
   }

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY hh:mm a");
   };
   handleClick(key) {
    
      this.setState({ activeChat: key });
      this.setState({ selectedChatId: key });
      
   }
   componentDidMount() {
      const { onGetChats, onGetGroups, onGetContacts, onGetMessages } =
         this.props;
      const { currentRoomId } = this.state;
      onGetChats();
      onGetGroups();
      onGetContacts();
      onGetMessages(currentRoomId);
      this.getAllChatsList(this.state.chatListCurrentPage);
         // Scroll to the bottom of the message list
      this.scrollToBottom();
   }

   async getAllChatsList(page) {

      try {
         let arrayEle = [];
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         if (page==1 || page <= this.state.chatListLastPage) {

            const chatsAPI = await getAllChats(accessToken, page);

            if (chatsAPI.status === true) {
               if(page == 1 ){
                  this.getAllConversations(
                     chatsAPI?.chats?.data[0]?.id,
                     chatsAPI?.chats?.data[0]?.user?.id,
                     chatsAPI?.chats?.data[0]?.plate?.user?.id,
                     chatsAPI?.chats?.data[0]?.plate?.plate,
                     1
                  );
               }
              
               this.setState({ loading: true });
               this.setState({
                  chatListCurrentPage: chatsAPI?.chats?.current_page,
                  chatListLastPage: chatsAPI?.chats?.last_page,
               })
               this.setState({ chatsArray: this.state.chatsArray.concat(chatsAPI?.chats?.data) });
            } 
         }
            

        
      } catch (err) {
         console.log("error", err);
      }
   }
   async getAllConversations(chatID, sID, rID, plateName,page) {

      this.setState({ selectedPlateName: plateName });
      this.setState({ senderID: sID });
      this.setState({ receiverID: rID });
      this.setState({ selectedChatID: chatID })

       try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         
          if (page == 1 || page <= this.state.conversationsLastPage) {
             this.setState({ msgLoader: false });
             const converAPI = await getAllConversation(accessToken, chatID, page);
             console.log('fayyyyyyaz',converAPI)

             if (converAPI.status === true) {
                this.setState({
                   conversationsCurrentPage: converAPI?.conversation?.current_page,
                   conversationsLastPage: converAPI?.conversation?.last_page,
                })
                this.setState({ msgLoader: true });
                
                this.setState({ coversationData: this.state.coversationData.concat(converAPI?.conversation.data) });
             }
          }
        
      } catch (err) { }
   }
   // eslint-disable-next-line no-unused-vars
   componentDidUpdate(prevProps, prevState, snapshot) {
      const { messages } = this.props;
      if (size(messages) !== size(prevProps.messages)) {
         this.scrollToBottom();
      }
      // Check if the ref is properly assigned
      if (prevState.conversationData?.length !== this.state?.conversationData?.length) {
         this.scrollToBottom();
      }
   }
 

   toggleNotification = () => {
      this.setState((prevState) => ({
         notification_Menu: !prevState.notification_Menu,
      }));
   };

   //Toggle Chat Box Menus
   toggleSearch = () => {
      this.setState((prevState) => ({
         search_Menu: !prevState.search_Menu,
      }));
   };

   toggleSettings = () => {
      this.setState((prevState) => ({
         settings_Menu: !prevState.settings_Menu,
      }));
   };

   toggleOther = () => {
      this.setState((prevState) => ({
         other_Menu: !prevState.other_Menu,
      }));
   };

   toggleTab = (tab) => {
      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab,
         });
      }
   };

   //Use For Chat Box
   userChatOpen = (id, name, status, roomId) => {
      const { onGetMessages } = this.props;
      this.setState({
         Chat_Box_Username: name,
         currentRoomId: roomId,
      });
      onGetMessages(roomId);
   };

   addMessage = (roomId, sender) => {
      const { onAddMessage } = this.props;
      const message = {
         id: Math.floor(Math.random() * 100),
         roomId,
         sender,
         message: this.state.curMessage,
         createdAt: new Date(),
      };
      this.setState({ curMessage: "" });
      onAddMessage(message);
   };

   scrollToBottom = () => {
      if (this.messageBox) {
         this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
      }
   };

   onKeyPress = (e) => {
      const { key, value } = e;

      const { currentRoomId, currentUser } = this.state;

      if (key === "Enter") {
         this.setState({ curMessage: value });
         this.addMessage(currentRoomId, currentUser.name);
      }
   };
   scrollToBottom = () => {
      if (this.messageListRef.current) {
         const messageListRef = this.messageListRef.current;
         messageListRef.scrollTop = messageListRef.scrollHeight;
      }
   }
   render() {
      const { chats, groups, contacts, messages } = this.props;

      const { currentRoomId, currentUser } = this.state;

      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                     title="Messages"
                     breadcrumbItems={this.state.breadcrumbItems}
                  />
                  {this.state.loading === true ? (
                     <div className="d-lg-flex mb-4">
                        <div className="chat-leftsidebar">
                           <CardBody className="border-bottom py-3 px-2">
                              <div className="search-box chat-search-box">
                                 <div className="position-relative">
                                    <h5>Chat List</h5>
                                 </div>
                              </div>
                           </CardBody>

                           <TabContent
                              activeTab={this.state.activeTab}
                              className="py-2"
                           >
                              <TabPane tabId="1">
                                 <div>
                                    {/* ----Chat List-------- */}
                                    <ul className="list-unstyled chat-list">
                                       <PerfectScrollbar
                                          style={{ maxHeight: "85vh" }}
                                          onYReachEnd={() => this.getAllChatsList(this.state.chatListCurrentPage + 1)}
                                       >
                                          {this.state.chatsArray.map(
                                             (chat, key) => {
                                                return (
                                                   <>
                                                      <li
                                                         key={key}
                                                         className={
                                                            chat.isActive
                                                               ? "active"
                                                               : ""
                                                         }
                                                         style={{
                                                            backgroundColor:
                                                               key ===
                                                                  this.state
                                                                     .activeChat
                                                                  ? "#eff2f7"
                                                                  : "",
                                                         }}
                                                      >
                                                         <Link
                                                            to="#"
                                                            onClick={() => {
                                                               this.handleClick(
                                                                  key
                                                               );
                                                               this.getAllConversations(
                                                                  chat?.id,
                                                                  chat?.user?.id,
                                                                  chat?.plate?.user?.id,
                                                                  chat.plate?.plate,
                                                                  1
                                                               );
                                                               this.setState({ conversationsCurrentPage: 1, coversationData: [] })

                                                            }}
                                                         >
                                                            <div className="d-flex">
                                                               <div
                                                                  className={
                                                                     chat.status ===
                                                                        "online"
                                                                        ? "user-img online align-self-center me-3"
                                                                        : "user-img away align-self-center me-3"
                                                                  }
                                                               >
                                                                  {chat?.user
                                                                     ?.profile_image ? (
                                                                     <img
                                                                        src={`${chat
                                                                              ?.user
                                                                              ?.profile_image
                                                                              ?.folder_path +
                                                                           "thumb/" +
                                                                           chat
                                                                              ?.user
                                                                              ?.profile_image
                                                                              ?.file_name
                                                                           }`}
                                                                        className="rounded-circle avatar-xs"
                                                                        alt=""
                                                                     />
                                                                  ) : (
                                                                     <img
                                                                        src={
                                                                           ProfileImg
                                                                        }
                                                                        className="rounded-circle avatar-xs"
                                                                     />
                                                                  )}
                                                                  {chat?.plate
                                                                     ?.user
                                                                     ?.profile_image ? (
                                                                     <img
                                                                        src={`${chat
                                                                              ?.plate
                                                                              ?.user
                                                                              ?.profile_image
                                                                              ?.folder_path +
                                                                           "thumb/" +
                                                                           chat
                                                                              ?.plate
                                                                              ?.user
                                                                              ?.profile_image
                                                                              ?.file_name
                                                                           }`}
                                                                        className="rounded-circle avatar-xs"
                                                                        alt=""
                                                                     />
                                                                  ) : (
                                                                     <img
                                                                        src={
                                                                           ProfileImg
                                                                        }
                                                                        className="rounded-circle avatar-xs"
                                                                     />
                                                                  )}

                                                                  <span className="user-status"></span>
                                                               </div>

                                                               <div className="flex-1 overflow-hidden">
                                                                  <h5 className="text-truncate font-size-14 mb-1">
                                                                     {
                                                                        chat
                                                                           .plate
                                                                           ?.plate
                                                                     }
                                                                  </h5>

                                                                  <p className="text-truncate mb-0 d-flex">
                                                                     {chat?.user
                                                                        ?.first_name ? (
                                                                        <p>
                                                                           {" "}
                                                                           {`${chat?.user?.first_name} / `}
                                                                        </p>
                                                                     ) : (
                                                                        <p className="">
                                                                           {`Anonymous /`}{" "}
                                                                        </p>
                                                                     )}
                                                                     {chat.plate
                                                                        ?.user
                                                                        ?.first_name ? (
                                                                        <p className="ms-1">
                                                                           {" "}
                                                                           {` ${chat.plate?.user?.first_name}`}
                                                                        </p>
                                                                     ) : (
                                                                        <p className="ms-1">{` Anonymous`}</p>
                                                                     )}

                                                                     {/* {chat.initiate_message} */}
                                                                  </p>
                                                               </div>
                                                               <div className="font-size-11">
                                                                  {this.getDate(
                                                                     chat.updated_at
                                                                  )}
                                                               </div>
                                                            </div>
                                                         </Link>
                                                      </li>
                                                   </>
                                                );
                                             }
                                          )}
                                       </PerfectScrollbar>
                                    </ul>
                                 </div>
                              </TabPane>

                           </TabContent>
                        </div>
                        <div className="w-100 user-chat mt-4 mt-sm-0">
                           <div className="p-3 px-lg-4">
                              <Row>
                                 <h4>{this.state.selectedPlateName} </h4>

                              </Row>
                           </div>
                           {/* All Conversations */}
                           <div className="px-lg-2">
                              <div className="chat-conversation p-3 chat-conversation-height">
                                 <ul className="list-unstyled mb-0 pe-3" ref={this.messageListRef} >
                                    <PerfectScrollbar
                                       style={{ maxHeight: "450px", bottom:0  }}
                                       onScrollUp={() => this.getAllConversations(this.state.selectedChatID, this.state.senderID, this.state.receiverID, this.state.selectedPlateName, this.state.conversationsCurrentPage+1)}
                                       // containerRef={ref => {
                                       //    ref.scrollToBottom=0
                                       // }}
                                    >
                                       {this.state.msgLoader === true ? (
                                          <>
                                             {this.state.coversationData?.map((message) => (
                                                   <>
                                                      {this.state.senderID ==
                                                         message.sender.id ? (
                                                         <li
                                                            style={{
                                                               paddingRight:
                                                                  "20px",
                                                            }}
                                                         >
                                                            <div className="conversation-list">
                                                               <div className="chat-avatar">
                                                                  {message
                                                                     ?.sender
                                                                     ?.profile_image ? (
                                                                     <img
                                                                        src={`${message
                                                                              ?.sender
                                                                              ?.profile_image
                                                                              ?.folder_path +
                                                                           "thumb/" +
                                                                           message
                                                                              ?.sender
                                                                              ?.profile_image
                                                                              ?.file_name
                                                                           }`}
                                                                        className="rounded-circle avatar-xs"
                                                                        alt=""
                                                                     />
                                                                  ) : (
                                                                     <img
                                                                        src={
                                                                           ProfileImg
                                                                        }
                                                                        className="rounded-circle avatar-xs"
                                                                     />
                                                                  )}
                                                               </div>

                                                               <div className="ctext-wrap">
                                                                  <div className="conversation-name">
                                                                     {
                                                                        message
                                                                           .sender
                                                                           ?.first_name
                                                                     }
                                                                  </div>
                                                                  {(message?.conversation_type ==
                                                                     "text") |
                                                                     (message?.conversation_type ==
                                                                        "contact") ? (
                                                                     <div className="ctext-wrap-content">
                                                                        <p className="mb-0">
                                                                           {
                                                                              message.message
                                                                           }
                                                                        </p>
                                                                     </div>
                                                                  ) : message.conversation_map !==
                                                                     null ? (
                                                                     <iframe
                                                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDcLGFU2zAn2rITMgtWVTkiGnuOPnqmtCU&q=${37.785834},${-122.406417}`}
                                                                        className="w-100 "
                                                                        allowfullscreen=""
                                                                        loading="lazy"
                                                                        referrerpolicy="no-referrer-when-downgrade"
                                                                     ></iframe>
                                                                  ) : message
                                                                     ?.conversation_media
                                                                     ?.length >
                                                                     0 ? (
                                                                     message?.conversation_type ==
                                                                        "image" ? (
                                                                        <img
                                                                           src={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           width={
                                                                              100
                                                                           }
                                                                           height={
                                                                              100
                                                                           }
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "video" ? (
                                                                        <ReactPlayer
                                                                           width="200px"
                                                                           height="200px"
                                                                           url={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           playing={
                                                                              false
                                                                           }
                                                                           controls={
                                                                              true
                                                                           }
                                                                           loop={
                                                                              false
                                                                           }
                                                                           muted={
                                                                              false
                                                                           }
                                                                           playsinline={
                                                                              true
                                                                           }
                                                                        // onReady={onLoadedData}
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "audio" ? (
                                                                        <ReactAudioPlayer
                                                                           src={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           // autoPlay
                                                                           controls
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "document" ? (
                                                                        <DocumentViewer
                                                                           queryParams="hl=EN"
                                                                           url={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                        // viewerUrl={message?.conversation_media[0]?.path}
                                                                        // viewer={selectedViewer.name}
                                                                        // overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                                                                        ></DocumentViewer>
                                                                     ) : null
                                                                  ) : null}

                                                                  <p className="chat-time mb-0">
                                                                     {this.getDate(
                                                                        message.updated_at
                                                                     )}
                                                                    
                                                                  </p>
                                                               </div>
                                                            </div>
                                                         </li>
                                                      ) : this.state
                                                         .receiverID ===
                                                         message.sender.id ? (
                                                         <li className="text-end">
                                                            <div className="conversation-list">
                                                               <div className="chat-avatar">
                                                                  {message
                                                                     ?.sender
                                                                     ?.profile_image ? (
                                                                     <img
                                                                        src={`${message
                                                                              ?.sender
                                                                              ?.profile_image
                                                                              ?.folder_path +
                                                                           "thumb/" +
                                                                           message
                                                                              ?.sender
                                                                              ?.profile_image
                                                                              ?.file_name
                                                                           }`}
                                                                        className="rounded-circle avatar-xs"
                                                                        alt=""
                                                                     />
                                                                  ) : (
                                                                     <img
                                                                        src={
                                                                           ProfileImg
                                                                        }
                                                                        className="rounded-circle avatar-xs"
                                                                     />
                                                                  )}
                                                               </div>

                                                               <div className="ctext-wrap text-start">
                                                                  <div className="conversation-name">
                                                                     {
                                                                        message
                                                                           .sender
                                                                           .first_name
                                                                     }
                                                                  </div>

                                                                  {(message?.conversation_type ==
                                                                     "text") |
                                                                     (message?.conversation_type ==
                                                                        "contact") ? (
                                                                     <div className="ctext-wrap-content">
                                                                        <p className="mb-0">
                                                                           {
                                                                              message.message
                                                                           }
                                                                        </p>
                                                                     </div>
                                                                  ) : message.conversation_map !==
                                                                     null ? (
                                                                     <iframe
                                                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDcLGFU2zAn2rITMgtWVTkiGnuOPnqmtCU&q=${37.785834},${-122.406417}`}
                                                                        className="w-100 "
                                                                        allowfullscreen=""
                                                                        loading="lazy"
                                                                        referrerpolicy="no-referrer-when-downgrade"
                                                                     ></iframe>
                                                                  ) : message
                                                                     ?.conversation_media
                                                                     ?.length >
                                                                     0 ? (
                                                                     message?.conversation_type ==
                                                                        "image" ? (
                                                                        <img
                                                                           src={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           width={
                                                                              100
                                                                           }
                                                                           height={
                                                                              100
                                                                           }
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "video" ? (
                                                                        <ReactPlayer
                                                                           width="200px"
                                                                           height="200px"
                                                                           url={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           playing={
                                                                              false
                                                                           }
                                                                           controls={
                                                                              true
                                                                           }
                                                                           loop={
                                                                              false
                                                                           }
                                                                           muted={
                                                                              false
                                                                           }
                                                                           playsinline={
                                                                              true
                                                                           }
                                                                        // onReady={onLoadedData}
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "audio" ? (
                                                                        <ReactAudioPlayer
                                                                           src={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                           // autoPlay
                                                                           controls
                                                                        />
                                                                     ) : message?.conversation_type ==
                                                                        "document" ? (
                                                                        <DocumentViewer
                                                                           queryParams="hl=EN"
                                                                           url={
                                                                              message
                                                                                 ?.conversation_media[0]
                                                                                 ?.path
                                                                           }
                                                                        // viewerUrl={message?.conversation_media[0]?.path}
                                                                        // viewer={selectedViewer.name}
                                                                        // overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                                                                        ></DocumentViewer>
                                                                     ) : null
                                                                  ) : null}

                                                                  <p className="chat-time mb-0">
                                                                        {this.getDate(
                                                                           message.updated_at
                                                                        )}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                         </li>
                                                      ) : (
                                                         <div
                                                            className="text-center d-flex align-items-center justify-content-center text-bold"
                                                            style={{
                                                               height: "50vh",
                                                            }}
                                                         >
                                                            <div
                                                               class="spinner-border text-enter"
                                                               role="status"
                                                            >
                                                               <span class="visually-hidden"></span>
                                                            </div>
                                                         </div>
                                                      )}

                                                      {/* {this.state.receiverID === message.sender.id} */}
                                                   </>
                                                )
                                             )}
                                          </>
                                       ) : (
                                          <div
                                             className="text-center d-flex align-items-center justify-content-center text-bold"
                                             style={{
                                                height: "50vh",
                                             }}
                                          >
                                             <div
                                                class="spinner-border text-enter"
                                                role="status"
                                             >
                                                <span class="visually-hidden"></span>
                                             </div>
                                          </div>
                                       )}
                                    </PerfectScrollbar>
                                       <div ref={this.messagesEndRef} />

                                 </ul>
                              </div>
                           </div>

                           <div className="px-lg-3">
                              <div className="p-3 chat-input-section"></div>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{
                           height: "50vh",
                        }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
            </div>
         </React.Fragment>
      );
   }
}

Chat.propTypes = {
   chats: PropTypes.array,
   groups: PropTypes.array,
   contacts: PropTypes.array,
   messages: PropTypes.array,
   onGetChats: PropTypes.func,
   onGetGroups: PropTypes.func,
   onGetContacts: PropTypes.func,
   onGetMessages: PropTypes.func,
   onAddMessage: PropTypes.func,
};

const mapStateToProps = ({ chat }) => ({
   chats: chat.chats,
   groups: chat.groups,
   contacts: chat.contacts,
   messages: chat.messages,
});

const mapDispatchToProps = (dispatch) => ({
   onGetChats: () => dispatch(getChats()),
   onGetGroups: () => dispatch(getGroups()),
   onGetContacts: () => dispatch(getContacts()),
   onGetMessages: (roomId) => dispatch(getMessages(roomId)),
   onAddMessage: (roomId) => dispatch(addMessage(roomId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
