import { BaseURL } from "../BaseURL";
export const getPaymentFeedback = async (accessToken, page) => {
    const response = await fetch(`${BaseURL}/admin/all-feed-backs?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + "  " + accessToken,
            "Access-Control-Allow-Origin": "*",
        },
    });
    const result = await response.json();
    return result;
};