import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
   changeLayout,
   changeLayoutWidth,
   changeSidebarTheme,
   changeSidebarType,
   changePreloader,
} from "../../store/actions";
import userIcon from "../../assets/icons/all-icon/userIcon.svg";
import managePlates from "../../assets/icons/all-icon/managePlates.svg";
import dealIcon from "../../assets/icons/all-icon/dealIcon.svg";
import invoicesIcon from "../../assets/icons/all-icon/invoicesIcon.svg";
import billingDetails from "../../assets/icons/all-icon/billingDetails.svg";
import packageIcon from "../../assets/icons/all-icon/packageIcon.svg";
import parkingIcon from "../../assets/icons/all-icon/parkingIcon.svg";
import reportedUser from "../../assets/icons/all-icon/reportedUser.svg";
import userList from "../../assets/icons/all-icon/userList.svg";
import vehiclesMake from "../../assets/icons/all-icon/vehiclesMake.svg";
import vehicelModel from "../../assets/icons/all-icon/vehicelModel.svg";
import reportReason from "../../assets/icons/all-icon/reportReason.svg";
import vehicleColor from "../../assets/icons/all-icon/vehicleColor.svg";
import parkingReport from "../../assets/icons/all-icon/parkingReport.svg";
import quickNote from "../../assets/icons/all-icon/quickNote.svg";
import manageCoupon from "../../assets/icons/all-icon/manageCoupon.svg";
import parkingFeature from "../../assets/icons/all-icon/parkingFeature.svg";
import manageParking from "../../assets/icons/all-icon/manageParking.svg";
import parkingSpot from "../../assets/icons/all-icon/parkingSpot.svg";
import userIconactive from "../../assets/icons/all-icon/userIconactive.svg";
import reportedUserActive from "../../assets/icons/all-icon/reportedUserActive.svg";
import reportedPlatesActive from "../../assets/icons/all-icon/reportedPlatesActive.svg";
import managePayment from "../../assets/icons/all-icon/managePayment.svg";
import managePlateActive from "../../assets/icons/all-icon/managePlateActive.svg";
import activeDeal from "../../assets/icons/all-icon/activeDeal.svg";
import plateListActive from "../../assets/icons/all-icon/plateListActive.svg";
import plateList from "../../assets/icons/all-icon/plateList.svg";
import invoicesActive from "../../assets/icons/all-icon/invoicesActive.svg";
import billingDetailActive from "../../assets/icons/all-icon/billingDetailActive.svg";
import activePackageIcon from "../../assets/icons/all-icon/activePackageIcon.svg";
import parkingActive from "../../assets/icons/all-icon/parkingActive.svg";
import activeVehicleType from "../../assets/icons/all-icon/activeVehicleType.svg";
import activeParkingFeature from "../../assets/icons/all-icon/activeParkingFeature.svg";
import manageParkingActive from "../../assets/icons/all-icon/manageParkingActive.svg";
import reportedParkingActive from "../../assets/icons/all-icon/reportedParkingActive.svg";
import activeVehicleSpot from "../../assets/icons/all-icon/activeVehicleSpot.svg";
import activeVehicleMake from "../../assets/icons/all-icon/activeVehicleMake.svg";
import activeVehicleColor from "../../assets/icons/all-icon/activeVehicleColor.svg";
import activePayment from "../../assets/icons/all-icon/activePayment.svg";
import manageCouponActive from "../../assets/icons/all-icon/manageCouponActive.svg";
import quickNoteActive from "../../assets/icons/all-icon/quickNoteActive.svg";
import settingActiveIcon from "../../assets/icons/all-icon/settingActiveIcon.svg";
import settingIcon from "../../assets/icons/all-icon/settingIcon.svg";
import userListActive from "../../assets/icons/all-icon/userListActive.svg";

class SidebarContent extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showActiveMenu: "",
      };
   }

   componentDidMount() {
      this.initMenu();
   }

   componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
         if (this.props.type !== prevProps.type) {
            this.initMenu();
         }
      }
   }

   initMenu() {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
         if (this.props.location.pathname === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
         }
      }
      if (matchingMenuItem) {
         this.activateParentDropdown(matchingMenuItem);
      }
   }

   activateParentDropdown = (item) => {
      item.classList.add("active");
      const parent = item.parentElement;

      if (parent) {
         parent.classList.add("mm-active");
         const parent2 = parent.parentElement;

         if (parent2) {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;

            if (parent3) {
               parent3.classList.add("mm-active"); // li
               parent3.childNodes[0].classList.add("mm-active"); //a
               const parent4 = parent3.parentElement;
               if (parent4) {
                  parent4.classList.add("mm-active");
               }
            }
         }
         return false;
      }
      return false;
   };

   render() {
      // const { location, history } = this.props;
      console.log("location", this.props.location.pathname);
      return (
         <React.Fragment>
            <div id="sidebar-menu"  >
               <ul className="metismenu list-unstyled" id="side-menu">
                  <li>
                     <Link
                        to="/#"
                        className="has-arrow waves-effect ps-3"
                        onClick={() =>
                           this.setState({ showActiveMenu: "Manage Users" })
                        }
                     >
                        {/* <i class="ri-contacts-fill"></i> */}
                        {this.props.location.pathname == "/users-list" ||
                        this.props.location.pathname == "/reported-users" ||
                        this.state.showActiveMenu == "Manage Users" ? (
                           <img src={userIconactive} alt="" width={30} />
                        ) : (
                           <img src={userIcon} alt="" width={30} />
                        )}

                        <span className="ms-1">
                           {this.props.t("Manage Users")}
                        </span>
                     </Link>
                     <ul className="sub-menu">
                        {/* <li><Link to="/ecommerce-products">{this.props.t('Products')}</Link></li> */}
                        <li>
                           <Link to="/users-list">
                              {/* <i class="mdi mdi-account-plus"></i> */}
                              {this.props.location.pathname == "/users-list" ? (
                                 <img
                                    src={userListActive}
                                    alt=""
                                    width={21}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={userList}
                                    alt=""
                                    width={21}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Users List")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/reported-users">
                              {this.props.location.pathname ==
                              "/reported-users" ? (
                                 <img
                                    src={reportedUserActive}
                                    alt=""
                                    width={22}
                                 />
                              ) : (
                                 <img src={reportedUser} alt="" width={22} />
                              )}

                              {/* <i class="mdi mdi-content-duplicate"></i> */}
                              <span className="ms-1">
                                 {this.props.t("Reported Users")}
                              </span>
                           </Link>
                        </li>
                        {/* <li><Link to="/deleted-users"><i class="mdi mdi-account-plus"></i><span className="ms-1">{this.props.t('Trash Users')}</span></Link></li>  */}
                        {/* <li><Link to="/ecommerce-product-detail/1">{this.props.t('Product Detail')}</Link></li> */}
                        {/* <li><Link to="/ecommerce-orders">{this.props.t('Orders')}</Link></li> */}

                        {/* <li><Link to="/ecommerce-cart">{this.props.t('Cart')}</Link></li>
                                <li><Link to="/ecommerce-checkout">{this.props.t('Checkout')}</Link></li>
                                <li><Link to="/ecommerce-shops">{this.props.t('Shops')}</Link></li>
                                <li><Link to="/ecommerce-add-product">{this.props.t('Add Product')}</Link></li> */}
                     </ul>
                  </li>

                  <li>
                     <Link to="/chat" className="waves-effect">
                        <i class="ri-discuss-fill"></i>
                        <span className="ms-1">
                           {this.props.t("Messages Center")}
                        </span>
                     </Link>
                     {/* <ul className="sub-menu">
                            <li>
                            <Link to="/chat" className=" waves-effect">
                                <i className="ri-chat-1-line"></i>
                                <span className="ms-1">{this.props.t('Chat')}</span>
                            </Link>
                        </li> */}
                     {/* <li><Link to="/ecommerce-products">{this.props.t('Products')}</Link></li>
                                <li><Link to="/ecommerce-product-detail/1">{this.props.t('Product Detail')}</Link></li>
                                <li><Link to="/ecommerce-orders">{this.props.t('Orders')}</Link></li>
                                <li><Link to="/ecommerce-customers">{this.props.t('Customers')}</Link></li>
                                <li><Link to="/ecommerce-cart">{this.props.t('Cart')}</Link></li>
                                <li><Link to="/ecommerce-checkout">{this.props.t('Checkout')}</Link></li>
                                <li><Link to="/ecommerce-shops">{this.props.t('Shops')}</Link></li>
                                <li><Link to="/ecommerce-add-product">{this.props.t('Add Product')}</Link></li> */}
                     {/* </ul> */}
                  </li>

                  <li>
                     <Link
                        to="/#"
                        className="has-arrow waves-effect"
                        onClick={() =>
                           this.setState({ showActiveMenu: "Manage Plates" })
                        }
                     >
                        {/* <i class="mdi mdi-car-info"></i> */}
                        {this.props.location.pathname == "/plates-list" ||
                        this.props.location.pathname == "/reported-plates" ||
                        this.state.showActiveMenu == "Manage Plates" ? (
                           <img src={managePlateActive} alt="" width={22} />
                        ) : (
                           <img src={managePlates} alt="" width={22} />
                        )}

                        <span className="ms-1">
                           {this.props.t("Manage Plates")}
                        </span>
                     </Link>
                     <ul className="sub-menu">
                        <li>
                           <Link to="/plates-list">
                              {this.props.location.pathname ==
                              "/plates-list" ? (
                                 <img
                                    src={plateListActive}
                                    alt=""
                                    width={18}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={plateList}
                                    alt=""
                                    width={18}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Plate List")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/reported-plates">
                              {this.props.location.pathname ==
                              "/reported-plates" ? (
                                 <img
                                    src={reportedPlatesActive}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={reportReason}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Reported Plates")}
                              </span>
                           </Link>
                        </li>
                        {/* <li>
                  <Link to="/Trash-plates">{this.props.t("Trash Plates")}</Link>
                </li> */}
                        {/* <li><Link to="/email-inbox">{this.props.t('Inbox')}</Link></li>
                                <li><Link to="/email-read">{this.props.t('Read Email')}</Link></li> */}
                     </ul>
                  </li>

                  <li>
                     <Link to="/deals" className="waves-effect">
                        {/* <i class="fas fa-handshake-slash fs-6"></i> */}
                        {this.props.location.pathname == "/deals" ? (
                           <img src={activeDeal} alt="" width={26} />
                        ) : (
                           <img src={dealIcon} alt="" width={26} />
                        )}

                        <span className="ms-1">{this.props.t("Deals")}</span>
                     </Link>
                  </li>

                  <li>
                     <Link to="/social-groups" className="waves-effect">
                        {/* <i class="fa-thin fa-alien-8bit"></i> */}
                        <i class="mdi mdi-account-multiple"></i>
                        <span className="ms-1">
                           {this.props.t("Social Groups")}
                        </span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/invoices" className="waves-effect">
                        {/* <i class="mdi mdi-credit-card-multiple fs-6"></i> */}
                        {this.props.location.pathname == "/invoices" ? (
                           <img src={invoicesActive} alt="" width={19} />
                        ) : (
                           <img src={invoicesIcon} alt="" width={19} />
                        )}

                        <span className="ms-1">{this.props.t("Invoices")}</span>
                     </Link>
                  </li>
                  {/* <li>
              <Link to="/payment-details" className="waves-effect">
                <i class="mdi mdi-format-strikethrough-variant fs-6"></i>
                <span className="ms-1">{this.props.t("Payment Detail")}</span>
              </Link>
            </li> */}
                  <li>
                     <Link to="/billing-details" className="waves-effect">
                        {/* <i class="mdi mdi-credit-card-multiple fs-6"></i> */}
                        {this.props.location.pathname == "/billing-details" ? (
                           <img src={billingDetailActive} alt="" width={19} />
                        ) : (
                           <img src={billingDetails} alt="" width={19} />
                        )}

                        <span className="ms-1">
                           {this.props.t("Billing Details")}
                        </span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/package-management" className="waves-effect">
                        {/* <i class="mdi mdi-package fs-6"></i> */}
                        {this.props.location.pathname ==
                        "/package-management" ? (
                           <img src={activePackageIcon} alt="" width={24} />
                        ) : (
                           <img src={packageIcon} alt="" width={24} />
                        )}

                        <span className="ms-1">
                           {this.props.t("Package Management")}
                        </span>
                     </Link>
                  </li>

                  <li>
                     <Link
                        to="/#"
                        className="has-arrow waves-effect"
                        onClick={() =>
                           this.setState({ showActiveMenu: "Settings" })
                        }
                     >
                        {this.props.location.pathname == "/reasons" ||
                        this.props.location.pathname == "/vehicles" ||
                        this.props.location.pathname == "/vehicle-models" ||
                        this.props.location.pathname == "/vehicle-colors" ||
                        this.props.location.pathname == "/quick-notes" ||
                        this.props.location.pathname ==
                           "/manage-payment-methods" ||
                        this.props.location.pathname == "/manage-coupon-code" ||
                        this.state.showActiveMenu == "Settings" ? (
                           <img
                              src={settingActiveIcon}
                              alt=""
                              width={22}
                              className="pb-1"
                           />
                        ) : (
                           <img
                              src={settingIcon}
                              alt=""
                              width={22}
                              className="pb-1"
                           />
                        )}
                        <span className="ms-1">{this.props.t("Settings")}</span>
                     </Link>
                     <ul className="sub-menu">
                        <li>
                           <Link to="/reasons" className="waves-effect">
                              {/* <i class="mdi mdi-language-r fs-6"></i> */}
                              {this.props.location.pathname == "/reasons" ? (
                                 <img
                                    src={reportedPlatesActive}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={reportReason}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Report Reasons")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/vehicles">
                              {/* <i class="mdi mdi-car-back"></i> */}
                              {this.props.location.pathname == "/vehicles" ? (
                                 <img
                                    src={activeVehicleMake}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={vehiclesMake}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Vehicles Make")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/vehicle-models">
                              {this.props.location.pathname ==
                              "/vehicle-models" ? (
                                 <img
                                    src={activeVehicleType}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={vehicelModel}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Vehicle Models")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/vehicle-colors">
                              {this.props.location.pathname ==
                              "/vehicle-colors" ? (
                                 <img
                                    src={activeVehicleColor}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={vehicleColor}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Vehicle Colors")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/quick-notes" className="waves-effect">
                              {this.props.location.pathname ==
                              "/quick-notes" ? (
                                 <img
                                    src={quickNoteActive}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={quickNote}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Quick Notes")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link
                              to="/manage-payment-methods"
                              className="waves-effect"
                           >
                              {this.props.location.pathname ==
                              "/manage-payment-methods" ? (
                                 <img
                                    src={activePayment}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={managePayment}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              )
                              }

                              <span className="ms-1">
                                 {this.props.t("Manage Payments")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link
                              to="/manage-coupon-code"
                              className="waves-effect"
                           >
                              {this.props.location.pathname ==
                              "/manage-coupon-code" ? (
                                 <img
                                    src={manageCouponActive}
                                    alt=""
                                    width={22}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={manageCoupon}
                                    alt=""
                                    width={25}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Manage Coupon")}
                              </span>
                           </Link>
                        </li>
                     </ul>
                  </li>
                  {/* //////////////Parking */}
                  <li
                     className={`${
                        this.props.location.pathname
                           .toString()
                           .startsWith("/parking/")
                           ? "mm-active"
                           : ""
                     }`}
                  >
                     <Link
                        to="/#"
                        className="has-arrow waves-effect"
                        onClick={() =>
                           this.setState({ showActiveMenu: "Parkings" })
                        }
                     >
                        {/* <i class="mdi mdi-van-passenger"></i> */}
                        {this.props.location.pathname == "/parking-features" ||
                        this.props.location.pathname == "/vehicle-types" ||
                        this.props.location.pathname
                           .toString()
                           .startsWith("/parking/") ||
                        this.props.location.pathname == "/vehicle-spots" ||
                        this.props.location.pathname == "/parking-reports" ||
                        this.props.location.pathname == "/parkings" ||
                        this.state.showActiveMenu == "Parkings" ? (
                           <img src={parkingActive} alt="" width={20} />
                        ) : (
                           <img src={parkingIcon} alt="" width={20} />
                        )}

                        <span
                           className="ms-1"
                           style={{
                              color: this.props.location.pathname
                                 .toString()
                                 .startsWith("/parking/")
                                 ? "#3e94a6"
                                 : "",
                           }}
                        >
                           {this.props.t("Parkings")}
                        </span>
                     </Link>
                     <ul className={`sub-menu `}>
                        <li>
                           <Link
                              to="/parking-features"
                              className="waves-effect"
                           >
                              {this.props.location.pathname ==
                              "/parking-features" ? (
                                 <img
                                    src={activeParkingFeature}
                                    alt=""
                                    width={18}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={parkingFeature}
                                    alt=""
                                    width={18}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Parking Features")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/vehicle-types">
                              {this.props.location.pathname ==
                              "/vehicle-types" ? (
                                 <img
                                    src={activeVehicleType}
                                    alt=""
                                    width={20}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={vehicelModel}
                                    alt=""
                                    width={20}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Vehicle Types")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/vehicle-spots">
                              {this.props.location.pathname ==
                              "/vehicle-spots" ? (
                                 <img
                                    src={activeVehicleSpot}
                                    alt=""
                                    width={20}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={parkingSpot}
                                    alt=""
                                    width={20}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Vehicle Spots")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/parking-reports">
                              {this.props.location.pathname ==
                              "/parking-reports" ? (
                                 <img
                                    src={reportedParkingActive}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={parkingReport}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              )}

                              <span className="ms-1">
                                 {this.props.t("Reported Parkings")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/parkings" className="waves-effect">
                              {this.props.location.pathname == "/parkings" ||
                              this.props.location.pathname
                                 .toString()
                                 .startsWith("/parking/") ? (
                                 <img
                                    src={manageParkingActive}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={manageParking}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              )}

                              <span
                                 className="ms-1 "
                                 style={{
                                    color: this.props.location.pathname
                                       .toString()
                                       .startsWith("/parking/")
                                       ? "#3e94a6"
                                       : "",
                                 }}
                              >
                                 {this.props.t("Manage Parking")}
                              </span>
                           </Link>
                        </li>
                        <li>
                           <Link to="/payments-feedbacks" className="waves-effect">
                              {this.props.location.pathname == "/payments-feedbacks" ||
                                 this.props.location.pathname
                                    .toString()
                                    .startsWith("/payments-feedbacks/") ? (
                                 <img
                                    src={activePayment}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) : (
                                 <img
                                    src={managePayment}
                                    alt=""
                                    width={17}
                                    className="pb-1"
                                 />
                              ) }

                              <span
                                 className="ms-1 "
                                 style={{
                                    color: this.props.location.pathname
                                       .toString()
                                       .startsWith("/parking/")
                                       ? "#3e94a6"
                                       : "",
                                 }}
                              >
                                 {this.props.t("Payments Feedbacks")}
                              </span>
                           </Link>
                        </li>
                        
                     </ul>
                  </li>
               </ul>
            </div>
         </React.Fragment>
      );
   }
}

const mapStatetoProps = (state) => {
   return { ...state.Layout };
};

export default withRouter(
   connect(mapStatetoProps, {
      changeLayout,
      changeSidebarTheme,
      changeSidebarType,
      changeLayoutWidth,
      changePreloader,
   })(withNamespaces()(SidebarContent))
);
