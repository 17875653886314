import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { getVehicleModel, AddModel, deleteVehicleModel, EditModel, SearchModel, ActiveVehicleModelsID, DeActiveVehicleModelsID } from "../Services/UserServices/Index";
import {
   getVehicleParking,
   searchParking,
   setActiveParking,
   setDeActiveParking,
} from "../../Services/VehicleServicesManageParking/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import Swal from "sweetalert2";
import moment from "moment";
import dummyCar from "../../../assets/vehicleIcon/dummyCar.jpg";
// import image_userA from "../VerticalLayout/AllUsers/VehicleModelA.svg";
import parkingA_active from "../../../assets/PakingImges/parkingA.svg";
import parkingDA_deactive from "../../../assets/PakingImges/parkingDA.svg";
// import image_userD from "../VerticalLayout/AllUsers/VehicleModelDA.svg";
import Pagination from "../../VerticalLayout/pagination/Pagination";
class ManageParking extends Component {
   constructor(props) {
      super(props);

      this.getVehicleParking = this.getVehicleParking.bind(this);

      // this.addVehicleParking = this.addVehicleParking.bind(this);
      // this.updateVehicleParking = this.updateVehicleParking.bind(this);
      // this.DeleteParkingID = this.DeleteParkingID.bind(this);

      this.state = {
         pageIDs: [],
         getParking: [],
         allVehicleParking: [],
         modelName: "",
         status: "",
         totalParking: "",
         searchItem: "",
         minPrice: "",
         maxPrice: "",
         minPriceVal: false,
         showClearBtn: false,
         maxPriceVal: false,
         loadingModel: true,
      };
   }

   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getVehicleParking();
   }

   async getVehicleParking(page) {
      let ParkingArray = [];
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         console.log(
            this.state.searchItem,
            this.state.minPrice,
            this.state.maxPrice
         );
         const getAllParking = await searchParking(
            accessToken,
            page,
            this.state.searchItem,
            this.state.minPrice,
            this.state.maxPrice
         );

         if (getAllParking.status === true) {
            console.log("this.state.allVehicleParking", getAllParking);
            this.setState({
               loading: true,
               totalParking: getAllParking?.parkings?.total,
            });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: getAllParking.parkings.current_page,
               });
            this.setState({ lastPage: getAllParking.parkings.last_page });
            this.setState({ allVehicleParking: getAllParking?.parkings?.data });

            for (
               let i = getAllParking.parkings.from;
               i <= getAllParking.parkings.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.state.allVehicleParking.map((mod, index) => {
               ParkingArray.push({
                  id: mod.id,
                  name: mod.name,
                  description: mod.description,

                  width: mod.width,
                  status: mod.status,
                  weight: mod.weight,
               });
               this.setState({ getParking: ParkingArray });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async ActiveVehicleParkingID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to active this parking list?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setActiveParking(accessToken, id);

            if (result.status === true) {
               this.getVehicleParking(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleParkingID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to deactive this parking list?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActiveParking(accessToken, id);

            if (result.status === true) {
               this.getVehicleParking(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getVehicleParking(page);
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   async SearchChangeHandler(e) {
      this.setState({ searchItem: e.target.value });

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const userData = await searchParking(
            accessToken,
            this.state.currentPage,
            e.target.value
         );
         if (userData.status === true) {
            this.getVehicleParking();
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   render() {
      return (
         <>
            <div className="page-content">
               <Container>
                  {/* ///////////////////////////// */}
                  <div className="d-flex justify-content-between">
                     <h5 className="fs-5 mb-3 fw-bold">Manage Parkings </h5>
                     {this.state.totalParking !== "" &&
                        this.state.totalParking !== null &&
                        this.state.totalParking !== undefined && (
                           <h5 className="fs-5 mb-3 fw-bold me-2 px-3 py-2 badge bg-white text-dark">
                              Total Parkings: {this.state.totalParking ?? ""}
                           </h5>
                        )}
                  </div>
                  {this.state.loading ? (
                     <>
                        <Card className="p-4 ">
                           <div className="row mb-3">
                              <div className="col-sm-3 ">
                                 {/* <div className="search-box "> */}
                                 <input
                                    ref={(inputRef) =>
                                       (this.inputRef1 = inputRef)
                                    }
                                    type="text"
                                    class="form-control rounded-pill"
                                    style={{ paddingLeft: 20 }}
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={this.state.searchItem}
                                    aria-describedby="search-addon"
                                    onChange={(e) =>
                                       this.SearchChangeHandler(e)
                                    }
                                 />
                                 {/* </div> */}
                              </div>
                              <div className="col-9">
                                 <div className="ms-auto text-end">
                                    {this.inputRef1?.value !== "" ||
                                    this.state?.showClearBtn ? (
                                       <button
                                          type="button"
                                          className="btn text-white mb-3 me-3"
                                          value={""}
                                          onClick={(e) => {
                                             this.setState({
                                                maxPrice: "",
                                                searchItem: "",
                                                minPrice: "",
                                                showClearBtn: false,
                                             });
                                             this.SearchChangeHandler(e);
                                          }}
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                       >
                                          Clear
                                       </button>
                                    ) : null}

                                    <span
                                       class="mdi mdi-filter fs-1 mt-3 cursor-pointer me-3"
                                       data-bs-toggle="modal"
                                       data-bs-target="#filterModal"
                                       style={{
                                          color: "#3e94a6",
                                          cursor: "pointer",
                                       }}
                                       onClick={() =>
                                          this.setState({
                                             maxPriceVal: false,
                                             minPriceVal: false,
                                          })
                                       }
                                    ></span>
                                 </div>
                              </div>
                           </div>
                           <div className="d-flex justify-content-between">
                              <div></div>
                              {/* <div>
                      <Button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropOne"
                        style={{
                          background: "linear-gradient(#3E94A6, #323F4B)",
                        }}
                        className="btn-rounded mb-2 me-2"
                      >
                        <span>Add Parking</span>
                      </Button>
                    </div> */}
                           </div>
                           <div className="overflow-auto">
                              <table class="table bg-white table-responsive">
                                 <thead>
                                    <tr>
                                       {/* <th scope="col">No.</th> */}
                                       <th scope="col">No.</th>
                                       <th scope="col">User</th>
                                       <th scope="col">Parking Name </th>
                                       <th scope="col">Price</th>
                                       <th scope="col">Date</th>
                                       <th scope="col">Type</th>

                                       <th scope="col">Status</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.allVehicleParking?.length >
                                    0 ? (
                                       <>
                                          {" "}
                                          {this.state.allVehicleParking.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}

                                                         <td>
                                                            <h6 className="mb-0">
                                                               {/* <img
                                                            src={dummyCar}
                                                            alt=""
                                                            width={25}
                                                            height={25}
                                                            style={{
                                                               borderRadius:
                                                                  "50%",
                                                               display:
                                                                  "inline",
                                                               marginRight:
                                                                  "4px",
                                                            }}
                                                         /> */}
                                                               {item?.user
                                                                  ?.first_name ==
                                                               undefined
                                                                  ? "--"
                                                                  : item?.user
                                                                       ?.first_name +
                                                                    " " +
                                                                    item?.user
                                                                       ?.last_name}{" "}
                                                            </h6>

                                                            <small
                                                               className="mt-0"
                                                               style={{
                                                                  color: "#505d69",
                                                               }}
                                                            >
                                                               {item?.user
                                                                  ?.email ??
                                                                  "--"}
                                                            </small>
                                                            <br />
                                                            <small
                                                               className="mt-0"
                                                               style={{
                                                                  color: "#505d69",
                                                               }}
                                                            >
                                                               {item?.user
                                                                  ?.phone ??
                                                                  "--"}
                                                            </small>
                                                         </td>
                                                         <td>{item.name}</td>
                                                         <td>
                                                            $
                                                            {item.daily_price !==
                                                               null &&
                                                            item.daily_price !==
                                                               undefined
                                                               ? item.daily_price
                                                               : item.hourly_price !==
                                                                    null &&
                                                                 item.hourly_price !==
                                                                    undefined
                                                               ? item.hourly_price
                                                               : 0}
                                                         </td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>

                                                         <td>
                                                            {item?.availability_type !==
                                                               "" &&
                                                            item?.availability_type !==
                                                               null &&
                                                            item?.availability_type !==
                                                               undefined ? (
                                                               <>
                                                                  {item?.availability_type?.replace(
                                                                     /^\w/,
                                                                     (c) =>
                                                                        c.toUpperCase()
                                                                  )}
                                                               </>
                                                            ) : (
                                                               <span>--</span>
                                                            )}
                                                         </td>
                                                         <td>
                                                            {console.log(
                                                               "item.status",
                                                               item.status
                                                            )}
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : item.status ==
                                                              "expire" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Expire
                                                               </span>
                                                            ) : item.status ==
                                                              "pending" ? (
                                                               <span className="badge bg-warning px-2">
                                                                  Pending
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            {/* <Link to="#" className="me-3 text-primary">
                                  <i
                                    title="Edit"
                                    className="mdi mdi-pencil font-size-18"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropTwo"
                                    // onClick={() => this.setInitialValue(item.id, item.name, item.slug, item.status)}
                                  ></i>
                                </Link> */}

                                                            <Link
                                                               to={`/parking/${item?.id}`}
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="View"
                                                                  className="mdi mdi-eye font-size-18"
                                                               ></i>
                                                            </Link>

                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        parkingDA_deactive
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-3"
                                                                     onClick={() =>
                                                                        this.DeActiveVehicleParkingID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        parkingA_active
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-3"
                                                                     onClick={() =>
                                                                        this.ActiveVehicleParkingID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}{" "}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* ///////////////////////add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start w-75">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Model
                           </h5>
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Vehicle Model
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Vehicle Model"
                                    value={this.state.vehicleModel}
                                    onChange={(e) =>
                                       this.vehicleModelFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }

                                    // onChange={(e) => this.setState({ vehicleModel: e.target.value })}
                                 />

                                 {this.state.vehicleModelVal ? (
                                    <div className="modelValidation">
                                       required vehicles model
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}
                           {this.state.vehicleModel != "" &&
                           this.state.vehicleModel != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Model
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveModel}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Parking
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start w-75">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Model
                           </h5>
                           <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className="d-flex flex-column justify-content-center">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0 text-secondary"
                              >
                                 Vehicle Model
                              </label>
                              <input
                                 type="text"
                                 className="form-control form-control-solid "
                                 placeholder="Vehicle Model"
                                 value={this.state.modelName}
                                 onChange={(e) =>
                                    this.setState({
                                       modelName: e.target.value,
                                       modelNameVal: false,
                                    })
                                 }
                                 onKeyPress={(e) =>
                                    this.onKeyPressAddGroupEdit(e)
                                 }
                              />
                           </div>
                           {this.state.modelNameVal ? (
                              <div className="modelValidation">Enter Color</div>
                           ) : null}
                        </div>

                        <div className="modal-footer">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* {this.state.vehicleModel != "" && this.state.vehicleModel != undefined ? ()} */}

                           {this.state.modelName != "" &&
                           this.state.modelName != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 data-bs-dismiss="modal"
                                 onClick={() => this.updateModel()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() => this.updateModel()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  className="modal fade"
                  id="filterModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Filter By Price
                           </h5>
                           <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                 this.setState({
                                    maxPrice: "",
                                    maxPriceVal: false,
                                    minPrice: "",
                                    minPriceVal: false,
                                 })
                              }
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className="d-flex flex-column justify-content-center">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder  form-label mb-0 text-secondary"
                              >
                                 Min Price
                              </label>
                              <input
                                 type="number"
                                 className="form-control form-control-solid "
                                 placeholder=" Min price"
                                 value={this.state.minPrice}
                                 onChange={(e) =>
                                    this.setState({
                                       minPrice: e.target.value,
                                       minPriceVal: false,
                                    })
                                 }
                                 // onKeyPress={(e) =>
                                 //    this.onKeyPressAddGroupEdit(e)
                                 // }
                              />
                           </div>
                           {this.state.minPriceVal ? (
                              <div className="modelValidation">Enter Price</div>
                           ) : null}
                           <div className="d-flex flex-column mt-3 justify-content-center">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder  form-label mb-0 text-secondary"
                              >
                                 Max Price
                              </label>
                              <input
                                 type="number"
                                 className="form-control form-control-solid "
                                 placeholder=" Max price"
                                 value={this.state.maxPrice}
                                 onChange={(e) =>
                                    this.setState({
                                       maxPrice: e.target.value,
                                       maxPriceVal: false,
                                    })
                                 }
                                 // onKeyPress={(e) =>
                                 //    this.onKeyPressAddGroupEdit(e)
                                 // }
                              />
                           </div>
                           {this.state.maxPriceVal ? (
                              <div className="modelValidation">Enter Price</div>
                           ) : null}
                        </div>

                        <div className="modal-footer">
                           {/* {this.state.vehicleModel != "" && this.state.vehicleModel != undefined ? ()} */}
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={() =>
                                 this.setState({
                                    maxPrice: "",
                                    maxPriceVal: false,
                                    minPrice: "",
                                    minPriceVal: false,
                                 })
                              }
                           >
                              Close
                           </button>
                           {this.state.minPrice == "" &&
                           this.state.maxPrice == "" ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary disabled"
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Apply
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 data-bs-dismiss="modal"
                                 onClick={() => {
                                    this.getVehicleParking();

                                    this.setState({
                                       showClearBtn: true,
                                    });
                                 }}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Apply
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default ManageParking;
