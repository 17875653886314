import React from "react";
import { Link } from "react-router-dom";

const EcommerceCustomerColumns = () => [
  {
    dataField: "username",
    text: "Name",
    sort: true,
  },
  {
    text: "Email",
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.email}</p>
      </>
    ),
  },
  {
    text: "Gender",
    dataField: "gender",
    sort: true,
  },
  // {
  //   text: "Wallet Balance",
  //   dataField: "walletBalance",
  //   sort: true,
  // },
  {
    dataField: "status",
    text: "Status",
    sort: true,
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: () => (
      <>
        <Link to="#" className="me-3 text-primary">
          <i className="mdi mdi-pencil font-size-18" data-bs-toggle="modal" data-bs-target="#staticBackdropTwo">
            {" "}
          </i>
         
                                       <div
            className="modal fade"
            id="staticBackdropTwo"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content text-start">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Edit User
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                  <div className="d-flex justify-content-around text-dark">
                    <div>
                      <label htmlFor="exampleFormControlInput1 " className="fw-bolder required form-label mb-0">
                       First Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid "
                        placeholder="First Name"
                        // onChange={NamePlate} value={PlateTitle}
                      />
                    </div>
                    <div>
                      <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                       Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid "
                        placeholder="Last Name"
                        // onChange={vehicleFunction} value={vehicleTitle}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-around mt-4 text-dark">
                    <div>
                      <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-solid "
                        placeholder="Email"
                        // onChange={ModelFunction} value={ModelTitle}
                      />
                    </div>
                    <div>
                      <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                        Gender
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid "
                        placeholder="Gender"
                        // onChange={DescriptionFunction} value={DescriptionTitle}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start ms-3 mt-4 text-dark">
                    <div>
                      <label htmlFor="exampleFormControlInput1" className="fw-bolder required form-label mb-0">
                        Status
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid "
                        placeholder="Status"
                        // onChange={colorFunction} value={colorTitle}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button
                    type="button"
                    style={{
                      background: "linear-gradient(#3E94A6, #323F4B)",
                    }}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
       
        </Link>
        <Link to="#" className="text-danger">
          <i className="mdi mdi-trash-can font-size-18"></i>
        </Link>
      </>
    ),
  },
];

export default EcommerceCustomerColumns;
