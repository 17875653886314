import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// import { getReasons } from "../UserServices/Index";
import {
   getReasons,
   AddReports,
   DeleteReasons,
   EditReason,
   getReportType,
   ActiveReasonsID,
   DeActiveReasonsID,
} from "../Services/Reasons/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";

import reasonsActive from "../../assets/ReasonIcons/reasonsA.svg";
import reasonsDeactive from "../../assets/ReasonIcons/reasonsDA.svg";
import Pagination from "../VerticalLayout/pagination/Pagination";

class Reasons extends Component {
   constructor(props) {
      super(props);

      this.setEditReason = this.setEditReason.bind(this);
      this.setAddReasons = this.setAddReasons.bind(this);
      this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         firstName: "",
         allReports: [],
         allReasons: [],
         loading: false,
         slectetdReportType: "",
         slectetdReportID: "",
         slectetdReportValue: "",
         // reportVal:false,
         reportTitle: "",
         // reportTitleVal:false,
         slectetdReasonId: "",
         reasonTitle: "",
         editplateID: null,
         slectetdIDReason: "",
         slectetdIDReasons: "",
         reasonTitleVal: false,
         slectetdReasonIdVal: false,
         pageIDs: [],

         currentPage: "",
         lastPage: "",

         loadingModel: true,
      };
   }

   async componentDidMount() {
      this.getAllReasons();
      this.getAllRoports();
   }
   async getAllReasons(page) {
      try {
         let IDArray = [];
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getReasons(accessToken, page);
         if (result.status === true) {
            this.setState({ loading: true });
            for (
               let i = result.report_reasons.from;
               i <= result.report_reasons.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: result.report_reasons.current_page,
               });
            this.setState({ lastPage: result.report_reasons.last_page });

            this.setState({ allReasons: result.report_reasons.data });
         } else {
            Swal.fire({
               position: "top",
               icon: "warning",
               title: "User has already been taken.",
               showConfirmButton: false,
               timer: 1000,
            });
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async getAllRoports() {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getReportType(accessToken);
         if (result.status === true) {
            this.setState({ allReports: result.report_types });
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async setAddReasons() {
      this.setState({ loadingModel: false });

      if (this.state.reasonTitle == "" || this.state.reasonTitle == undefined) {
         this.setState({ reasonTitleVal: true });
      }

      if (
         this.state.slectetdReasonId == "" ||
         this.state.slectetdReasonId == undefined
      ) {
         this.setState({ slectetdReasonIdVal: true });
      }

      if (
         this.state.reasonTitle != "" &&
         this.state.reasonTitle != undefined &&
         this.state.slectetdReasonId != "" &&
         this.state.slectetdReasonId != undefined
      ) {
         try {
            // let ownerIdNumber = parseInt(this.state.OwnerId);
            // let modelIdNumber = parseInt(this.state.modelIdName);
            // let vehicleIdNumber = parseInt(this.state.vehicleIdName);
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            const response = await AddReports(
               accessToken,
               this.state.slectetdReasonId,
               this.state.reasonTitle
            );
            if (response.status == true) {
               this.getAllReasons(this.state.currentPage);

               // let usersArray = [...this.state.allReasons];
               // usersArray.unshift(response.new_report_reason);
               // this.setState({ allReasons: usersArray });

               // this.setState({ loadingModel: true });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Reason add successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async SetDeleteReasons(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeleteReasons(accessToken, id);
            if (result.status === true) {
               this.getAllReasons(this.state.currentPage);
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Reason removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 3000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async setInitialValue(id, reasonTitles, reasontypes, IDReasons) {
      this.setState({
         editplateID: id,
         reportTitle: reasonTitles,
         slectetdReportType: reasontypes,
         slectetdIDReasons: IDReasons,
      });
   }
   async setEditReason() {
      if (this.state.reportTitle == "" || this.state.reportTitle == undefined) {
         this.setState({ reasonTitleVal: true });
      }

      if (
         this.state.slectetdReasonId == "" ||
         this.state.slectetdReasonId == undefined
      ) {
         this.setState({ slectetdReasonIdVal: true });
      }

      if (this.state.reportTitle != "" && this.state.reportTitle != undefined) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);
            const response = await EditReason(
               accessToken,
               this.state.slectetdIDReasons,
               this.state.reportTitle,
               this.state.editplateID
            );
            if (response.status == true) {
               this.getAllReasons(this.state.currentPage);

               // let usersArray = [...this.state.allReasons];
               // usersArray.map((item, index) => {
               //   if (item.id === response.new_report_reason.id) {
               //     usersArray[index] = response?.new_report_reason;
               //   }
               // });
               // this.setState({ allReasons: usersArray });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Reason updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   AllClearValueValidation = () => {
      this.setState({
         reportTitle: "",
         slectetdReasonId: "",
         reasonTitle: "",
         reasonTitleVal: false,
         slectetdReasonIdVal: false,
      });
   };

   ReportTitleFunction = (e) => {
      this.setState({
         reportTitle: e.target.value,
         reasonTitleVal: false,
      });
   };
   ReasonTitleFunction = (e) => {
      this.setState({ reasonTitle: e.target.value, reasonTitleVal: false });
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   async ActiveReasonID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to active this report reason?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await ActiveReasonsID(accessToken, id);
            if (result.status === true) {
               this.getAllReasons(this.state.currentPage);

               this.getAllRoports();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Reasons active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveReasonID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to deactive this report reason?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeActiveReasonsID(accessToken, id);

            if (result.status === true) {
               this.getAllReasons(this.state.currentPage);
               this.getAllRoports();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Reasons Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllReasons(page);
   };

   render() {
      return (
         <>
            <div className="page-content">
               <h5 className="fs-5 mb-3 fw-bold">Report Reasons </h5>
               {this.state.loading ? (
                  <Card className="p-4 ">
                     <div className="d-flex justify-content-between">
                        <div>
                           {/* <div className="search-box "> */}
                           {/* <input
                    type="text"
                    class="form-control rounded-pill"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    // onChange={(e) => this.SearchChangeHandler(e)}
                  /> */}
                           {/* </div> */}
                        </div>
                        <div>
                           <Button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn-rounded mb-2 me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={this.AllClearValueValidation}
                           >
                              {/* {this.state.loadingModel === true ? ( */}
                              <span>
                                 {/* <i className="mdi mdi-plus me-1" /> */}
                                 Add Reason
                              </span>
                           </Button>
                        </div>
                     </div>

                     <div className="overflow-auto">
                        <table class="table table-responsive">
                           <thead>
                              <tr>
                                 <th scope="col">No.</th>
                                 <th scope="col">Title</th>
                                 <th scope="col">Type</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Date</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.allReasons.length > 0 ? (
                                 this.state.allReasons.map((item, outer) => {
                                    return (
                                       <>
                                          <tr key={outer}>
                                             {this.state.pageIDs?.map(
                                                (item, inner) =>
                                                   outer == inner ? (
                                                      <td>{item.id}</td>
                                                   ) : null
                                             )}

                                             <td>{item.title}</td>
                                             <td>{item.report_type.type}</td>

                                             <td>
                                                {item.status == "active" ? (
                                                   <span
                                                      className="badge bg-success "
                                                      style={{
                                                         paddingLeft: 13,
                                                         paddingRight: 13,
                                                      }}
                                                   >
                                                      Active
                                                   </span>
                                                ) : item.status ==
                                                  "deactive" ? (
                                                   <span className="badge bg-danger px-2">
                                                      Deactive
                                                   </span>
                                                ) : null}
                                             </td>

                                             <td>
                                                {this.getDate(item.created_at)}
                                             </td>

                                             <td>
                                                <Link
                                                   to="#"
                                                   className="me-3 text-primary"
                                                >
                                                   <i
                                                      title="Edit"
                                                      className="mdi mdi-pencil font-size-18"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdropTwo"
                                                      onClick={() =>
                                                         this.setInitialValue(
                                                            item?.id,
                                                            item.title ?? "no",
                                                            item.report_type
                                                               .type ?? "no",
                                                            item.report_type
                                                               .id ?? "no"
                                                         )
                                                      }
                                                   ></i>
                                                </Link>
                                                <Link
                                                   to="#"
                                                   className="text-danger"
                                                >
                                                   <i
                                                      title="Delete"
                                                      className="mdi mdi-trash-can font-size-18 me-3"
                                                      onClick={() =>
                                                         this.SetDeleteReasons(
                                                            item.id
                                                         )
                                                      }
                                                      //  onClick={() => this.DeleteVehicleID(item.id)}
                                                   ></i>
                                                </Link>
                                                {item?.status == "active" ? (
                                                   <Link
                                                      to="#"
                                                      className="text-danger"
                                                      title="Deactive"
                                                   >
                                                      <img
                                                         src={reasonsDeactive}
                                                         width={"20px"}
                                                         className="mb-1"
                                                         alt=""
                                                         onClick={() =>
                                                            this.DeActiveReasonID(
                                                               item.id
                                                            )
                                                         }
                                                      />
                                                   </Link>
                                                ) : item?.status ==
                                                  "deactive" ? (
                                                   <Link
                                                      to="#"
                                                      className="text-danger"
                                                      title="Active"
                                                   >
                                                      <img
                                                         src={reasonsActive}
                                                         width={"20px"}
                                                         className="mb-1"
                                                         alt=""
                                                         onClick={() =>
                                                            this.ActiveReasonID(
                                                               item.id
                                                            )
                                                         }
                                                      />
                                                   </Link>
                                                ) : (
                                                   ""
                                                )}
                                             </td>
                                          </tr>
                                       </>
                                    );
                                 })
                              ) : (
                                 <tr className="pb-5">
                                    <td className="pt-5">
                                       <div class="record-not-found pt-5">
                                          Record Not Found
                                       </div>
                                    </td>
                                 </tr>
                              )}
                           </tbody>
                        </table>
                     </div>
                     {this.state.lastPage > 1 && (
                        <Pagination
                           pageCount={this.state.lastPage}
                           paginate={this.paginate}
                        />
                     )}
                  </Card>
               ) : (
                  <div
                     className="text-center mt-5 d-flex align-items-center justify-content-center"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Edit Reasons
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="me-2 fw-bolder required form-label mb-0"
                           >
                              Reason Type
                           </label>

                           <select
                              class="form-select"
                              aria-label="Default select example"
                              value={this.state.slectetdIDReasons}
                              onChange={(e) =>
                                 this.setState({
                                    slectetdIDReasons: e.target.value,
                                    reportVal: false,
                                 })
                              }
                           >
                              {this.state.slectetdReportType != "" &&
                              this.state.slectetdReportType != undefined ? (
                                 <option hidden>
                                    {this.state.slectetdReportType}
                                 </option>
                              ) : (
                                 <option hidden>Select Owner</option>
                              )}

                              {this.state.allReports?.map((report, iiii) => {
                                 return (
                                    <option value={report.id} key={iiii}>
                                       {report?.type}
                                    </option>
                                 );
                              })}
                           </select>
                           {/* {this.state.reportVal ? <div className="modelValidation">Enter FullName</div> : null} */}
                        </div>
                        {/* <div className="text-center mb-2 justify-content-around"> */}
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Report Title
                           </label>

                           <input
                              type="text"
                              className="form-control form-control-solid "
                              placeholder="Reason"
                              value={this.state.reportTitle}
                              onChange={(e) => this.ReportTitleFunction(e)}
                           />
                           {this.state.reasonTitleVal ? (
                              <div className="modelValidation">
                                 Enter reason title
                              </div>
                           ) : null}
                        </div>
                     </div>
                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        {this.state.slectetdIDReasons != "" &&
                        this.state.slectetdIDReasons != undefined &&
                        this.state.reportTitle != "" &&
                        this.state.reportTitle != undefined ? (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={this.setEditReason}
                           >
                              Update
                           </button>
                        ) : (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              onClick={this.setEditReason}
                           >
                              Update
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            {/* /////////////////////////////Add model */}
            <div
               className="modal fade"
               ///
               id="staticBackdrop"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Add Reason
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="me-2 fw-bolder required form-label mb-0"
                           >
                              Reason Type
                           </label>

                           <select
                              class="form-select"
                              aria-label="Default select example"
                              value={this.state.slectetdReasonId}
                              onChange={(e) =>
                                 this.setState({
                                    slectetdReasonId: e.target.value,

                                    slectetdReasonIdVal: false,
                                 })
                              }
                           >
                              <option hidden>Select type</option>
                              {this.state.allReports?.map((report, iiii) => {
                                 return (
                                    <option value={report.id} key={iiii}>
                                       {report?.type}
                                    </option>
                                 );
                              })}
                           </select>
                           {this.state.slectetdReasonIdVal ? (
                              <div className="modelValidation">
                                 Select report type
                              </div>
                           ) : null}
                        </div>
                        {/* <div className="text-center mb-2 justify-content-around"> */}
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Reason Title
                           </label>

                           <input
                              type="text"
                              className="form-control form-control-solid "
                              placeholder="Reason"
                              onChange={(e) => this.ReasonTitleFunction(e)}
                              value={this.state.reasonTitle}
                           />

                           {this.state.reasonTitleVal ? (
                              <div className="modelValidation">
                                 Enter reason title
                              </div>
                           ) : null}
                        </div>
                     </div>
                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        {this.state.reasonTitle != "" &&
                        this.state.reasonTitle != undefined &&
                        this.state.slectetdReasonId != "" &&
                        this.state.slectetdReasonId != undefined ? (
                           <button
                              // data-bs-dismiss="modal"
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              // onClick={(e) => this.SaveUser(e)}
                              onClick={this.setAddReasons}
                              data-bs-dismiss="modal"
                           >
                              Add Reason
                           </button>
                        ) : (
                           <button
                              // data-bs-dismiss="modal"
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              // onClick={(e) => this.SaveUser(e)}
                              // onClick={this.SavePlates}
                              onClick={this.setAddReasons}
                           >
                              Add Reason
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default Reasons;
