import React, { Component } from "react";
import { Card, } from "reactstrap";
import moment from "moment";
import { getPaymentFeedback } from "../../Services/PaymentFeedback/index";
import Pagination from "../../VerticalLayout/pagination/Pagination";

class ManageFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIDs: [],
            allPaymentFeedback: [],
            currentPage: "",
            lastPage: "",
            loading: false,
        };
    }

    async componentDidMount() {
        this.getAllPaymentFeedback();
        // this.getAllRoports();
    }

    async getAllPaymentFeedback(page) {
        try {
            let IDArray = [];

            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            const result = await getPaymentFeedback(accessToken, page);
            console.log('result.feedbacks.data', result.feedbacks.data)
            if (result.status === true) {
                for (
                    let i = result.feedbacks.from;
                    i <= result.feedbacks.to;
                    i++
                ) {
                    IDArray.push({ id: i });
                }
                this.setState({ pageIDs: IDArray });

                this.setState({ loading: true });
                this.setState({
                    currentPage: result.feedbacks.current_page,
                });
                this.setState({ lastPage: result.feedbacks.last_page });
                this.setState({
                    allPaymentFeedback: result.feedbacks.data,
                });
            } else {
                console.log("sorry");
            }
        } catch (err) {
            console.log("error ", err);
        }
    }
    getDate = (date) => {
        return moment(date).format("DD/MM/YYYY HH:mm");
    };

    paginate = async (data) => {
        let page = data.selected + 1;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        await this.getAllPaymentFeedback(page);
    };
    render() {
        return (
            <>
                <div className="page-content">
                    <div>
                        <h5 className="fs-5 mb-3 fw-bold">Payments Feedbacks </h5>
                        {this.state.loading === true ? (
                            <Card className="p-4">
                                <div className="overflow-auto">
                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Feedback / Suggestion</th>
                                                <th scope="col">Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.allPaymentFeedback.map(
                                                (itemGroup, outer) => {
                                                    return (
                                                        <tr key={outer}>
                                                            <td>
                                                                {this.state.pageIDs?.map(
                                                                    (item, inner) =>
                                                                        outer == inner ? (
                                                                            <td>{item.id}</td>
                                                                        ) : null
                                                                )}
                                                            </td>


                                                            <td>{itemGroup?.payment_through ?? "--"} </td>
                                                            <td>{itemGroup?.feedback ?? "--"} </td>
                                                            <td> {this.getDate(
                                                                    itemGroup.created_at
                                                                )}
                                                            </td>
                                                            
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {this.state.lastPage > 1 && (
                                    <Pagination
                                        pageCount={this.state.lastPage}
                                        paginate={this.paginate}
                                    />
                                )}
                            </Card>
                        ) : (
                         
                            <div
                                className="text-center d-flex align-items-center justify-content-center text-bold"
                                style={{
                                    height: "50vh",
                                }}
                            >
                                <div class="spinner-border text-enter" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
               
            </>
        );
    }
}

export default ManageFeedback;
