import React, { Component, useState } from "react";
import { PhotoshopPicker } from "react-color";
import { Link } from "react-router-dom";
import {
   AddVehicles,
   EditVehicle,
   deleteVehicle,
   getVehicles,
   searchVehicles,
   DeActiveVehicleID,
   ActiveVehicleID,
} from "../Services/AllVehicles/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import Swal from "sweetalert2";
import moment from "moment";

import image_userA from "../../assets/vehicleIcon/vehicleA.svg";
import image_userD from "../../assets/vehicleIcon/vehicleDA.svg";
import Pagination from "../VerticalLayout/pagination/Pagination";
class Vehicles extends Component {
   constructor(props) {
      super(props);

      this.vehicleNameFunction = this.vehicleNameFunction.bind(this);
      this.ClearAllField = this.ClearAllField.bind(this);
      this.AllClearFields = this.AllClearFields.bind(this);

      this.SaveVehicle = this.SaveVehicle.bind(this);
      this.state = {
         vehicleNameVal: false,
         color: "",
         colorCode: "",
         vehicleName: "",
         vehicleArray: [],
         currentPage: "",
         lastPage: "",
         loading: false,
         pageIDs: [],

         loadingModel: true,
      };
   }
   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllVehicles();
   }
   async getAllVehicles(page) {
      // let vehiclesArray = [];
      let IDArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const vehicle = await getVehicles(accessToken, page);

         if (vehicle.status === true) {
            for (let i = vehicle.vehicles.from; i <= vehicle.vehicles.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.setState({ loading: true });

            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({ currentPage: vehicle.vehicles.current_page });
            this.setState({ lastPage: vehicle.vehicles.last_page });

            this.setState({ vehicleArray: vehicle.vehicles.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   async SaveVehicle() {
      this.setState({ loadingModel: false });
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (this.state.vehicleName == "" || this.state.vehicleName == undefined) {
         this.setState({ vehicleNameVal: true });
      }
      if (
         this.state.vehicleName !== "" &&
         this.state.vehicleName !== undefined
      ) {
         try {
            const response = await AddVehicles(
               accessToken,
               this.state.vehicleName
            );

            if (response.status == true) {
               this.setState({ vehicleNameVal: true });
               this.getAllVehicles(this.state.currentPage);

               // let usersArray = [...this.state.vehicleArray];
               // usersArray.unshift(response.vehicle);
               // this.setState({ vehicleArray: usersArray });

               this.setState({ loadingModel: true });
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleName: "",
                  status: "",
                  editvehicleID: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Vehicle has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
      // }
   }
   async setInitialValue(
      id,
      name,

      status
   ) {
      this.setState({
         vehicleNameVal: false,
         editvehicleID: id,
         vehicleName: name,

         status: status,
      });
   }
   async updateVehicle() {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (this.state.vehicleName == "" || this.state.vehicleName == undefined) {
         this.setState({ vehicleNameVal: true });
      }
      if (
         this.state.vehicleName !== "" &&
         this.state.vehicleName !== undefined
      ) {
         try {
            const response = await EditVehicle(
               accessToken,
               this.state.vehicleName,
               this.state.editvehicleID
            );
            if (response.status == true) {
               this.getAllVehicles(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleName: "",
                  status: "",
                  editvehicleID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async DeleteVehicleID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteVehicle(accessToken, id);
            if (result.status === true) {
               this.getAllVehicles(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async SearchChangeHandler(e) {
      let search = e.target.value;

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const vehicle = await searchVehicles(accessToken, search);

         if (vehicle.status === true) {
            this.setState({ vehicleArray: vehicle.vehicles.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   paginate = (pageNumber) => {
      this.getAllVehicles(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };
   vehicleNameFunction = (e) => {
      this.setState({ vehicleName: e.target.value });
      this.setState({ vehicleNameVal: false });
   };
   ClearAllField = () => {
      this.setState({ vehicleName: "" });
      this.setState({ vehicleNameVal: false });
   };
   AllClearFields = () => {
      this.setState({ vehicleNameVal: false });
   };

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   async ActiveVehicleID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure to active  this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await ActiveVehicleID(accessToken, id);
            if (result.status === true) {
               this.getAllVehicles(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure to deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeActiveVehicleID(accessToken, id);

            if (result.status === true) {
               this.getAllVehicles(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllVehicles(page);
   };

   render() {
      return (
         <>
            <div className="page-content">
               <Container fluid>
                  {/* ///////////////////////////// */}
                  <h5 className="fs-5 mb-3 fw-bold">Vehicles Make </h5>
                  {this.state.loading ? (
                     <div className="">
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div>
                                 {/* <div className="search-box "> */}
                                 {/* <input
                        type="search"
                        class="form-control rounded-pill"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(e) => this.SearchChangeHandler(e)}
                      /> */}
                                 {/* </div> */}
                              </div>
                              <div>
                                 <Button
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    type="button"
                                    onClick={this.ClearAllField}
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded mb-2 me-2"
                                 >
                                    <span>Add Vehicle</span>
                                 </Button>
                              </div>
                           </div>

                           <div className="overflow-auto">
                              <table class="table table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Date</th>
                                       <th scope="col">Status</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.vehicleArray?.length > 0 ? (
                                       <>
                                          {" "}
                                          {this.state.vehicleArray?.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}
                                                         <td>{item.name}</td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>
                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <Link
                                                               to="#"
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="Edit"
                                                                  className="mdi mdi-pencil font-size-18"
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#staticBackdropTwo"
                                                                  onClick={() =>
                                                                     this.setInitialValue(
                                                                        item.id,
                                                                        item.name,

                                                                        item.status
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                            >
                                                               <i
                                                                  title="Delete"
                                                                  className="mdi mdi-trash-can font-size-18 me-3"
                                                                  onClick={() =>
                                                                     this.DeleteVehicleID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userD
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1 img-fluid"
                                                                     onClick={() =>
                                                                        this.DeActiveVehicleID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userA
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1 img-fluid"
                                                                     onClick={() =>
                                                                        this.ActiveVehicleID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}{" "}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </div>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* //////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Vehicle
                           </h5>
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body d-flex flex-column justify-content-center">
                           <div className="mt-3 pb-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Vehicle Name
                              </label>
                              <input
                                 style={{ width: "100%" }}
                                 type="text"
                                 className="form-control form-control-solid"
                                 placeholder="Vehicle name"
                                 value={this.state.vehicleName}
                                 onChange={(e) => this.vehicleNameFunction(e)}
                                 onKeyPress={(e) =>
                                    this.onKeyPressAddGroupEdit(e)
                                 }
                              />
                              {this.state.vehicleNameVal ? (
                                 <div className="modelValidation">
                                    Enter vehicle name
                                 </div>
                              ) : null}
                           </div>
                        </div>
                        <div className="modal-footer text-nowrap">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary "
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleName != "" &&
                           this.state.vehicleName != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={() => this.updateVehicle()}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={() => this.updateVehicle()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////////Add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Vehicle
                           </h5>
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn-close "
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>

                        <div className="modal-body d-flex flex-column justify-content-center ">
                           <div className="mt-3 pb-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Vehicle Name
                              </label>
                              <input
                                 style={{ width: "100%" }}
                                 type="text"
                                 className="form-control form-control-solid"
                                 placeholder="Vehicle name"
                                 value={this.state.vehicleName}
                                 onChange={(e) => this.vehicleNameFunction(e)}
                                 onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                              />
                              {this.state.vehicleNameVal ? (
                                 <div className="modelValidation">
                                    Enter vehicle name
                                 </div>
                              ) : null}

                              {/* {this.state.vehicleNameVal ? <div className="modelValidation">Enter Vehicle</div> : null} */}
                           </div>
                        </div>
                        <div className="modal-footer text-nowrap">
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn btn-secondary "
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {this.state.vehicleName != "" &&
                           this.state.vehicleName != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveVehicle}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Vehicle
                              </button>
                           ) : (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SaveVehicle}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Vehicle
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default Vehicles;
