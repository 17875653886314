import React, { Component } from "react";
import { getReportedUsers } from "../Services/UserServices/ManageUsers/index";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
// import image_userD from "../../VerticalLayout/AllUsers/image_userD.svg";
import image_userD from "../../assets/UserIcons/image_userD.svg";
import image_userA from "../../assets/UserIcons/image_userA.svg";
import profileImage from "../../assets/images/profile_image.jpg";
import "./AllUsers.css";

import {
   DeleteUser,
   setRejectUser,
   setAcceptUser,
} from "../Services/UserServices/ManageUsers/index";
import Pagination from "../VerticalLayout/pagination/Pagination";
class ReportedUser extends Component {
   constructor(props) {
      super(props);

      this.getAllReportedUsers = this.getAllReportedUsers.bind(this);
      this.statusDeleteFunction = this.statusDeleteFunction.bind(this);
      this.statusActiveFunction = this.statusActiveFunction.bind(this);

      this.state = {
         allReportedUser: [],
         loading: false,
         modalData: [],
         iID: 0,
         userStatus: 0,
         modalStatus: "",

         currentPage: "",
         lastPage: "",

         profileImg: "",
         reportedReasons: "",
         parkingCount: "",
      };
   }

   async componentDidMount() {
      // this.getAllReasons();
      this.getAllReportedUsers();
   }
   plateLength;
   async getAllReportedUsers(page) {
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getReportedUsers(accessToken, page);

         if (result.status === true) {
            this.setState({ loading: true });
            for (
               let i = result.reported_users.from;
               i <= result.reported_users.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.setState({ currentPage: result.reported_users.current_page });
            this.setState({ lastPage: result.reported_users.last_page });
            this.setState({ plateLength: result.reported_users.last_page });

            this.setState({ allReportedUser: result.reported_users.data });
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   async statusActiveFunction(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to active this reported user?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setAcceptUser(accessToken, id);
            if (result.status === true) {
               this.getAllReportedUsers();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Active user successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async statusDeleteFunction(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to deactive this reported user?",
            // text: "You would be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setRejectUser(accessToken, id);

            this.getAllReportedUsers();
            if (result.status === true) {
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Deactive user successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   dataLength;
   setDataHandler = (data, id, status, profileImg, reasons, parkingCounts) => {
      this.dataLength = data?.plates.length;
      // console.log(data);
      this.setState({
         modalData: data,
         profilesImg: profileImg,
         reportedReasons: reasons,
         parkingCount: parkingCounts,
      });
      this.setState({ modalStatus: status });
      this.setState({ iID: id });
   };

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllReportedUsers(page);
   };

   render() {
      return (
         <div className="page-content">
            <div className="container">
               <h5 className="fs-5 mb-3 fw-bold">Reported Users </h5>
               {this.state.loading === true ? (
                  <Card className="p-4">
                     <div className="overflow-auto">
                        <table class="table table-responsive">
                           <thead>
                              <tr>
                                 <th scope="col">No.</th>
                                 <th scope="col">Reported Person</th>
                                 <th scope="col">Reported By</th>
                                 <th scope="col">Report Reason</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Report Date</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.allReportedUser.length > 0 ? (
                                 this.state.allReportedUser.map(
                                    (item, outer) => {
                                       return (
                                          <>
                                             <tr key={outer}>
                                                {/* {console.log("iteeeeeem", item?.reportable)} */}
                                                {this.state.pageIDs?.map(
                                                   (item, inner) =>
                                                      outer == inner ? (
                                                         <td>{item.id}</td>
                                                      ) : null
                                                )}

                                                <td>
                                                   <h6 className="mb-0">
                                                      {item?.reportable
                                                         ?.first_name ==
                                                      undefined
                                                         ? "--"
                                                         : item?.reportable
                                                              ?.first_name +
                                                           " " +
                                                           item?.reportable
                                                              ?.last_name}
                                                   </h6>
                                                   <small className="mt-0">
                                                      {item?.reportable
                                                         ?.email ?? "--"}
                                                   </small>
                                                   <br />
                                                   <small className="mt-0">
                                                      {item?.reportable
                                                         ?.phone ?? "--"}
                                                   </small>
                                                </td>
                                                <td>
                                                   <h6 className="mb-0">
                                                      {item?.user?.first_name ==
                                                      undefined
                                                         ? "--"
                                                         : item?.user
                                                              ?.first_name +
                                                           " " +
                                                           item?.user
                                                              ?.last_name}{" "}
                                                   </h6>
                                                   <small className="mt-0">
                                                      {item?.user?.email ??
                                                         "--"}
                                                   </small>
                                                   <br />
                                                   <small className="mt-0">
                                                      {item?.user?.phone ??
                                                         "--"}
                                                   </small>
                                                </td>
                                                <td>
                                                   <h6 className="mt-0">
                                                      {" "}
                                                      {item.discription !== null
                                                         ? item.discription
                                                         : item?.report_reason
                                                              ?.title}
                                                   </h6>
                                                </td>
                                                {console.log(
                                                   "object",
                                                   item.status
                                                )}
                                                <td className="badges mt-0">
                                                   {item.status ==
                                                   "accepted" ? (
                                                      <span
                                                         className="badge bg-success mt-0 px-3 "
                                                         style={{
                                                            paddingLeft: 13,
                                                            paddingRight: 13,
                                                         }}
                                                      >
                                                         Active
                                                      </span>
                                                   ) : item.status ==
                                                        "rejected" ||
                                                     item?.status ==
                                                        "deactive" ? (
                                                      <span
                                                         className="badge bg-danger mt-0 "
                                                         style={{
                                                            paddingLeft: 13,
                                                            paddingRight: 13,
                                                         }}
                                                      >
                                                         Deactive
                                                      </span>
                                                   ) : item.status ==
                                                     "pending" ? (
                                                      <span
                                                         className="badge bg-warning mt-0 "
                                                         style={{
                                                            paddingLeft: 13,
                                                            paddingRight: 13,
                                                         }}
                                                      >
                                                         Pending
                                                      </span>
                                                   ) : null}
                                                </td>

                                                <td>
                                                   <h6 className="mt-0">
                                                      {" "}
                                                      {this.getDate(
                                                         item?.created_at
                                                      )}
                                                   </h6>
                                                </td>

                                                {/* <td>
                                {item?.status !== "rejected" || item?.status !== "accepted" ? ( */}
                                                <td
                                                   className="pt-2"
                                                   //   style={{ padding: "20px" }}
                                                >
                                                   <Link
                                                      to="#"
                                                      className=" text-primary"
                                                   >
                                                      <i
                                                         className="mdi mdi-eye font-size-18  "
                                                         data-bs-toggle="modal"
                                                         title="View"
                                                         data-bs-target="#staticBackdropOne"
                                                         onClick={() =>
                                                            this.setDataHandler(
                                                               item?.reportable,
                                                               item?.id,
                                                               item?.status,
                                                               `${item?.reportable?.profile_image?.folder_path}${item?.reportable?.profile_image?.file_name}`,
                                                               item
                                                                  ?.report_reason
                                                                  ?.title,
                                                               item?.user
                                                                  ?.parkings_count
                                                            )
                                                         }
                                                      ></i>
                                                   </Link>
                                                </td>
                                                {/* // ) : (
                                //   <>
                                //     <Link to="#" className="text-danger">
                                //       <img className="mt-3" src={image_userA} alt="" onClick={() => this.statusDeleteFunction(item?.id)} />
                                //     </Link>
                                //     <Link to="#" className="text-danger">
                                //       <img className="ms-3 mt-3" src={image_userD} alt="" onClick={() => this.statusActiveFunction(item?.id)} />
                                //     </Link>
                                //   </>
                                // )} 
                              </td>*/}
                                             </tr>
                                          </>
                                       );
                                    }
                                 )
                              ) : (
                                 <>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>

                                    <tr className="pt-5">
                                       <td className="pt-5">
                                          <div class="record-not-found pt-5">
                                             Record Not Found
                                          </div>
                                       </td>
                                    </tr>
                                 </>
                              )}
                           </tbody>
                        </table>
                     </div>

                     {this.state.lastPage > 1 && (
                        <Pagination
                           pageCount={this.state.lastPage}
                           paginate={this.paginate}
                        />
                     )}
                  </Card>
               ) : (
                  <div
                     className="text-center d-flex align-items-center justify-content-center text-bold"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* ///////////////////////add model */}
            {/* <div
          className="modal fade"
          id="staticBackdropOne"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content text-start">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Reported Person
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body w-100">
                <div className="row ">
                  <div className="col-5 fw-bold">First Name</div>
                  <div className="col-7 ">{this.state.modalData?.first_name}</div>
                  <div className="col-5 fw-bold">Last Name</div>
                  <div className="col-7 ">{this.state.modalData?.last_name}</div>
                  <div className="col-5 fw-bold">Phone Number</div>
                  <div className="col-7 ">{this.state.modalData?.phone}</div>
                  <div className="col-5 fw-bold">Email</div>
                  <div className="col-7 ">{this.state.modalData?.email}</div>
                  <div className="col-5 fw-bold">Gender</div>
                  <div className="col-7 ">{this.state.modalData?.gender}</div>
                  <div className="col-5 fw-bold">Status</div>
                  <div className="col-7 ">{this.state.modalData?.status}</div>
                  <div className="col-5 fw-bold">Number of Plates</div>
                  <div className="col-7 ">{this.dataLength}</div>
                  <div className="col-5 fw-bold"></div>
                  {this.state.modalStatus == "pending" && (
                    <div className="col-5 fw-bold">
                      <label htmlFor="" className="mt-3">
                        Take Action
                      </label>
                      <select
                        className="form-select shadow-none"
                        aria-label="Default select example"
                        value={this.state.userStatus}
                        onChange={(e) => this.setState({ userStatus: e.target.value })}
                      >
                        <option selected hidden>
                          Select Status
                        </option>
                        <option>Active</option>
                        <option>deactive</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              {this.state.modalStatus == "pending" && (
                <div className="modal-footer ">
                  <button type="button" onClick={this.AllClearField} className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>

                  {this.state.userStatus == "Active" ? (
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                      }}
                      className="btn btn-primary"
                      onClick={() => this.statusActiveFunction(this.state.iID)}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                      }}
                      className="btn btn-primary"
                      onClick={() => this.statusDeleteFunction(this.state.iID)}
                    >
                      Ok
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div> */}

            {/* //////////////Profile model */}
            <div
               className="modal fade"
               id="staticBackdropOne"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Reported Person
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.profilesImg ===
                              "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={profileImage}
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={this.state.profilesImg}
                                    alt=""
                                 />
                              )}
                           </center>
                        </div>

                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    First Name
                                 </label>
                              </div>
                              <div className=" col-8">
                                 <p>
                                    {this.state.modalData?.first_name ??
                                       "--"}
                                 </p>
                              </div>

                              <div className="  col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Last Name
                                 </label>
                              </div>

                              <div className=" col-8">
                                 <p>
                                    {this.state.modalData?.last_name ??
                                       "--"}
                                 </p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Email
                                 </label>
                              </div>
                              <div className=" col-5">
                                 <p>
                                    {this.state.modalData?.email ?? "--"}
                                 </p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Gender
                                 </label>
                              </div>
                              <div className=" col-8">
                                 <p>
                                    {this.state.modalData?.gender ??
                                       "--"}
                                 </p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Phone
                                 </label>
                              </div>
                              <div className=" col-8">
                                 <p>
                                    {this.state.modalData?.phone ?? "--"}
                                 </p>
                              </div>
                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Number Of Plates
                                 </label>
                              </div>
                              <div className=" col-8">
                                 <p>{this.dataLength ?? "0"}</p>
                              </div>
                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Number Of Listings
                                 </label>
                              </div>
                              <div className=" col-8">
                                 <p>{this.state?.parkingCount ?? "0"}</p>
                              </div>

                              <div className=" col-11">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Report Reason
                                 </label>
                              </div>
                              <div className=" col-11 border ">
                                 <p>
                                    {this.state.reportedReasons ?? "--"}
                                 </p>
                              </div>

                              {this.state.modalStatus == "pending" && (
                                 <div className=" col-11 fw-bold">
                                    <label htmlFor="" className="mt-3">
                                       Take Action On User
                                    </label>
                                    <select
                                       className="form-select shadow-none"
                                       aria-label="Default select example"
                                       value={
                                          this.state.userStatus ?? "--"
                                       }
                                       onChange={(e) =>
                                          this.setState({
                                             userStatus: e.target.value,
                                          })
                                       }
                                    >
                                       <option selected hidden>
                                          Select Status
                                       </option>
                                       <option>Active</option>
                                       <option>Deactive</option>
                                    </select>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>

                     {this.state.modalStatus == "pending" ? (
                        <div className="modal-footer ">
                           <button
                              type="button"
                              onClick={this.AllClearField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.userStatus == "Active" ? (
                              <button
                                 data-bs-dismiss="modal"
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() =>
                                    this.statusActiveFunction(this.state.iID)
                                 }
                              >
                                 Ok
                              </button>
                           ) : (
                              <button
                                 data-bs-dismiss="modal"
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() =>
                                    this.statusDeleteFunction(this.state.iID)
                                 }
                              >
                                 Ok
                              </button>
                           )}
                        </div>
                     ) : (
                        <>
                           <div className="modal-footer ">
                              <button
                                 type="button"
                                 onClick={this.AllClearField}
                                 className="btn btn-secondary"
                                 data-bs-dismiss="modal"
                              >
                                 Close
                              </button>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default ReportedUser;
