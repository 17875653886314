import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// import { getReasons, AddReports, DeleteReasons, EditReason, getReportType } from "../Services/UserServices/Index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProfileImg from "../../assets/images/profile/images.jpg";
import Img from "../../assets/images/small/img-2.jpg";
import ReactPlayer from "react-player";
// import { getSocialGroups } from "../../Services/SocialGroups/index";
import { getBillingDetail } from "../Services/BillingDetail/index";
import "./BillingDeatail.css";
import Pagination from "../VerticalLayout/pagination/Pagination";
class BillingDeatail extends Component {
   constructor(props) {
      super(props);

      // this.setEditReason = this.setEditReason.bind(this);
      // this.setAddReasons = this.setAddReasons.bind(this);
      // this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         firstName: "",
         allReports: [],
         allBillingDetail: [],

         currentPage: "",
         lastPage: "",
         loading: false,

         slectetdReportType: "",
         slectetdReportID: "",
         slectetdReportValue: "",
         // reportVal:false,
         reportTitle: "",
         // reportTitleVal:false,
         slectetdReasonId: "",
         reasonTitle: "",
         editplateID: null,
         slectetdIDReason: "",
         slectetdIDReasons: "",
         dateChangerStart: [],
         dateChangerEnd: [],

         pageIDs: [],

         reasonTitleVal: false,
         slectetdReasonIdVal: false,
      };
   }

   async componentDidMount() {
      this.setState({ loading: true });
      this.getAllInvoice();
      // this.getAllRoports();
   }

   async getAllInvoice(page) {
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getBillingDetail(accessToken, page);
         if (result.status === true) {
            this.setState({ loading: false });
            this.setState({ allBillingDetail: result?.billing?.data });
            this.setState({ currentPage: result?.billing?.current_page });
            this.setState({ lastPage: result?.billing?.last_page });
            for (let i = result?.billing?.from; i <= result?.billing?.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
         } else {
            console.log("sorry");
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllInvoice(page);
   };

   dateConveter = (date) => {
      let dte = new Date(date);
      return dte;
   };

   render() {
      return (
         <>
            <div className="page-content">
               <h5 className="fs-5 mb-3 fw-bold">Users Billing Detail </h5>
               {this.state.loading == false ? (
                  <div>
                     <Card className="p-4">
                        <div className="overflow-auto">
                           <table class="table table-responsive">
                              <thead>
                                 <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">User </th>
                                    <th scope="col">Address</th>
                                    <th scope="col"> Country</th>
                                    <th scope="col">State</th>

                                    <th scope="col">City</th>

                                    <th scope="col">Zip Code</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.allBillingDetail?.length > 0 ? (
                                    <>
                                       {" "}
                                       {this.state.allBillingDetail.map(
                                          (item, outer) => (
                                             <>
                                                <tr key={outer}>
                                                   {this.state.pageIDs?.map(
                                                      (item, inner) =>
                                                         outer == inner ? (
                                                            <td>{item.id}</td>
                                                         ) : null
                                                   )}

                                                   <td>
                                                      <span>
                                                         {item.first_name !==
                                                            "" &&
                                                         item.first_name !==
                                                            undefined &&
                                                         item.first_name !==
                                                            null &&
                                                         item.first_name !==
                                                            "null"
                                                            ? item?.first_name +
                                                              " "
                                                            : ""}
                                                         {item?.last_name !==
                                                            "" &&
                                                         item.first_name !==
                                                            undefined &&
                                                         item?.last_name !==
                                                            null &&
                                                         item?.last_name !==
                                                            "null"
                                                            ? item?.last_name
                                                            : ""}

                                                         {/* {item?.first_name ??
                                                      "" +
                                                         " " +
                                                         item?.last_name ??
                                                      ""} */}
                                                      </span>
                                                   </td>
                                                   <td>
                                                      <span>
                                                         {item?.address_line_1 ??
                                                            "" +
                                                               " " +
                                                               item?.address_line_2 ??
                                                            ""}
                                                      </span>
                                                   </td>
                                                   <td>
                                                      <span class="">
                                                         {item?.country}
                                                      </span>
                                                   </td>
                                                   <td>
                                                      <span class="">
                                                         {item?.state}
                                                      </span>
                                                   </td>
                                                   <td>
                                                      <span class="">
                                                         {item?.city}
                                                      </span>
                                                   </td>
                                                   <td>
                                                      <span class="">
                                                         {item?.zip ?? "--"}
                                                      </span>
                                                   </td>

                                                   <td className="">
                                                      {item.status == "paid" ? (
                                                         <span class="badge bg-success text-white">
                                                            {item.status}
                                                         </span>
                                                      ) : (
                                                         <span class="badge bg-light text-dark">
                                                            {item.status}
                                                         </span>
                                                      )}
                                                   </td>
                                                </tr>
                                             </>
                                          )
                                       )}
                                    </>
                                 ) : (
                                    <>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>
                                       <tr>
                                          {" "}
                                          <td></td>{" "}
                                       </tr>

                                       <tr className="pb-5">
                                          <td className="pt-5">
                                             <div class="record-not-found pt-5">
                                                Record Not Found
                                             </div>
                                          </td>
                                       </tr>
                                    </>
                                 )}
                              </tbody>
                           </table>
                        </div>
                        {this.state.lastPage > 1 && (
                           <Pagination
                              pageCount={this.state.lastPage}
                              paginate={this.paginate}
                           />
                        )}
                     </Card>
                  </div>
               ) : (
                  <div
                     className="text-center mt-5 d-flex align-items-center justify-content-center"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Chow Club
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <PerfectScrollbar style={{ maxHeight: "450px" }}>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             James
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <p>
                                          In publishing and graphic design,
                                          Lorem ipsum is a placeholder text
                                          commonly used to demonstrate the
                                          visual form of a document or a
                                          typeface without relying on meaningful
                                          content. Lorem ipsum may be used as a
                                          placeholder before final copy is
                                          available.
                                       </p>
                                    </div>
                                 </Link>
                              </li>
                           </div>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Charlotte
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <ReactPlayer
                                          width="auto"
                                          height="200px"
                                          // url={message?.conversation_media[0]?.path}
                                          url="https://www.youtube.com/watch?v=4VaqA-5aQTM"
                                          playing={false}
                                          controls={true}
                                          loop={false}
                                          muted={false}
                                          playsinline={true}
                                          // onReady={onLoadedData}
                                       />
                                    </div>
                                 </Link>
                              </li>
                           </div>

                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Amelia
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <img
                                          src={Img}
                                          width="100%"
                                          height="200"
                                       />
                                    </div>
                                 </Link>
                              </li>
                           </div>
                        </PerfectScrollbar>
                     </div>

                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default BillingDeatail;
