import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import image_plateD from "../../assets/PlatesIcon/image_plateD.svg";
import image_plateA from "../../assets/PlatesIcon/image_plateA.svg";
import Dropdown from "react-bootstrap/Dropdown";
import profileImage from "../../assets/images/profile_image.jpg";
import {
   getPlate,
   getPlateModel,
   EditPlates,
   searchPlate,
   getPlateOwnerName,
   getPlateVehicle,
   getPlateColor,
   AddPlate,
   DeletePlates,
   setDeActivePlate,
   setActivePlate,
} from "../Services/ManagePlates/index";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import { times } from "chartist";
import Pagination from "../VerticalLayout/pagination/Pagination";
import "./plates.css";

const options = [
   { value: "chocolate", label: "Chocolate" },
   { value: "strawberry", label: "Strawberry" },
   { value: "vanilla", label: "Vanilla" },
];

//
class AllPlate extends Component {
   constructor(props) {
      super(props);

      this.SavePlates = this.SavePlates.bind(this);
      this.AllClearField = this.AllClearField.bind(this);
      this.AllValueClear = this.AllValueClear.bind(this);
      this.setEditPlateData = this.setEditPlateData.bind(this);
      this.OwnerNameFunction = this.OwnerNameFunction.bind(this);
      // const [firstName, setFirstName] = useState("");

      this.state = {
         loaderChecker: true,
         lastPage: "",
         currentPage: "",

         SelectOwner: true,
         plateNumber: "",
         plateNumberVal: false,
         ownerNameForm: true,
         colorForm: true,
         vehicleForm: true,
         modelForm: true,
         description: "",
         descriptionVal: false,
         OwnerId: "",
         OwnerIdVal: false,
         colorId: "",
         colorIdName: "",
         colorIdVal: false,
         OwnerIdName: "",
         dropDownColor: "",
         plateOwnerName: "",
         status: "",
         plateAPI: [],
         colorName: [],
         modelName: [],
         vehicleName: [],
         userName: [],
         userAPI: "",

         vehicleId: "",
         vehicleIdName: "",
         vehicleIdVal: false,

         modelId: "",
         modelIdName: "",
         modelIdVal: false,
         editplateID: null,
         pageIDs: [],
         colorIdFayaz: "",
         carimage: "",
         profilesImg: "",

         userEmail: "",
         plateColorName: "",

         loadingModel: true,
      };
   }

   async SavePlates() {
      this.setState({ loadingModel: false });

      if (this.state.modelId == "" || this.state.modelId == undefined) {
         this.setState({ modelIdVal: true });
      }

      if (this.state.colorId == "" || this.state.colorId == undefined) {
         this.setState({ colorIdVal: true });
      }

      if (this.state.vehicleId == "" || this.state.vehicleId == undefined) {
         this.setState({ vehicleIdVal: true });
      }

      if (
         this.state.plateNumber == "" ||
         this.state.plateNumber == undefined ||
         this.state.plateNumber.length < 5
      ) {
         this.setState({ plateNumberVal: true });
      }
      if (this.state.OwnerId == "" || this.state.OwnerId == undefined) {
         this.setState({ OwnerIdVal: true });
      }
      // if (this.state.description == "" || this.state.description == undefined) {
      //   this.setState({ descriptionVal: true });
      // }
      // if (this.state.colorId == 4) {
      //   this.setState({ colorId: 9 });
      // } else this.setState({ colorId: this.state.colorId });

      if (
         this.state.plateNumber != "" &&
         this.state.plateNumber != undefined &&
         this.state.plateNumber.length > 4 &&
         this.state.modelId != "" &&
         this.state.modelId != undefined &&
         this.state.vehicleId != "" &&
         this.state.vehicleId != undefined &&
         this.state.dropDownColor != "" &&
         this.state.dropDownColor != undefined &&
         // this.state.description != "" &&
         // this.state.description != undefined &&
         this.state.OwnerId != "" &&
         this.state.OwnerId != undefined
      ) {
         try {
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            const response = await AddPlate(
               accessToken,
               this.state.OwnerId,
               this.state.plateNumber,
               this.state.description,
               this.state.modelId,
               this.state.vehicleId,
               this.state.colorId
               // this.state.status
            );

            if (response.status == true) {
               this.getAllPlate(this.state.currentPage);
               // console.log("zzzzzz", this.state.currentPage);

               // let plateArray = [...this.state.plateAPI];
               // plateArray.unshift(response.admin_users);

               // this.setState({ plateAPI: plateArray });

               // this.setState({ loadingModel: true });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  plateNumber: "",
                  description: "",
                  OwnerId: "",
                  OwnerIdName: "",
                  OwnerName: "",
                  plateOwnerName: "",
                  status: "",
               });
            } else {
               this.setState({ loadingModel: false });

               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Plate has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async DeletePlateList(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeletePlates(accessToken, id);

            if (result.status === true) {
               this.getAllPlate(this.state.currentPage);

               // let userArray = [...this.state.plateAPI];
               // userArray.map((item, index) => {
               //   if (item.id === id) {
               //     userArray.splice(index, 1);
               //   }
               // });
               // this.setState({ plateAPI: userArray });
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async ActivePlateID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to active this vehicle plate?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setActivePlate(accessToken, id);

            if (result.status === true) {
               this.getAllPlate(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActivePlateID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to deactive this vehicle plate?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActivePlate(accessToken, id);

            if (result.status === true) {
               this.getAllPlate(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   dataLength;
   async setInitialValue(
      data,
      id,
      user,
      OwnerId,
      plate,
      description,
      vehicle,
      vehicleIDz,
      model,
      modelIDz,
      color,
      colorIdName,
      status,
      profileImg,
      carImage,
      email,
      colorname
   ) {
      this.dataLength = data;
      // console.log(",.,.", carImage, ",,,,,", profileImg);
      this.setState({
         profilesImg: profileImg,
         editplateID: id,
         userEmail: email,
         plateNumber: plate,
         description: description,
         OwnerIdName: user,
         OwnerId: OwnerId,
         vehicleId: vehicle,
         vehicleIdName: vehicleIDz,
         modelId: model,
         modelIdName: modelIDz,
         colorId: color,
         colorIdName: colorname,
         status: status,
         plateColorName: colorname,
         carimage: carImage,
      });
   }
   async setEditPlateData() {
      if (this.state.modelId == "" || this.state.modelId == undefined) {
         this.setState({ modelIdVal: true });
      }

      // if (this.state.colorId == "" || this.state.colorId == undefined) {
      //   this.setState({ colorIdVal: true });
      // }
      if (this.state.vehicleId == "" || this.state.vehicleId == undefined) {
         this.setState({ vehicleIdVal: true });
      }

      if (
         this.state.plateNumber == "" ||
         this.state.plateNumber == undefined ||
         this.state.plateNumber.length < 5
      ) {
         this.setState({ plateNumberVal: true });
      }
      if (this.state.OwnerId == "" || this.state.OwnerId == undefined) {
         this.setState({ OwnerIdVal: true });
      }
      // if (this.state.description == "" || this.state.description == undefined) {
      //   this.setState({ descriptionVal: true });
      // }

      if (
         this.state.plateNumber != "" &&
         this.state.plateNumber != undefined &&
         this.state.plateNumber.length > 4 &&
         this.state.modelId != "" &&
         this.state.modelId != undefined &&
         this.state.vehicleId != "" &&
         this.state.vehicleId != undefined &&
         this.state.OwnerId != "" &&
         this.state.OwnerId != undefined
         // this.state.description != "" &&
         // this.state.description != undefined
      ) {
         try {
            let ownerIdNumber = parseInt(this.state.OwnerId);
            let modelIdNumber = parseInt(this.state.modelId);
            let vehicleIdNumber = parseInt(this.state.vehicleId);
            let tokenAccess = localStorage.getItem("Token");
            let accessToken = JSON.parse(tokenAccess);

            const response = await EditPlates(
               accessToken,
               ownerIdNumber,
               this.state.plateNumber,
               this.state.description,
               modelIdNumber,
               vehicleIdNumber,
               // this.state.vehicleId,
               this.state.colorId,
               this.state.editplateID
            );
            if (response.status == true) {
               // this.getAllPlate();
               this.getAllPlate(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });

               this.setState({
                  plateNumber: "",
                  description: "",
                  OwnerId: "",
                  OwnerName: "",
                  plateOwnerName: "",
                  status: "",
                  editplateID: null,
                  coloId: "",
                  colorIdName: "",
                  OwnerId: "",
                  plateNumber: "",
                  modelId: "",
                  vehicleId: "",
                  coloId: "",
                  status: "",
                  countryID: "",
                  colorIdName: "",
                  modelIdName: "",
                  OwnerIdName: "",
                  vehicleIdName: "",
               });
               this.AllValueClear();
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async SearchChangeHandler(e) {
      this.setState({ searchItem: e.target.value });

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const plateData = await searchPlate(
            accessToken,
            e.target.value,
            this.state.currentPage
         );
         if (plateData.status === true) {
            // this.setState({ currentPage: plateData.plates.current_page });
            // this.setState({ lastPage: plateData.plates.last_page });
            // this.setState({ plateAPI: plateData.plates.data });
            this.getAllPlate();
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   //////////////////validation////////////////////
   OwnerNameFunction = (e) => {
      this.setState({ plateNumber: e.target.value, plateNumberVal: false });
   };

   async componentDidMount() {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const resultColor = await getPlateColor(accessToken);
         this.setState({ colorName: resultColor.vehicle_colors });

         const resultModel = await getPlateModel(accessToken);
         this.setState({ modelName: resultModel.car_models });

         const resultVehicle = await getPlateVehicle(accessToken);
         this.setState({ vehicleName: resultVehicle.vehicles });

         const resultOwnerName = await getPlateOwnerName(accessToken);
         this.setState({ userName: resultOwnerName.users });
      } catch (err) {
         console.log("error", err);
      }

      this.getAllPlate();
   }

   async getAllPlate(page) {
      try {
         let IDArray = [];
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const plateData = await searchPlate(
            accessToken,
            this.state.searchItem,
            page
         );
         if (plateData.status === true) {
            for (let i = plateData.plates.from; i <= plateData.plates.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({ currentPage: plateData.plates.current_page });
            this.setState({ lastPage: plateData.plates.last_page });

            this.setState({ loaderChecker: false });
            this.setState({ plateAPI: plateData.plates.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   AllClearField = () => {
      this.setState({
         vehicleIdVal: false,
         colorIdVal: false,
         descriptionVal: false,
         plateNumberVal: false,
         OwnerIdVal: false,
         modelIdVal: false,
      });
   };
   AllValueClear = () => {
      this.setState({
         vehicleIdVal: false,
         colorIdVal: false,
         descriptionVal: false,
         plateNumberVal: false,
         OwnerIdVal: false,
         modelIdVal: false,
      });
      this.setState({
         OwnerId: "",
         colorIdName: "",
         dropDownColor: "",
         modelIdName: "",
         OwnerIdName: "",
         vehicleIdName: "",
         plateNumber: "",
         modelId: "",
         vehicleId: "",
         colorId: "",
         status: "",
         countryID: "",
         description: "",
      });
   };

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   ////////////////////////////pagination
   paginate = (pageNumber) => {
      this.getAllPlate(pageNumber);
      window.scrollTo({
         top: 0,
         // left: 100,
         behavior: "smooth",
      });
      // window.scrollTo(0, 1000)
   };
   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllPlate(page);
   };
   render() {
      const { SearchBar } = Search;

      return (
         // return (
         <div className="container page-content">
            {this.state.loaderChecker ? (
               <div className="text-center mt-5 d-flex align-items-center justify-content-center text-bold">
                  <div class="spinner-border text-enter" role="status">
                     <span class="visually-hidden"></span>
                  </div>
               </div>
            ) : null}
            {!this.state.loaderChecker ? (
               <Container>
                  <h5 className="fs-5 mb-3 fw-bold">Plates List</h5>

                  <Card className="p-4 ">
                     <div className="d-flex justify-content-between ">
                        <div>
                           {/* <div className="search-box "> */}
                           <input
                              type="text"
                              class="form-control rounded-pill"
                              style={{ paddingLeft: 20 }}
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="search-addon"
                              onChange={(e) => this.SearchChangeHandler(e)}
                           />
                           {/* </div> */}
                        </div>
                        <div>
                           <Button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              // className="btn-rounded mb-2 me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={this.AllValueClear}
                              className="btn-rounded mb-2 me-2 "
                           >
                              {/* {this.state.loadingModel === true ? ( */}
                              {/* <span> */}
                              {/* <i className="mdi mdi-plus me-1" /> */}
                              Add Plate
                              {/* </span> */}
                              {/* ) : (
                      <span>
                        <div class="spinner-border text-center " role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </span>
                    )} */}
                           </Button>
                        </div>
                     </div>
                     <div className="overflow-auto">
                        <table className="table table-responsive ">
                           <thead>
                              <tr>
                                 <th scope="col">No.</th>
                                 <th scope="col">Plate Number</th>
                                 <th scope="col">Owner Name</th>
                                 <th scope="col">Date</th>
                                 <th scope="col">Vehicle</th>
                                 <th scope="col">Model</th>
                                 <th scope="col">Color</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>

                           <tbody>
                              {this?.state?.plateAPI?.length > 0 ? (
                                 <>
                                    {" "}
                                    {this?.state?.plateAPI?.map(
                                       (item, outer) => {
                                          return (
                                             <>
                                                <tr>
                                                   {this.state.pageIDs?.map(
                                                      (item, inner) =>
                                                         outer == inner ? (
                                                            <td>{item.id}</td>
                                                         ) : null
                                                   )}
                                                   <td
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdropSix"
                                                      className="pointer"
                                                      onClick={() =>
                                                         this.setInitialValue(
                                                            item?.user
                                                               ?.total_plates,
                                                            item?.id,
                                                            item?.user
                                                               ?.first_name ??
                                                               "no",
                                                            item?.user?.id ??
                                                               "no",
                                                            item?.plate,
                                                            item?.discription,
                                                            item?.vehicle?.id,
                                                            item?.vehicle?.name,
                                                            item?.model?.id,
                                                            item?.model?.name,
                                                            item?.color?.id,
                                                            item?.color?.color,
                                                            // item.color?.name,
                                                            item?.status,

                                                            `${item?.user?.profile_image?.folder_path}${item?.user?.profile_image?.file_name}`,
                                                            `${item?.car_image?.folder_path}${item?.car_image?.file_name}`,

                                                            item?.user?.email,
                                                            item?.color?.name
                                                         )
                                                      }
                                                      title="Profile"
                                                   >
                                                      {item?.plate ??
                                                         "--"}
                                                   </td>
                                                   <td
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdropFiveth"
                                                      className="pointer"
                                                      onClick={() =>
                                                         this.setInitialValue(
                                                            item?.user
                                                               ?.total_plates,
                                                            item?.id,
                                                            item?.user
                                                               ?.first_name ??
                                                               "no",
                                                            item?.user?.id ??
                                                               "no",
                                                            item?.plate,
                                                            item?.discription,
                                                            item?.vehicle?.id,
                                                            item?.vehicle?.name,
                                                            item?.model?.id,
                                                            item?.model?.name,
                                                            item?.color?.id,
                                                            item?.color?.color,
                                                            // item.color?.name,
                                                            item?.status,

                                                            `${item?.user?.profile_image?.folder_path}${item?.user?.profile_image?.file_name}`,
                                                            `${item?.car_image?.folder_path}${item?.car_image?.file_name}`,

                                                            item?.user?.email,
                                                            item?.color?.name
                                                         )
                                                      }
                                                      title="Profile"
                                                   >
                                                      {item?.user?.first_name ==
                                                      undefined
                                                         ? "--"
                                                         : item?.user
                                                              ?.first_name}
                                                   </td>
                                                   {/* <td>{item?.user?.first_name ?? "no"}</td> */}
                                                   <td>
                                                      {this.getDate(
                                                         item?.model?.created_at
                                                      )}
                                                   </td>
                                                   <td>
                                                      {item?.vehicle?.name ??
                                                         "--"}
                                                   </td>
                                                   <td>
                                                      {item?.model?.name ??
                                                         "--"}
                                                   </td>
                                                   <td>
                                                      <div
                                                         className="text-center py-2 "
                                                         style={{
                                                            backgroundColor:
                                                               item?.color
                                                                  ?.color,
                                                            width: "90px",
                                                            // padding: "9px",
                                                            color: "white",
                                                            border:
                                                               ".1px solid black",
                                                         }}
                                                      >
                                                         <span className="badge bg-light text-dark">
                                                            {item?.color
                                                               ?.name ?? (
                                                               <span className="text-dark">
                                                                  Anonymous
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                   </td>
                                                   {/* {console.log("aaaaa", item?.color?.Name)} */}
                                                   <td className="badges">
                                                      {item?.status ==
                                                      "active" ? (
                                                         <span
                                                            className="badge bg-success "
                                                            style={{
                                                               paddingLeft: 13,
                                                               paddingRight: 13,
                                                            }}
                                                         >
                                                            Active
                                                         </span>
                                                      ) : item?.status ==
                                                        "deactive" ? (
                                                         <span className="badge bg-danger px-2">
                                                            Deactive
                                                         </span>
                                                      ) : null}
                                                   </td>
                                                   <td>
                                                      <Link
                                                         to="#"
                                                         className=" text-primary"
                                                      >
                                                         <i
                                                            title="Edit"
                                                            className="mdi mdi-pencil font-size-18"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdropTwo"
                                                            onClick={() =>
                                                               this.setInitialValue(
                                                                  item?.user
                                                                     ?.total_plates,
                                                                  item?.id,
                                                                  item?.user
                                                                     ?.first_name ??
                                                                     "no",
                                                                  item?.user
                                                                     ?.id ??
                                                                     "no",
                                                                  item?.plate,
                                                                  item?.discription,
                                                                  item?.vehicle
                                                                     ?.id,
                                                                  item?.vehicle
                                                                     ?.name,
                                                                  item?.model
                                                                     ?.id,
                                                                  item?.model
                                                                     ?.name,
                                                                  item?.color
                                                                     ?.id,
                                                                  item?.color
                                                                     ?.color,
                                                                  // item?.color?.Name,
                                                                  item?.status,
                                                                  `${item?.user?.profile_image?.folder_path}${item?.user?.profile_image?.file_name}`,
                                                                  `${item?.car_image?.folder_path}${item?.car_image?.file_name}`,

                                                                  item?.user
                                                                     ?.email,
                                                                  item?.color
                                                                     ?.name
                                                               )
                                                            }
                                                         ></i>
                                                      </Link>
                                                      <Link
                                                         to="#"
                                                         className="text-danger mx-2 cursor-pointer mt-1"
                                                         onClick={() =>
                                                            this.DeletePlateList(
                                                               item.id
                                                            )
                                                         }
                                                      >
                                                         <i
                                                            className="mdi mdi-trash-can font-size-18"
                                                            title="Delete"
                                                         ></i>
                                                      </Link>
                                                      {item?.status ==
                                                      "active" ? (
                                                         <Link
                                                            to="#"
                                                            className="text-danger"
                                                            title="Deactive"
                                                         >
                                                            <img
                                                               src={
                                                                  image_plateD
                                                               }
                                                               alt=""
                                                               onClick={() =>
                                                                  this.DeActivePlateID(
                                                                     item.id
                                                                  )
                                                               }
                                                            />
                                                            {/* <i className="mdi mdi-trash-can font-size-18" onClick={() => this.DeleteUserID(item.id)}></i> */}
                                                         </Link>
                                                      ) : item?.status ==
                                                        "deactive" ? (
                                                         <Link
                                                            to="#"
                                                            className="text-danger"
                                                            title="Active"
                                                         >
                                                            <img
                                                               className=""
                                                               src={
                                                                  image_plateA
                                                               }
                                                               alt=""
                                                               onClick={() =>
                                                                  this.ActivePlateID(
                                                                     item.id
                                                                  )
                                                               }
                                                            />
                                                            <i></i>
                                                            {/* <i className="ms-3 mdi mdi-trash-can font-size-18" onClick={() => this.DeleteUserID(item.id)}></i> */}
                                                         </Link>
                                                      ) : (
                                                         ""
                                                      )}
                                                   </td>
                                                </tr>
                                             </>
                                          );
                                       }
                                    )}
                                 </>
                              ) : (
                                 <>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>
                                    <tr>
                                       {" "}
                                       <td></td>{" "}
                                    </tr>

                                    <tr className="pt-5">
                                       <td className="pt-5">
                                          <div class="record-not-found pt-5">
                                             Record Not Found
                                          </div>
                                       </td>
                                    </tr>
                                 </>
                              )}
                           </tbody>
                        </table>
                     </div>
                     {this.state.lastPage > 1 && (
                        <Pagination
                           pageCount={this.state.lastPage}
                           paginate={this.paginate}
                        />
                     )}
                  </Card>
               </Container>
            ) : null}
            {/* ////////////Loder */}

            {/* //////////////Profile model */}
            <div
               className="modal fade"
               id="staticBackdropFiveth"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Plate Owner Profile
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        {/* profilesImg */}
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.profilesImg ===
                              "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={profileImage}
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={this.state.profilesImg}
                                    alt=""
                                 />
                              )}
                           </center>
                           {/* <img src={this.state.profilesImg} className="img-fluid modal_image w-50 h-50 " alt="" /> */}
                        </div>

                        {/* <div className="d-flex justify-content-around text-dark"> */}
                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Plate Number:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.plateNumber ?? "--"}</p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Name:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.OwnerIdName ?? "--"}</p>
                              </div>
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Vehicle Name:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.vehicleIdName ?? "--"}
                                 </p>
                              </div>
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Email:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.userEmail ?? "--"}</p>
                              </div>
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Model Name:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.modelIdName ?? "--"}</p>
                              </div>
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Vehicle Color:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.plateColorName ?? "--"}
                                 </p>
                              </div>
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Number Of Vehicle:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.dataLength ?? "0"}</p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            {/* //////////////Plate model */}
            <div
               className="modal fade"
               id="staticBackdropSix"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Plate Profile
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        {/* profilesImg */}
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.carimage === "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src="https://img.lovepik.com/free-png/20210926/lovepik-car-icon-png-image_401452256_wh1200.png"
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={this.state.carimage}
                                    alt=""
                                 />
                              )}
                           </center>
                           {/* <img src={this.state.profilesImg} className="img-fluid modal_image w-50 h-50 " alt="" /> */}
                        </div>

                        {/* <div className="d-flex justify-content-around text-dark"> */}
                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Plate Number:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.plateNumber ?? "--"}</p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Owner Name:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.OwnerIdName ?? "--"}</p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>

            {/* //////////////////////model add */}
            <div
               //
               className="modal fade"
               id="staticBackdrop"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Add Plate
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllValueClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="me-2 fw-bolder required form-label mb-0"
                           >
                              Select Owner
                           </label>

                           <select
                              class="form-select"
                              aria-label="Default select example"
                              value={this.state.OwnerId}
                              onChange={(e) =>
                                 this.setState({
                                    OwnerId: e.target.value,
                                    OwnerIdVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                           >
                              <option hidden>Select name</option>
                              {this.state.userName?.map((userNames, iiii) => {
                                 return (
                                    <option value={userNames.id} key={iiii}>
                                       {userNames?.first_name}
                                    </option>
                                 );
                              })}
                           </select>
                           {this.state.OwnerIdVal ? (
                              <div className="modelValidation">
                                 Select owner
                              </div>
                           ) : null}
                        </div>
                        {/* <div className="d-flex justify-content-around"> */}
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Plate Number
                           </label>

                           <input
                              type="text"
                              className="form-control form-control-solid "
                              placeholder=" Plate number"
                              value={this.state.plateNumber}
                              onChange={(e) => this.OwnerNameFunction(e)}
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}

                              // onChange={(e) => this.setState({ plateNumber: e.target.value })}
                           />
                           {this.state.plateNumberVal ? (
                              <div className="modelValidation">
                                 {this.state.plateNumber?.length > 0
                                    ? "Enter valid plate number"
                                    : "Enter plate number"}
                              </div>
                           ) : null}
                        </div>

                        <div className="mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder  form-label mb-0"
                           >
                              Description
                           </label>

                           <textarea
                              // type="textarea"
                              className="form-control form-control-solid "
                              placeholder="Description"
                              value={this.state.description}
                              onChange={(e) =>
                                 this.setState({
                                    description: e.target.value,
                                    descriptionVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                           />
                           {/* {this.state.descriptionVal ? <div className="modelValidation">Enter plate description</div> : null} */}
                        </div>
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Model
                           </label>

                           <select
                              value={this.state.modelId}
                              onChange={(e) =>
                                 this.setState({
                                    modelId: e.target.value,
                                    modelIdVal: false,
                                 })
                              }
                              class="form-select w-100"
                              aria-label="Default select example"
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                           >
                              {/* <option>Select model</option> */}
                              <option hidden>Select Model</option>
                              {this.state.modelName.map((model, iii) => {
                                 return (
                                    <option value={model?.id} key={iii}>
                                       {model?.name}
                                    </option>
                                 );
                              })}
                           </select>
                           {this.state.modelIdVal ? (
                              <div className="modelValidation">
                                 Select model
                              </div>
                           ) : null}
                        </div>
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder mb-0 required"
                           >
                              Vehicle
                           </label>

                           <select
                              class="form-select w-100"
                              value={this.state.vehicleId}
                              onChange={(e) =>
                                 this.setState({
                                    vehicleId: e.target.value,
                                    vehicleIdVal: false,
                                 })
                              }
                              aria-label="Default select example"
                              onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                           >
                              {/* <option>Select vehicle</option> */}
                              <option hidden>Select Vehicle</option>
                              {this.state.vehicleName.map((vehicle, iiii) => {
                                 return (
                                    <option value={vehicle.id} key={iiii}>
                                       {vehicle?.name}
                                    </option>
                                 );
                              })}
                           </select>

                           {this.state.vehicleIdVal ? (
                              <div className="modelValidation">
                                 Select vehicle
                              </div>
                           ) : null}
                        </div>
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Color
                           </label>
                           <Dropdown placeholder="Select Color">
                              <Dropdown.Toggle
                                 placeholder="Select Color"
                                 style={{
                                    backgroundColor: `${
                                       this.state.dropDownColor !== ""
                                          ? this.state.dropDownColor
                                          : "white"
                                    }`,
                                    width: "100%",
                                 }}
                                 id="dropdown-basic"
                              >
                                 <span className="badge bg-light text-dark">
                                    {this.state?.dropDownColor !== ""
                                       ? this.state?.dropDownColor
                                       : "Select Color"}
                                 </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                 className="p-3"
                                 style={{
                                    width: "100%",
                                 }}
                              >
                                 {this.state.colorName?.map((colors, i) => (
                                    <Dropdown.Item
                                       className="my-1"
                                       key={i}
                                       name={colors.name}
                                       value={colors.id}
                                       style={{
                                          backgroundColor: `${colors.color}`,
                                       }}
                                       onClick={(e) => {
                                          this.setState({
                                             colorId: colors.id,
                                             colorIdVal: false,
                                             dropDownColor: colors.name,
                                          });
                                       }}
                                    >
                                       <span
                                          className="badge bg-light text-dark w-25"
                                          name={colors.name}
                                          value={colors.id}
                                       >
                                          {colors.name}
                                       </span>
                                    </Dropdown.Item>
                                 ))}
                              </Dropdown.Menu>
                           </Dropdown>
                           <div className="">
                              {this.state.colorIdVal ? (
                                 <div className="modelValidation d-block">
                                    Select color
                                 </div>
                              ) : null}
                           </div>
                        </div>
                        {/* </div> */}

                        <div className="d-flex justify-content-start ms-3 mt-4"></div>
                     </div>
                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllValueClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        {this.state.plateNumber != "" &&
                        this.state.plateNumber != undefined &&
                        this.state.plateNumber.length > 4 &&
                        this.state.modelId != "" &&
                        this.state.modelId != undefined &&
                        this.state.vehicleId != "" &&
                        this.state.vehicleId != undefined &&
                        this.state.dropDownColor != "" &&
                        this.state.dropDownColor != undefined &&
                        // this.state.description != "" &&
                        // this.state.description != undefined &&
                        this.state.OwnerId != "" &&
                        this.state.OwnerId != undefined ? (
                           <button
                              // data-bs-dismiss="modal"
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              // onClick={(e) => this.SaveUser(e)}
                              onClick={this.SavePlates}
                              data-bs-dismiss="modal"
                              ref={(node) => (this.btn = node)}
                           >
                              Add Plate
                           </button>
                        ) : (
                           <button
                              // data-bs-dismiss="modal"
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              // onClick={(e) => this.SaveUser(e)}
                              onClick={this.SavePlates}
                              ref={(node) => (this.btn = node)}
                           >
                              Add Plate
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>

            {/* ////////////////////////,model Edit */}

            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Edit Plate
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllValueClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="me-2 fw-bolder required form-label mb-0"
                           >
                              Select Owner
                           </label>

                           <select
                              class="form-select"
                              aria-label="Default select example"
                              value={this.state.OwnerId}
                              onChange={(e) =>
                                 this.setState({
                                    OwnerId: e.target.value,
                                    OwnerIdVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                           >
                              {this.state.OwnerIdName != "" &&
                              this.state.OwnerIdName != undefined ? (
                                 <option hidden>
                                    {this.state.OwnerIdName}
                                 </option>
                              ) : (
                                 <option hidden>Owner Name</option>
                              )}

                              {this.state.userName?.map((userNames, iiii) => {
                                 return (
                                    <option value={userNames.id} key={iiii}>
                                       {userNames?.first_name}
                                    </option>
                                 );
                              })}
                           </select>
                           {this.state.OwnerIdVal ? (
                              <div className="modelValidation">
                                 Select owner
                              </div>
                           ) : null}
                        </div>
                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Plate Number
                           </label>

                           <input
                              type="text"
                              className="form-control form-control-solid "
                              placeholder=" Plate number"
                              value={this.state.plateNumber}
                              onChange={(e) => this.OwnerNameFunction(e)}
                              onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                           />
                           {this.state.plateNumberVal ? (
                              <div className="modelValidation">
                                 {this.state.plateNumber?.length > 0
                                    ? "Enter valid plate number"
                                    : "Enter plate number"}
                              </div>
                           ) : null}
                        </div>

                        <div className=" mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder  form-label mb-0"
                           >
                              Description
                           </label>

                           <textarea
                              // type="text"
                              className="form-control form-control-solid "
                              placeholder="Description"
                              value={this.state.description}
                              onChange={(e) =>
                                 this.setState({
                                    description: e.target.value,
                                    descriptionVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                           />
                           {/* {this.state.descriptionVal ? <div className="modelValidation">Enter plate description</div> : null} */}
                        </div>

                        {/* </div> */}

                        <div className="mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Model
                           </label>
                           <select
                              value={this.state.modelId}
                              onChange={(e) =>
                                 this.setState({
                                    modelId: e.target.value,
                                    modelIdVal: false,
                                 })
                              }
                              onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                              class="form-select w-100"
                              aria-label="Default select example"
                           >
                              {this.state.modelIdName != "" &&
                              this.state.modelIdName != undefined ? (
                                 <option hidden>
                                    {this.state.modelIdName}
                                 </option>
                              ) : (
                                 <option hidden>select model</option>
                              )}

                              {this.state.modelName.map((model, iii) => {
                                 return (
                                    <option value={model.id} key={iii}>
                                       {model?.name}
                                    </option>
                                 );
                              })}
                           </select>
                           {this.state.modelIdVal ? (
                              <div className="modelValidation">
                                 Select model
                              </div>
                           ) : null}
                        </div>
                        <div className="mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder mb-0 required"
                           >
                              Vehicle
                           </label>
                           <select
                              class="form-select w-100"
                              value={this.state.vehicleId}
                              onChange={(e) =>
                                 this.setState({
                                    vehicleId: e.target.value,
                                    vehicleIdVal: false,
                                 })
                              }
                              aria-label="Default select example"
                              onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                           >
                              {this.state.vehicleIdName != "" &&
                              this.state.vehicleIdName != undefined ? (
                                 <option hidden>
                                    {this.state.vehicleIdName}
                                 </option>
                              ) : (
                                 <option hidden>select vehicle</option>
                              )}

                              {this.state.vehicleName.map((vehicle, iiii) => {
                                 return (
                                    <option value={vehicle.id} key={iiii}>
                                       {vehicle?.name}
                                    </option>
                                 );
                              })}
                           </select>

                           {this.state.vehicleIdVal ? (
                              <div className="modelValidation">
                                 Select vehicle
                              </div>
                           ) : null}
                        </div>
                        <div className="mb-3">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="fw-bolder required form-label mb-0"
                           >
                              Color
                           </label>
                           <Dropdown>
                              <Dropdown.Toggle
                                 style={{
                                    backgroundColor: `${this.state.colorIdName}`,
                                    width: "100%",
                                 }}
                                 id="dropdown-basic"
                              >
                                 <span className="badge bg-light text-dark ">
                                    {" "}
                                    {this.state.colorIdName}
                                 </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                 className="p-3"
                                 style={{
                                    width: "100%",
                                 }}
                              >
                                 {this.state.colorName.map((colors, i) => (
                                    <Dropdown.Item
                                       className="my-1 w-100"
                                       key={i}
                                       name={colors.color}
                                       value={colors.id}
                                       style={{
                                          backgroundColor: `${colors.color}`,
                                          width: "100%",
                                       }}
                                       onClick={(e) => {
                                          this.setState({
                                             colorId: colors.id,
                                             colorIdVal: false,
                                             colorIdName: colors.name,
                                          });
                                       }}
                                    >
                                       <span className="badge bg-light text-dark w-25">
                                          {colors.name}
                                       </span>
                                    </Dropdown.Item>
                                 ))}
                              </Dropdown.Menu>
                           </Dropdown>
                           <div className="">
                              {this.state.colorIdVal ? (
                                 <div className="modelValidation d-block">
                                    Select color
                                 </div>
                              ) : null}
                           </div>
                        </div>

                        <div className="d-flex justify-content-start ms-3 mt-4"></div>
                     </div>
                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllValueClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        {this.state.plateNumber != "" &&
                        this.state.plateNumber != undefined &&
                        this.state.plateNumber.length > 4 &&
                        this.state.modelId != "" &&
                        this.state.modelId != undefined &&
                        this.state.vehicleId != "" &&
                        this.state.vehicleId != undefined &&
                        // this.state.colorId != "" &&
                        // this.state.colorId != undefined &&
                        // this.state.description != "" &&
                        // this.state.description != undefined &&
                        this.state.OwnerId != "" &&
                        this.state.OwnerId != undefined ? (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={this.setEditPlateData}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Update
                           </button>
                        ) : (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              onClick={this.setEditPlateData}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Update
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default AllPlate;
