import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { getVehicleModel, AddModel, deleteVehicleModel, EditModel, SearchModel, ActiveVehicleModelsID, DeActiveVehicleModelsID } from "../Services/UserServices/Index";

import {
   getVehicleFeature,
   deleteVehicleFeature,
   AddFeatures,
   EditFeature,
   setActiveFeature,
   setDeActiveFeature,
} from "../../Services/VehicleServicesFeature/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import Swal from "sweetalert2";
import moment from "moment";
import dummyCar from "../../../assets/vehicleIcon/dummyCar.jpg";
// import image_userA from "../VerticalLayout/AllUsers/VehicleModelA.svg";
import feature_active from "../../../assets/PakingImges/featureA.svg";
import feature_deactive from "../../../assets/PakingImges/featureDA.svg";
import pencil from "../../../assets/icons/Pencil-01.svg";
// import image_userD from "../VerticalLayout/AllUsers/VehicleModelDA.svg";
import Pagination from "../../VerticalLayout/pagination/Pagination";
class ManageParkingFeature extends Component {
   constructor(props) {
      super(props);
      this.getAllVehicleFeatures = this.getAllVehicleFeatures.bind(this);
      this.addParkingFeatures = this.addParkingFeatures.bind(this);
      this.updateFeatureFunction = this.updateFeatureFunction.bind(this);
      this.DeleteFeatureID = this.DeleteFeatureID.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.state = {
         currentPage: "",
         lastPage: "",
         pageIDs: [],
         addProfile: "",
         allVehicleFeature: [],
         getFeatures: [],
         vehicleFeatureName: "",
         vehicleFeatureNameVal: false,
         vehicleFeatureDescription: "",
         vehicleFeatureDescriptionVal: false,
         showEditProfileImage: "",
         editProfileImage: "",
         showProfileImage: "",
         editFeatureID: "",
         showProfileImageVal: false,
         modelName: "",
         status: "",
         slug: "",
         modelNameVal: false,
         loading: false,

         loadingModel: true,
      };
   }

   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllVehicleFeatures();
   }

   async getAllVehicleFeatures(page) {
      let featuresArray = [];
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const getFeature = await getVehicleFeature(accessToken, page);
         if (getFeature.status === true) {
            this.setState({ loading: true });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: getFeature.parking_features.current_page,
               });
            this.setState({ lastPage: getFeature.parking_features.last_page });
            this.setState({
               allVehicleFeature: getFeature.parking_features.data,
            });
            for (
               let i = getFeature.parking_features.from;
               i <= getFeature.parking_features.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.state.allVehicleFeature.map((mod, index) => {
               featuresArray.push({
                  id: mod.id,
                  name: mod.name,
                  description: mod.description,
                  status: mod.status,
               });
               this.setState({ getFeatures: featuresArray });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   async addParkingFeatures() {
      // this.setState({ loadingModel: false });

      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (
         this.state.vehicleFeatureName == "" ||
         this.state.vehicleFeatureName == undefined
      ) {
         this.setState({ vehicleFeatureNameVal: true });
      }
      if (this.state.addProfile == "" || this.state.addProfile == undefined) {
         this.setState({ showProfileImageVal: true });
      }
      // if (
      //    this.state.vehicleFeatureDescription == "" ||
      //    this.state.vehicleFeatureDescription == undefined
      // ) {
      //    this.setState({ vehicleFeatureDescriptionVal: true });
      // }
      if (
         this.state.vehicleFeatureName !== "" &&
         this.state.vehicleFeatureName !== undefined &&
         this.state.addProfile !== "" &&
         this.state.addProfile !== undefined
      ) {
         try {
            const response = await AddFeatures(
               accessToken,
               this.state.vehicleFeatureName,
               this.state.vehicleFeatureDescription,
               this.state.addProfile
            );
            if (response.status == true) {
               this.getAllVehicleFeatures(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking feature added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleFeatureName: "",
                  vehicleFeatureDescription: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async setInitialValue(id, name, description, status, imageBG) {
      if (imageBG == "undefinedundefined") {
         this.setState({
            showEditProfileImage: dummyCar,
         });
      } else {
         this.setState({
            showEditProfileImage: imageBG,
         });
      }
      this.setState({
         vehicleFeatureNameVal: false,
         vehicleFeatureDescriptionVal: false,
         editFeatureID: id,
         vehicleFeatureName: name,
         vehicleFeatureDescription: description,
         status: status,
      });
   }

   async updateFeatureFunction() {
      if (
         this.state.vehicleFeatureName == "" ||
         this.state.vehicleFeatureName == undefined
      ) {
         this.setState({ vehicleFeatureNameVal: true });
      }
      // if (
      //    this.state.vehicleFeatureDescription == "" ||
      //    this.state.vehicleFeatureDescription == undefined
      // ) {
      //    this.setState({ vehicleFeatureDescriptionVal: true });
      // }

      if (
         this.state.vehicleFeatureName !== "" &&
         this.state.vehicleFeatureName !== undefined
         // this.state.vehicleFeatureDescription !== "" &&
         // this.state.vehicleFeatureDescription !== undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditFeature(
               accessToken,
               this.state.vehicleFeatureName,
               this.state.vehicleFeatureDescription,
               this.state.editFeatureID,
               this.state.editProfileImage
            );
            if (response.status == true) {
               this.getAllVehicleFeatures(this.state.currentPage);
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking feature updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleFeatureName: "",
                  vehicleFeatureDescription: "",
                  editProfileImage: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async DeleteFeatureID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteVehicleFeature(accessToken, id);
            if (result.status === true) {
               this.getAllVehicleFeatures(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle feature removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async ActiveVehicleFeatureID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to active this parking feature?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setActiveFeature(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleFeatures(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking feature active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleFeatureID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to deactive this parking feature?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActiveFeature(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleFeatures(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Parking feature Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   vehicleNameFunction = (e) => {
      this.setState({
         vehicleFeatureName: e.target.value,
         vehicleFeatureNameVal: false,
      });
   };
   vehicleDescriptionFunction = (e) => {
      this.setState({
         vehicleFeatureDescription: e.target.value,
         vehicleFeatureDescriptionVal: false,
      });
   };
   clearAllState = (e) => {
      this.setState({
         vehicleFeatureName: "",
         addProfile: "",
         vehicleFeatureNameVal: false,
         vehicleFeatureDescription: "",
         vehicleFeatureDescriptionVal: false,
         showProfileImage: dummyCar,
         showProfileImageVal: false,
         imageIcon: "",
      });
   };

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllVehicleFeatures(page);
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   handleChange = (e, inputField) => {
      // Add Logo Error
      if (
         e.target?.files[0]?.type == "image/svg+xml" ||
         e.target?.files[0]?.type == "image/svg"
      ) {
         if (inputField == "logo") {
            if (e.target.files.length !== 0) {
               this.setState({
                  addProfile: e.target.files[0],
                  showProfileImageVal: false,
               });
               this.setState({
                  showProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
         if (inputField == "Elogo") {
            if (e.target.files.length !== 0) {
               this.setState({ editProfileImage: e.target.files[0] });
               this.setState({
                  showEditProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showEditProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
      } else {
         Swal.fire({
            position: "top",
            icon: "warning",
            title: "Please upload svg file",
            showConfirmButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
         });
      }
   };
   render() {
      return (
         <>
            <div className="page-content">
               <Container>
                  {/* ///////////////////////////// */}
                  <h5 className="fs-5 mb-3 fw-bold">Parking Features </h5>
                  {this.state.loading ? (
                     <>
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div></div>
                              <div>
                                 <Button
                                    type="button"
                                    onClick={() => {
                                       this.clearAllState();
                                       this.setState({
                                          showProfileImage: dummyCar,
                                       });
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded mb-2 me-2"
                                 >
                                    <span>Add Feature</span>
                                 </Button>
                              </div>
                           </div>
                           <div className="overflow-auto">
                              <table class="table bg-white table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Icon</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Description</th>
                                       <th scope="col">Date</th>
                                       <th scope="col">Status</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.allVehicleFeature?.length >
                                    0 ? (
                                       <>
                                          {" "}
                                          {this.state.allVehicleFeature.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}
                                                         <td>
                                                            {item?.feature_image
                                                               ?.folder_path +
                                                            item?.feature_image
                                                               ?.file_name ? (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     maxWidth:
                                                                        "30px",
                                                                     // maxHeight:
                                                                     //    "20px",
                                                                     // background:
                                                                     //    "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={
                                                                     item
                                                                        ?.feature_image
                                                                        ?.folder_path +
                                                                     item
                                                                        ?.feature_image
                                                                        ?.file_name
                                                                  }
                                                                  alt=""
                                                               />
                                                            ) : (
                                                               <img
                                                                  className="img-fluid iconImage me-3"
                                                                  style={{
                                                                     background:
                                                                        "linear-gradient(#3E94A6, #323F4B)",
                                                                  }}
                                                                  src={dummyCar}
                                                                  alt=""
                                                                  width={"20px"}
                                                                  height={
                                                                     "20px"
                                                                  }
                                                               />
                                                            )}
                                                         </td>
                                                         <td>
                                                            {item.name ?? "--"}
                                                         </td>
                                                         <td>
                                                            {item.description ??
                                                               "--"}
                                                         </td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>

                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <Link
                                                               to="#"
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="Edit"
                                                                  className="mdi mdi-pencil font-size-18"
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#staticBackdropTwo"
                                                                  onClick={() =>
                                                                     this.setInitialValue(
                                                                        item.id,
                                                                        item.name,
                                                                        item.description,
                                                                        item.status,
                                                                        `${item?.feature_image?.folder_path}${item?.feature_image?.file_name}`
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                            >
                                                               <i
                                                                  title="Delete"
                                                                  className="mdi mdi-trash-can font-size-18 me-3"
                                                                  onClick={() =>
                                                                     this.DeleteFeatureID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        feature_deactive
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.DeActiveVehicleFeatureID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        feature_active
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.ActiveVehicleFeatureID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}{" "}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* ///////////////////////add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Feature
                           </h5>
                           <button
                              type="button"
                              onClick={this.clearAllState}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>

                        <div>
                           <div className="mt-3  row">
                              <div className="m-auto  mt-2 col-4">
                                 <div
                                    className=" image-input-outline p-2"
                                    data-kt-image-input="true"
                                    style={{
                                       height: "125px",
                                       width: "125px",
                                       border: "1.5px solid #3e94a6",
                                    }}
                                 >
                                    <div
                                       className="image-input-wrapper  d-flex"
                                       style={{
                                          height: "107px",
                                          // width: "125px",
                                          background:
                                             "linear-gradient(#3E94A6, #323F4B)",
                                       }}
                                    >
                                       <img
                                          src={this.state.showProfileImage}
                                          className="img-fluid modal_image w-100 h-100"
                                          alt=""
                                          style={{
                                             maxHeight: "107px",
                                             maxWidth: "122px",
                                          }}
                                       />
                                    </div>
                                    {this.state.showProfileImageVal ? (
                                       <div className="modelValidation ">
                                          Icon is required{" "}
                                       </div>
                                    ) : null}
                                 </div>

                                 <label
                                    for="avatar"
                                    style={{
                                       overflow: "hidden",
                                       cursor: "pointer",
                                       marginBottom: "10px",
                                    }}
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                 >
                                    <i
                                       title="Edit"
                                       style={{
                                          position: "absolute",
                                          left: "126px",
                                          top: "-19px",
                                          zIndex: 11,
                                       }}
                                    >
                                       <img
                                          src={pencil}
                                          width={30}
                                          height={25}
                                          alt=""
                                          className="d-inline cursor-pointer"
                                       />
                                    </i>
                                    <input
                                       type="file"
                                       name="avatar"
                                       accept=".svg"
                                       onChange={(e) =>
                                          this.handleChange(e, "logo")
                                       }
                                       onClick={(e) => {
                                          e.target.value = null;
                                       }}
                                       style={{
                                          opacity: 0,
                                          position: "absolute",
                                          left: "122px",
                                          top: "-45px",
                                          zIndex: 111,
                                          cursor: "pointer",
                                          width: "40px",
                                          height: "55px",
                                       }}
                                    />
                                    <input type="hidden" />
                                 </label>
                              </div>
                           </div>
                           <div className="px-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Name
                              </label>
                              <input
                                 type="text"
                                 className="form-control form-control-solid "
                                 placeholder="Enter name"
                                 value={this.state.vehicleFeatureName}
                                 onChange={(e) => this.vehicleNameFunction(e)}
                                 onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                              />
                              {this.state.vehicleFeatureNameVal ? (
                                 <div className="modelValidation">
                                    Enter name
                                 </div>
                              ) : null}
                           </div>
                           <div className="mt-3 px-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder  form-label mb-0"
                              >
                                 Description
                              </label>
                              <input
                                 type="text"
                                 className="form-control form-control-solid "
                                 placeholder="Enter description"
                                 value={this.state.vehicleFeatureDescription}
                                 onChange={(e) =>
                                    this.vehicleDescriptionFunction(e)
                                 }
                                 onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                              />
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.clearAllState}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleFeatureName != "" &&
                           this.state.vehicleFeatureName != undefined &&
                           this.state.addProfile != "" &&
                           this.state.addProfile != undefined ? (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addParkingFeatures}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Feature
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addParkingFeatures}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Feature
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Feature
                           </h5>
                           <button
                              type="button"
                              onClick={this.clearAllState}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="mt-3  row">
                                 <div className="m-auto col-4">
                                    <div
                                       className="image-input image-input-outline p-2"
                                       data-kt-image-input="true"
                                       style={{
                                          height: "125px",
                                          width: "125px",
                                          border: "1.5px solid #3e94a6",
                                       }}
                                    >
                                       <div
                                          className="image-input-wrapper d-flex "
                                          style={{
                                             height: "107px",
                                             // width: "125px",
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                       >
                                          <img
                                             src={
                                                this.state.showEditProfileImage
                                             }
                                             className="img-fluid modal_image w-100 h-100 "
                                             alt=""
                                             style={{
                                                maxHeight: "107px",
                                                maxWidth: "122px",
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <label
                                       for="avatar"
                                       style={{
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       data-kt-image-input-action="change"
                                       data-bs-toggle="tooltip"
                                       title="Edit"
                                    >
                                       <i
                                          title="Edit"
                                          style={{
                                             position: "absolute",
                                             left: "128px",
                                             top: "-19px",
                                             zIndex: 11,
                                          }}
                                       >
                                          <img
                                             src={pencil}
                                             width={25}
                                             height={25}
                                             alt=""
                                             className="d-inline"
                                          />
                                       </i>
                                       <input
                                          type="file"
                                          name="avatar"
                                          accept=".svg"
                                          onChange={(e) =>
                                             this.handleChange(e, "Elogo")
                                          }
                                          style={{
                                             opacity: 0,
                                             left: "126px",
                                             top: "-40px",
                                             position: "absolute",
                                             zIndex: 111,
                                             cursor: "pointer",
                                             width: "45px",
                                             height: "45px",
                                          }}
                                       />
                                       <input type="hidden" />
                                    </label>
                                 </div>
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Name
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter name"
                                    value={this.state.vehicleFeatureName}
                                    onChange={(e) =>
                                       this.vehicleNameFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                                 {this.state.vehicleFeatureNameVal ? (
                                    <div className="modelValidation">
                                       Enter name
                                    </div>
                                 ) : null}
                              </div>
                              <div className="mt-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Description
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter description"
                                    value={this.state.vehicleFeatureDescription}
                                    onChange={(e) =>
                                       this.vehicleDescriptionFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              onClick={this.clearAllState}
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleFeatureName != "" &&
                           this.state.vehicleFeatureName != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 data-bs-dismiss="modal"
                                 onClick={() => this.updateFeatureFunction()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Update
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() => this.updateFeatureFunction()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Update
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default ManageParkingFeature;
